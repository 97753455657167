import { CssBaseline, Drawer, DrawerProps, Hidden, IconButton, StylesProvider, useTheme } from '@material-ui/core';
import React, { ReactNode } from 'react';
import MenuIcon from '@material-ui/icons/Menu';
import styled from '@emotion/styled';
import { ThemeProvider } from '@emotion/react';

const drawerWidth = 240;

const duration = 100;

const RootView = styled.div`
    display: flex;
    transition: ${duration}ms;
`

const DrawerView = styled.nav<{open: boolean, width?: number }>`
    transition: ${duration}ms;
    background-color: ${props => props.theme.palette.primary.main};

    ${props => props.theme.breakpoints.up('sm')} {
        width: ${props => props.open? props.width || drawerWidth : 0}px;
        flex-shrink: 0;
    }
`

interface DrawerPaperExtraProps {
    mobile?: boolean;
    open: boolean;
    width?: number;
    shadow?: boolean;
}

export const DrawerPaperView = styled(Drawer)<DrawerPaperExtraProps>`

    transition: ${duration}ms;

    ${props => props.theme.breakpoints.up('sm')} {
        ${props => props.mobile? 'display: none;' : ''}
    }

    & .MuiDrawer-paper{
        transition: ${duration}ms !important;
        
        width: ${props => props.width || drawerWidth}px;
    
        color: ${props => props.theme.palette.primary.contrastText};
        background-color: ${props => props.theme.palette.primary.main};

        border-right: none;

        ${props => props.theme.breakpoints.only('xs')} {
            border-left: none;
        }

        ${props => props.theme.breakpoints.up('sm')} {
            ${props => props.mobile? 'display: none;' : ''}
        }
    }

    & > .MuiPaper-root {
        box-shadow: ${props => props.shadow ? `0px 0px 5px 3px #33333340` : 'none'};
        z-index: 400;
    }
    
    & .MuiSvgIcon-root {
        color: ${props => props.theme.palette.primary.contrastText};
    }

    & ul {
        margin-right: 3px;
    }
    
    & ul::-webkit-scrollbar {
        width: 2px;
        background-color: ${props => props.theme.palette.primary.light};
    }

    & ul::-webkit-scrollbar-thumb {
        background-color: ${props => props.theme.palette.primary.contrastText};
    }
`;


const MenuButtomView = styled(IconButton)`
    position: fixed;
    top: 0px;
    right: 0px;
    margin-right: ${props => props.theme.spacing(2)};
    display: none;

    ${props => props.theme.breakpoints.down('sm')} {
        display: initial;
    }
`

const SidebarContentWrapper = styled.div`
    height: 100vh;
    max-height: 100vh;
    width: 100%;
    display: grid;
    grid-template-rows: max-content 1fr max-content;
    overflow: hidden;

    & > :nth-child(2) {
        overflow-y: auto;
    }
`;

const UpperView = styled.div`
    width: 100%;
    /* flex-grow: 1; */
`;

const BottomView = styled.div`
    width: 100%;
`

export interface SidebarProps {
    top?: ReactNode;
    menu?: ReactNode;
    bottom?: ReactNode;
    open: boolean;
    onClose: () => void;
    width?: number;
    drawerPaperComponent?: React.ComponentType<DrawerProps & DrawerPaperExtraProps>;
    drawerPaperProps?: Omit<DrawerPaperExtraProps, 'open'>;
}

export const Sidebar: React.FC<SidebarProps> = (props: SidebarProps) => {
    const theme = useTheme();

    const DrawerPaperComponent = props.drawerPaperComponent || DrawerPaperView;

    const drawer = (
        <SidebarContentWrapper>
            <UpperView>
                {props.top}
            </UpperView>
            {props.menu}
            <BottomView>
                {props.bottom}
            </BottomView>
        </SidebarContentWrapper>
    )

    return (
        <StylesProvider injectFirst>
            <ThemeProvider theme={theme}>
                <RootView>
                    <CssBaseline />
                    <MenuButtomView
                        aria-label="open drawer"
                        edge="end"
                        onClick={props.onClose}
                    >
                        <MenuIcon />
                    </MenuButtomView>
                    <DrawerView open={props.open} width={props.width}>
                        <Hidden smUp implementation="css">
                            <DrawerPaperComponent
                                variant="temporary"
                                anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                                {...(props.drawerPaperProps || {})}
                                open={props.open}
                                onClose={props.onClose}
                                mobile
                                ModalProps={{
                                    keepMounted: true, // Better open performance on mobile.
                                }}
                            >
                                {drawer}
                            </DrawerPaperComponent>
                        </Hidden>
                        <Hidden xsDown implementation="css">
                            <DrawerPaperComponent
                                width={props.width}
                                variant="persistent"
                                {...(props.drawerPaperProps || {})}
                                open={props.open}
                            >
                                {drawer}
                            </DrawerPaperComponent>
                        </Hidden>
                    </DrawerView>
                </RootView>
            </ThemeProvider>
        </StylesProvider>
    )
}