import { ReactNode, useState } from 'react';
import { useVisibilityTrigger } from './useVisibilityTrigger';

interface AutoexpandedList<T> {
    visibleItems: T[];
    anchor: ReactNode;
}

export const AutoexpanderDefault = { initialRows: 20, increment: 20 };

export const useListAutoexpander = <T extends unknown>(source: T[], initialItems: number, increment: number): AutoexpandedList<T> => {
    const [visibleN, setVisibleN] = useState(initialItems);
    const { anchor } = useVisibilityTrigger({
        onBecameVisible: () => setVisibleN(n => n+increment),
        isDisabled: visibleN >= source.length,
    })

    return {
        visibleItems: source.slice(0, visibleN),
        anchor,
    }
}
