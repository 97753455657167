import { useEffect } from "react"

export type UseDocumentEventType = <E extends Event>(eventName: keyof DocumentEventMap, callback: (e: E) => void) => void;

export const useDocumentEvent: UseDocumentEventType = (eventName, callback) => {
    useEffect(() => {
        const listener = (e: Event) =>{callback(e as any)};

        document.addEventListener(eventName, listener);

        return () => document.removeEventListener(eventName, listener);
    }, [callback, eventName]);
}

export type UseKeydownEventType = (callback: (e: KeyboardEvent) => void) => void;

export const useKeydownEvent: UseKeydownEventType = (callback) => {
    useDocumentEvent<KeyboardEvent>('keydown', callback);
}

export type UseKeyupEventType = (callback: (e: KeyboardEvent) => void) => void;

export const useKeyupEvent: UseKeyupEventType = (callback) => {
    useDocumentEvent<KeyboardEvent>('keyup', callback);
}

export type UseKeyupWithAltEventType = (key: string, callback: (e: KeyboardEvent) => void) => void;

export const useKeyupWithAltEvent: UseKeyupWithAltEventType = (key, callback) => {
    useDocumentEvent<KeyboardEvent>('keyup', e => {
        if (e.altKey && e.key === key) {
            e.preventDefault();
            callback(e);
        }
    });
}
