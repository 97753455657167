import React, { PropsWithChildren, useContext } from 'react';
import { CMSRecord } from "../types";

export interface ICMSConfiguration {
    apiPath: string;
    getRecordUrl: (record: Partial<CMSRecord> | null) => string | null | undefined;
    getSiteUrl?: (record: Partial<CMSRecord> | null) => string | null | undefined;
}

export const CMSConfigurationContext = React.createContext<ICMSConfiguration>({ apiPath: "", getRecordUrl: () => null });

export const useCMSConfiguration = () => useContext(CMSConfigurationContext);

export const CMSConfigurationProvider = (props: PropsWithChildren<ICMSConfiguration>) => {
    const { children, ...otherProps } = props;
    return <CMSConfigurationContext.Provider value={otherProps}>
        {children}
    </CMSConfigurationContext.Provider>
}
