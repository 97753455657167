import { useState } from "react";

export interface LocalizerConfig<T> {
    defaultLocale: string;
    updateItem: (original: T, changes: Partial<T>) => void;
    translationsField: keyof T;
    translatedFields: (keyof T)[];
    translatedInPlaceFields?: (keyof T)[];
}

export const createTranslator = <T,>(translationsField: keyof T, translatedFields: (keyof T)[], locale: string) => (
    (item: T) => translatedFields.reduce(
        (r,f) => {
            r[f] = (((item[translationsField] || {}) as any)[locale] || {})[f] || "";
            return r;
        },
        { ...item }
        )
    );

interface TranslatorConfig<T> {
  translationsField?: keyof T;
  translatedFields?: (keyof T)[];
  translatedInPlaceFields?: (keyof T)[];
}

export const createTranslator2 = <T,>(cfg: TranslatorConfig<T>, locale: string) => {
  const { translationsField, translatedFields, translatedInPlaceFields } = cfg;

  return (item: T) => {
    const copy = { ...item };
    const withInTranslationsFields = translationsField && translatedFields && translatedFields.length ?
      translatedFields.reduce(
        (r,f) => {
            r[f] = (((item[translationsField] || {}) as any)[locale] || {})[f] || "";
            return r;
        },
        copy)
      : copy;

    const withInPlaceFields = (translatedInPlaceFields || []).reduce(
      (r,f) => {
          r[f] = item[`${f.toString()}_${locale}` as keyof T] || "" as any;
          return r;
      },
      withInTranslationsFields);
    return withInPlaceFields;
  };
};

export const useLocalizer = <T,>(config: LocalizerConfig<T>) => {
    const {
        defaultLocale,
        translationsField,
        translatedFields,
        translatedInPlaceFields,
    } = config;

    const [locale, setLocale] = useState<string>(config.defaultLocale);

    const translate = createTranslator2(config, locale);

    const updateItem = (original: T, changes: Partial<T>) => {
        if(locale === defaultLocale) {
            config.updateItem(original, changes);
        } else {
            const changesWithTranslation = Object.entries(changes).reduce((r: any, [f,v]) => {
                if(translatedFields.includes(f as keyof T)) {
                    r[translationsField] = r[translationsField] || { ...original[translationsField] } || {};
                    r[translationsField][locale] = r[translationsField][locale] || {};
                    r[translationsField][locale][f] = v;
                } else if((translatedInPlaceFields || []).includes(f as keyof T)) {
                    r[`${f}_${locale}` as keyof T] = v;
                } else {
                    r[f] = v;
                }
                return r;
            }, {});
            
            config.updateItem(original, changesWithTranslation);
        }
    }

    return {
        locale,
        isDefaultLocale: locale === config.defaultLocale,
        setLocale,
        translate,
        updateItem,
    }
}
