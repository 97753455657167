import { LoadedData, useLoadedData } from "../../hooks/useLoadedData";
import { apiFetch, apiUploadFile, downloadFile as apiDownloadFile } from "../../api/core";
import { WithTextFilter } from "../schemed/Filtering/useTextFilter";
import { useState } from "react";

export interface MediaFile {
    space_id: string; 
    _id: string; 
    filename: string; 
    thumbnail?: string | null; 
    contenttype: string; 
    created_at: string; 
    fs_path?: string;
}

export interface MediaLib extends Omit<LoadedData<MediaFile[]>, "setData">, WithTextFilter {
    uploadFile: (f: File) => Promise<MediaFile>;
    removeFile: (mf: MediaFile) => Promise<MediaFile>;
    replaceFile: (mf: MediaFile, file: File) => Promise<MediaFile>;
    downloadFile: (mf: MediaFile) => void;
    getFilepath: (mf: MediaFile) => string;
    isUploading: boolean;
    protectedFiles: boolean;

    requestLoad?: () => void;
}

export interface MediaLibConfig {
    noLoad?: boolean;
    loadOnDemand?: boolean;
    noReloadOnUpload?: boolean;
    getFilepath?: (mf: MediaFile) => string;
    urlPrefix?: string;
    protectedFiles?: boolean;
}

export const useMediaLib = (apiPath: string, spaceId: string, cfg?: MediaLibConfig): MediaLib => {
    const apiPathX = `${apiPath}/${spaceId}`;
    const [loadRequested,setLoadRequested] = useState<boolean>(false);
    const data = useLoadedData<MediaFile[]>(`${apiPathX}`, [], !!spaceId && !cfg?.noLoad && (!cfg?.loadOnDemand || loadRequested));
    const [filter, setFilter] = useState<string>("");
    
    const [isUploading, setIsUploading] = useState<boolean>(false);

    const getFilepath = cfg?.getFilepath || (cfg?.urlPrefix
        ? (mf => `${cfg.urlPrefix}/${mf.space_id}/${mf.filename}`)
        : (mf => `/uploads/${mf.space_id}/${mf.filename}`));

    const fileUploadOp = <T,>(op: Promise<T>, noReload?: boolean) => {
        setIsUploading(true);
        return op.then(x => {
            if(!(noReload || cfg?.noReloadOnUpload)) {
                data.reload();
            }
            setIsUploading(false);
            return x;
        })
        .catch(e => {
            setIsUploading(false);
            throw e;
        })
    };

    const uploadFile = (f: File) => fileUploadOp(
        apiUploadFile(apiPathX, "post", "file", f).then(x => x.data as MediaFile));

    const replaceFile = (mf: MediaFile, f: File) => fileUploadOp(
        apiUploadFile(`${apiPathX}/${mf._id}`, "put", "file", f).then(x => x.data as MediaFile));

    const removeFile = (mf: MediaFile) => fileUploadOp(
        apiFetch<MediaFile>(`${apiPathX}/${mf._id}`, "delete")
            .then(x => {
                data.setData(data.data.filter(mf2 => mf2._id !== mf._id));
                return x;
            }),
            true);

    const downloadFile = (mf: MediaFile) => {
        apiDownloadFile(getFilepath(mf), mf.filename);
    }

    const filteredItems = filter ? data.data.filter(mf => mf.filename.toLowerCase().includes(filter.toLowerCase())) : data.data;

    return {
        ...data,
        data: filteredItems,
        uploadFile,
        replaceFile,
        removeFile,
        downloadFile,
        getFilepath,
        filter,
        setFilter,
        isUploading,
        protectedFiles: !!cfg?.protectedFiles,
        requestLoad: () => setLoadRequested(true),
    }
}