import React, {useCallback, useEffect, useState} from "react";
import { apiFetch, apiUploadFile, FetchTypes } from "./core";

interface RequestData {
    body?: any,
    url?: string;
    method?: FetchTypes;
}

interface HookRequsetData extends RequestData {
    depsOfEffect?: any[]
}

export interface FetchHookData<T> {
    data: T;
    loading: boolean;
    error: any;
    setData: React.Dispatch<React.SetStateAction<T>>;
    request: (rd: RequestData) => Promise<void>;
    reload: () => Promise<void>;
    file: (url: string, file: File) => Promise<any>;
    durationRequest: (data: RequestData | undefined, duration: number) => Promise<void>;
}

/**

 * Возвращает значение data, состояние loading, информацию о последней ошибке error и функции:
 *
 * <strong>setData</strong> - уснанавливает значение состаянию data
 * 
 * <strong>request</strong> - позволяет делать запрос вне хука, возвращая промис этого запроса,
 * при этом значения полей data и loading возвращаемые хуком, будут также относиться к этой функции. 
 * Принимает данные, необходимые для запроса, 
 * Если они не указаны, использует параметры переданные в хук
 * 
 * reload - просто функция, вызывающая функцию request без параметров, 
 * удобно использовать как параметр метода Promise.then  
 * 
 * <strong>file</strong> - отправляет файл, возвращает в промисе данные - ответ с сервера, 
 * влияет на свойство loading, но не на свойство data
 * 
 * <strong>durationRequest</strong> - отправляет запрос с заданной задержкой, 
 * если при задержке через эту функцию был отправлен ещё один запрос, 
 * то предыдущий ответ игнорируется
 * 
 * @param initValue - Изначальное значение data до запроса
 * @param param1 - Первичные данные а запросе (body, url, method), можно не указывать, но тогда нужно их указать в request
 * @param run - Запустить при первом вызове хука, по умолчанию true, если указать false, то можно использовать функцию request и возвращаемого объекта.
 * при этом значения полей data и loading, будут актуальными    
 */
export const useFetch = <T>(initValue: T, requestData: HookRequsetData, run: boolean = true): FetchHookData<T> => {
    const {url, method, body, depsOfEffect = []} = requestData;

    const [data, setData] = useState<T>(initValue);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<any>(null);
    const [promiseSwitch] = useState<{pr: Promise<any> | null}>({pr: null});

    const request = useCallback(({body: otBody, url: otUrl, method: otMethod}: RequestData = {}) => {
        setLoading(true);
        return apiFetch<T>(
                '/api' + (otUrl || url), 
                otMethod || method || FetchTypes.GET, 
                otBody || body
            )    
            .then(setData)
            .catch(setError)
            .finally(() => setLoading(false));
    }, [url, method, body]);

    useEffect(() => {
        if (run) {
            request();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [run, request, ...depsOfEffect]);

    const file = (url: string, file: File) => {
        setLoading(true);
        return apiUploadFile('/api' + url, method || FetchTypes.PUT, 'file', file)
            .finally(() => setLoading(false));
    }

    const  withTimer = (promiseFn: () => Promise<any>, duration: number): Promise<any> => {
        const promise = new Promise<any>((resolve, reject) => {
            setTimeout(() => {
                setLoading(true);
        
                if (promise === promiseSwitch.pr as Promise<any>) {
                    promiseFn()
                        .then(res => {
                            if (promise === promiseSwitch.pr as Promise<any>) {
                                resolve(res);
                                setLoading(false);
                            }
                        });
                } else {
                    setLoading(false);
                }
            }, duration);
        });

        promiseSwitch.pr = promise;

        return promise;
    }

    const durationRequest = (data: RequestData = {}, duration: number) => {
        return withTimer(() => request(data), duration);
    };

    return {
        data,
        loading,
        error,
        setData,
        request,
        reload: () => request(),
        file,
        durationRequest
    }
}
