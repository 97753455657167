/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react';
import { readBlobAsDataURL, apiFetchFile } from '../../api/files';
import { LoadingIndicator } from '../primitives';

interface Props extends React.ImgHTMLAttributes<HTMLImageElement> {
}

export const ProtectedImg = (props: Props) => {
    const { src } = props;
    const [dataSrc, setDataSrc] = useState<string>("");

    useEffect(() => {
        if(!src || !src.startsWith("/")) {
            setDataSrc(src || "");
        }
        apiFetchFile(src as string)
            .then(readBlobAsDataURL)
            .then(url => setDataSrc(url || ""));
    }, [src]);

    if(!dataSrc) {
        return <LoadingIndicator />
    } else {
        return <img {...props} src={dataSrc} />;
    }
}

interface PossiblyProtectedProps extends Props {
    protected?: boolean;
}

export const PossiblyProtectedImg = (props: PossiblyProtectedProps) => {
    return props.protected
        ? <ProtectedImg {...props} />
        : <img {...props} />;
}
