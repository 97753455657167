import React from 'react';
import styled from '@emotion/styled';
import { FormattedMessage } from "react-intl";
import { FieldSchema } from "../../hooks/useSchema";
import { ExtraProps } from './FormControlProps';

export type FieldDefinition = [string] | [string, ExtraProps];
export type MaybeFieldDefinition = FieldDefinition | null | false;

export type OnFieldChange = (oldRow: any, changes: any) => void;

export const fieldLabel = (schema: FieldSchema, fallback: string = "") => {
    return schema?.label_id ? <FormattedMessage id={schema?.label_id} /> : (schema?.label || fallback); 
};

export const TableRowButtons = styled.div`
    display: flex;
    flex-flow: row;
`;