import React, { ReactNode } from 'react';
import { Dialog, FormGrid } from '../../primitives';
import { FormattedMessage } from 'react-intl';
import { Button } from '@material-ui/core';
import { CalendarEventCreateData } from './useCalendarEventCreate';
import { FormControlsForFields } from '../../schemed';
import { CalendarEventSchema } from './calendar.events.types';

interface Props {
  data: CalendarEventCreateData;
  title?: ReactNode;
}

export const CalendarEventDialog = (props: Props) => {
  return (
    <Dialog
      isOpen={props.data.isEditing}
      close={() => props.data.cancel()}
      dialogTitle={props.title || <FormattedMessage id="notifications.task.calendar_event.create_dialog_title" />}
      noFullscreen
      actions={<>
        <Button onClick={() => props.data.cancel()}><FormattedMessage id="common.cancel" /></Button>
        <Button onClick={() => props.data.save()} color="primary" variant="contained"><FormattedMessage id="common.create" /></Button>
      </>}>

      {props.data.item &&
        <>
          <FormGrid columns="1fr" noMargin gap="dense">
            <FormControlsForFields
              schema={CalendarEventSchema}
              data={props.data.item}
              onChange={(o,c) => props.data.update(c)}
              fields={[
                ["summary"],
                ["location"],
              ]}
              />
          </FormGrid>

          <FormGrid columns="1fr 1fr" noMargin gap="dense">
            <FormControlsForFields
              schema={CalendarEventSchema}
              data={props.data.item}
              onChange={(o,c) => props.data.update(c)}
              fields={[
                ["start"],
                ["end"],
                ["organizer_name"],
                ["organizer_email"],
              ]}
              />
          </FormGrid>

          <FormGrid columns="1fr" noMargin gap="dense">
            <FormControlsForFields
              schema={CalendarEventSchema}
              data={props.data.item}
              onChange={(o,c) => props.data.update(c)}
              fields={[
                ["description", { autoRows: true }],
              ]}
              />
          </FormGrid>
        </>}
    </Dialog>
  );
}
