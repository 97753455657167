import React from "react";
import styled from '@emotion/styled';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from "@material-ui/core";
import { Add, FullscreenExitOutlined, FullscreenOutlined, InfoOutlined } from "@material-ui/icons";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Component, DefaultComponents } from ".";
import { FieldDefinition, FormControlsForFields, TableForFields } from "../schemed";
import { ComponentData } from "./usePages";
import { ActionRow, OccupyFreeSpace } from "../primitives/ActionRow";
import { LoadingIndicator } from "../primitives/LoadingIndicator";
import { FieldType, mergeSchema } from "../../hooks/useSchema";
import isHotkey from "is-hotkey";


export interface ComponentManagementFormProps {
    componentData: ComponentData;
    customComponentsDescription?: [string,string][];
}

const ComponentForm = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 0.75rem;
    row-gap: 0.25rem;

    grid-template-areas: 
        "order type"
        "content content"
        "properties properties";

    & > :nth-child(1) {
        grid-area: order;
    }
    & > :nth-child(2) {
        grid-area: type;
    }
    & > :nth-child(3) {
        grid-area: content;
    }
    & > :nth-child(4) {
        grid-area: properties;
    }
`;

const GuideContent = styled(DialogContent)`
    & p {
        margin: 0.5rem 0;
    }

    & li u {
        text-decoration: none;
        border-bottom: 1px dotted;
    }
`;

export const ComponentManagementForm = (props: ComponentManagementFormProps) => {
    const [selectedComponentId, setSelectedComponentId] = useState<string | null>(null);
    const [hasChanges, setHasChanges] = useState<boolean>(false);
    const [isGuideOpen, setIsGuideOpen] = useState<boolean>(false);
    const [isFullscreenEditor, setIsFullscreenEditor] = useState<boolean>(true);
    const componentData = props.componentData;

    const { formatMessage } = useIntl();

    const selectedComponent = selectedComponentId === null ? null : componentData.components.data.find(p => p._id === selectedComponentId);

    const onChangeComponent = (oldData: Component, changes: Partial<Component>) => {
        const data = { ...oldData, ...changes };
        let oldPages = componentData.components.data.slice();
        oldPages[componentData.components.data.findIndex(p => p._id === data._id)] = data;

        setHasChanges(true);
        componentData.components.setData(oldPages);
    };

    const componentControls = (fields: FieldDefinition[]) => (
        selectedComponent && <FormControlsForFields
            data={selectedComponent}
            fields={fields}
            schema={componentData.schema}
            onChange={onChangeComponent}
            />
    );

    const tableSchema = mergeSchema(componentData.schema, { content_text: { type: FieldType.textlong} });

    const openForEditing = (component: Component) => {
        setSelectedComponentId(component._id);
        setHasChanges(false);
    };

    const closeCurrent = () => {
        setSelectedComponentId(null);
        setHasChanges(false);
        componentData.components.reload();
    };

    const deleteCurrent = () => {
        if(selectedComponent) {
            componentData.deleteComponent(selectedComponent._id)
                .then(() => { setHasChanges(false); });
        }
        closeCurrent();
    }


    const saveCurrent = () => {
        if(selectedComponent) {
            componentData.saveComponent(selectedComponent)
                .then(() => { setHasChanges(false); });;
        }
    }

    const guideParagraphs = (text: string, keyPrefix: string) => text.split("\n").map((line,i) => <Typography key={`${keyPrefix}_${i}`}>{line}</Typography>);

    return (
        <>
            <ActionRow>
                <Typography variant="h6"><FormattedMessage id="pages.componentListTitle" /></Typography>
                {componentData.components.isLoading && <LoadingIndicator />}
                <OccupyFreeSpace />
                <IconButton size="small" color='primary' onClick={() => componentData.components.create()}><Add /></IconButton>
                <IconButton size="small" color='primary' onClick={() => setIsGuideOpen(true)}><InfoOutlined /></IconButton>
            </ActionRow>
            <TableForFields
                fields={[
                    ["comp_order", { width: 60 }],
                    ["content_text"],
                    ["subtype_text", { width: 200 }],
                ]}
                data={componentData.components.data}
                schema={tableSchema}
                onRowClick={openForEditing}
                fieldElement={f => {
                    if(f === "content_text") {
                        return row => {
                            const maxLines = 5;
                            const lines = row.content_text.split("\n");
                            const renderLine = (line: string) => <p style={{ margin: "0" }}>{line}</p>;
                            return <>
                                {lines.slice(0, maxLines).map(renderLine)}
                                {lines.length > maxLines && renderLine("...")}
                                </>;
                        };
                    }
                    return null;
                }}
                />
            
            <Dialog
                open={!!selectedComponentId}
                onClose={closeCurrent}
                maxWidth="md"
                fullWidth
                fullScreen={isFullscreenEditor}
                onKeyUp={e => {
                    if(hasChanges && (isHotkey("alt+s", e) || isHotkey("mod+s", e))) {
                        e.preventDefault();
                        saveCurrent();
                    }
                }}
            >
                <DialogTitle>
                    <ActionRow firstItemNoMargin itemMarginTop="0">
                        <FormattedMessage id="pages.editComponentTitle" />

                        <OccupyFreeSpace />

                        <IconButton size="small" onClick={() => setIsFullscreenEditor(x => !x)}>
                            {isFullscreenEditor ? <FullscreenExitOutlined /> : <FullscreenOutlined />}
                        </IconButton>
                    </ActionRow>
                </DialogTitle>
                {selectedComponent &&
                    <DialogContent>
                        <ComponentForm>
                            {componentControls([
                                ["comp_order"],
                                ["subtype_text"],
                                ["content_text"],
                                ["properties", { rows: 4 }],
                            ])}
                        </ComponentForm>
                    </DialogContent>
                }
                <DialogActions>
                    <Button color="secondary" onClick={deleteCurrent}>
                        <FormattedMessage id="common.delete" />
                    </Button>
                    <Button onClick={closeCurrent}>
                        <FormattedMessage id="common.close" />
                    </Button>
                    <Button color="primary" variant="contained" disabled={!hasChanges} onClick={saveCurrent}>
                        <FormattedMessage id="common.save" />
                    </Button>
                </DialogActions>
            </Dialog>


            <Dialog
                open={isGuideOpen}
                onClose={() => setIsGuideOpen(false)}
                maxWidth="md"
                fullWidth
            >
                <DialogTitle>
                    <FormattedMessage id="pages.components_guide.title" />
                </DialogTitle>
                <GuideContent>
                    {guideParagraphs(formatMessage({ id: "pages.components_guide.general"}), "general")}
                    <a href={formatMessage({ id: "pages.components_guide.md_guide_url"})} target="_blank" rel="noopener noreferrer">
                        <FormattedMessage id="pages.components_guide.md_guide_text" />
                    </a>

                    {guideParagraphs(formatMessage({ id: "pages.components_guide.part2"}), "part2")}
                    <ul>
                        {Object.keys(DefaultComponents).sort().map(ct => (
                            <li key={`default_${ct}`}><Typography><u>{ct}</u> – <FormattedMessage id={`pages.components_guide.type_descriptions.${ct}`} /></Typography></li>
                        ))}
                        {(props.customComponentsDescription || []).map(([ct, description]) => (
                            <li key={`custom_${ct}`}><Typography><u>{ct}</u> – {description}</Typography></li>
                        ))}
                    </ul>

                    {guideParagraphs(formatMessage({ id: "pages.components_guide.part3"}), "part3")}
                </GuideContent>
                <DialogActions>
                    <Button onClick={() => setIsGuideOpen(false)}>
                        <FormattedMessage id="common.close" />
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}