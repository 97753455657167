import React from "react";
import styled from "@emotion/styled";
import { IconButton, withTheme } from "@material-ui/core";
import { Add, Check } from "@material-ui/icons";
import { Tooltip } from "./Tooltip";

const SelectedButton = withTheme(styled(IconButton)`
    color: ${props => "#ffffff"};
    background: ${props => props.theme.palette.success.main};

    &:hover {
        background: ${props => props.theme.palette.error.main};
    }
`);

const UnselectedButton = withTheme(styled(IconButton)`
    background: ${props => props.theme.palette.grey[200]};

    &:hover {
        color: ${props => "#ffffff"};
        background: ${props => props.theme.palette.success.main};
    }
`);

interface Props {
    isSelected: boolean;
    select: () => void;
    deselect: () => void;
    selectTooltip?: string;
    deselectTooltip?: string;
}

export const SelectButton = ({ isSelected, select, deselect, selectTooltip, deselectTooltip }: Props) => {
    return (
        <>
            {isSelected ?
                <Tooltip text={deselectTooltip} text_id="common.deselect">
                    <SelectedButton size="small" onClick={deselect}>
                        <Check />
                    </SelectedButton>
                </Tooltip> :
                <Tooltip text={selectTooltip} text_id="common.select">
                    <UnselectedButton size="small" onClick={select}>
                        <Add />
                    </UnselectedButton>
                </Tooltip>
                }
        </>
    );
}