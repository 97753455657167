import { useEffect } from "react";
import { clearApiToken, setOnAuthError } from "../../api/core";
import { useUser } from "../../userContext/UserContext";
import { useReturnUrl } from "./useReturnUrl";

interface Settings {
    onGuardInvoked?: () => void;
    isAuthenticated?: boolean;
}

export const useLoginGuard = (loginUrl: string, settings: Settings) => {
    const returnUrl = useReturnUrl();
    const user = useUser();

    const { onGuardInvoked, isAuthenticated } = settings;

    const logout = () => {
        setOnAuthError(() => { });
        clearApiToken();
        user.setUser(null);
        if(onGuardInvoked) {
            onGuardInvoked();
        }
        returnUrl.redirectWithReturn(loginUrl);
    };

    setOnAuthError(logout);

    useEffect(() => {
        if(isAuthenticated === false) {
            logout();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated])
}
