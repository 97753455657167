import { useMemo, useState } from "react";
import { DateTimeInternalFormat } from "../../api/datetimeUtil";
import { useLoadedData } from "../../hooks/useLoadedData";
import { utc } from "../timezone";
import { ProgramExecution } from "./types";

interface ExecutionsLogFilter {
    before?: string;
    after?: string;
}

const getDefaultFilter = (): ExecutionsLogFilter => ({
    after: utc.toServer(new Date()).add({ days: -1 }).format(DateTimeInternalFormat),
})

export const useRobudExecutions = (apiPath: string, defaultFilter?: ExecutionsLogFilter) => {
    const [filter, setFilter] = useState<Partial<ExecutionsLogFilter>>(defaultFilter || getDefaultFilter());

    const params = useMemo(
        () => Object.entries(filter).filter(([k,v]) => v !== undefined).map(([k,v]) => `${k}=${v}`).join("&"),
        [filter]);

    const data = useLoadedData<ProgramExecution[]>(`${apiPath}/execution?${params}`, []);

    return {
        ...data,
        filter,
        updateFilter: (c: Partial<ExecutionsLogFilter>) => setFilter(f => ({ ...f, ...c })),
        resetFilter: () => setFilter(defaultFilter || getDefaultFilter()),
    }
}
