import React from 'react';
import { PowerEditorBase, PowerEditorProps } from '../toolympus/components/PowerDoc';
import { useMediaFilesPlugin } from '../toolympus/components/PowerDoc/plugins/MediaFiles';
import { ColumnsPlugin } from '../toolympus/components/PowerDoc/plugins/Columns';
import { CustomFieldsBlockPlugin } from '../toolympus/components/PowerDoc/plugins/CustomFieldsBlock';
import { ExpanderPlugin } from '../toolympus/components/PowerDoc/plugins/Expander';
import { PanelPlugin } from '../toolympus/components/PowerDoc/plugins/Panel/PanelPlugin';
import { ScriptPlugin } from '../toolympus/components/PowerDoc/plugins/Script';
import { TablesPlugin } from '../toolympus/components/PowerDoc/plugins/Tables';
import { TilesPlugin } from '../toolympus/components/PowerDoc/plugins/Tiles';

export const CmsEditor = (props: PowerEditorProps) => {
  const mediaFilesPlugin = useMediaFilesPlugin();
  // const linkedRecordsPlugin = useLinkedRecordsEditorPlugin();

  return (
      <PowerEditorBase
          {...props}
          plugins={[
              mediaFilesPlugin,
              CustomFieldsBlockPlugin,
              TilesPlugin,
              ColumnsPlugin,
              PanelPlugin,
              ScriptPlugin,
              ExpanderPlugin,
              TablesPlugin,
              // linkedRecordsPlugin,
              // ConfigurableFormsEditorPlugin,
          ]}
          />
  );
}
