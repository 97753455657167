import React, { ReactNode, useContext } from 'react';
import { Editor } from 'slate';
import { CustomElement } from '../../../../slate';

export interface IPowerEditorContext {
    viewMode?: boolean;
    invokeCommand: (commandName: string, editor: Editor, element?: CustomElement, params?: any) => void;
}

const PowerEditorContext = React.createContext<IPowerEditorContext>({
    invokeCommand: () => { },
});

export const PowerEditorContextProvider = (props: IPowerEditorContext & { children?: ReactNode }) => {
    return (
        <PowerEditorContext.Provider value={props}>
            {props.children}
        </PowerEditorContext.Provider>
    );
}


export const usePowerEditorContext = () => useContext(PowerEditorContext);
