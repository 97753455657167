import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { MenuGroup, SubmenuContainer } from '../frame/Submenu';
import { NotificationTasksContainer, NotificationTasksProps } from '../Notifications/NotificationTasksContainer';
import { QueryConsoleContainer } from '../QueryConsole';
import { ScoringSheetsManagementContainer, ScoringSheetsManagementProps } from './ScoringSheets';
import { EmailsPage } from '../emails/presentation/EmailsPage';
import { PageManagementContainer, PageManagementProps } from '../Pages';
import { FileListPage, FileListProps } from '../files';
import { AllInclusive, AlternateEmailOutlined, AssignmentTurnedInOutlined, AttachFile, BookOutlined, BuildOutlined, Code, EmailOutlined, Group, PlaylistPlay, PrintOutlined, SubjectOutlined, Translate, VpnKeyOutlined } from '@material-ui/icons';
import UserManagementForm, { IUserRole, UserManagementFormProps } from '../userManagement/UserManagementForm';
import { EventLogContainer } from '../EventLog/EventLogForm';
import { DictionariesList, DictionariesProps } from '../Dictionaries/DictionariesList';
import { SchemasPage } from '../schemed/SchemasPage';
import { DefaultUser, useUser } from '../../userContext/UserContext';
import { LocalizationMessagesContainer, LocalizationMessagesContainerProps } from '../localization';
import { RobudConfig, RobudRoutes } from '../Robud';
import { APITokensManagementContainer, APITokensManagementProps } from '../userManagement/ApiTokens';
import { CMSMenuWithEditorProps, CMSMenuWithEditorSettings } from '../CMS';

interface Props {
    scoringSheets?: ScoringSheetsManagementProps;
    notificationTasks?: NotificationTasksProps;
    emails?: { apiPath: string };
    queryConsole?: { apiPath?: string };
    eventLog?: { apiPath: string };
    pages?: ({ key: string, label: ReactNode } & PageManagementProps)[];
    files?: ({ key: string, label: ReactNode } & FileListProps)[];
    users?: UserManagementFormProps<IUserRole>;
    apiTokens?: APITokensManagementProps;
    dictionaries?: DictionariesProps;
    robud?: Partial<RobudConfig>;
    schemas?: {};
    messages?: LocalizationMessagesContainerProps;
    cms?: CMSMenuWithEditorProps;

    extraItems?: MenuGroup[];

    checkSectionAvailable?: Record<string, (user: DefaultUser) => boolean | undefined | null>;
    noCloseButton?: boolean;
}

export const SystemSubmenu = (props: Props) => {
    const { user } = useUser();

    const checkAccess = (key: string) => ((props.checkSectionAvailable || {})[key] || (() => true))(user);
    
    return (
        <SubmenuContainer
            noCloseButton={props.noCloseButton}
            groups={[
                ...(props.dictionaries && checkAccess("dictionaries") ? [{
                    key: "dictionaries",
                    icon: <BookOutlined />,
                    label: <FormattedMessage id="dictionaries.title" />,
                    items: [{ key: "dictionaries", label: "", component: () => <DictionariesList {...props.dictionaries as DictionariesProps} />}],
                },] : []),

                ...(props.pages && checkAccess("pages") ? [{
                    key: "pages",
                    icon: <SubjectOutlined />,
                    label: <FormattedMessage id="pages.pageListTitle" />,
                    items: props.pages.map(pp => ({ key: pp.key, label: pp.label, component: () => <PageManagementContainer {...pp} /> })),
                },] : []),

                ...(props.files && checkAccess("files") ? [{
                    key: "files",
                    icon: <AttachFile />,
                    label: <FormattedMessage id="files.pageTitle" />,
                    items: props.files.map(pp => ({ key: pp.key, label: pp.label, component: () => <FileListPage {...pp} /> })),
                },] : []),

                ...(props.scoringSheets && checkAccess("scoring-sheets") ? [{
                    key: "scoring-sheets",
                    icon: <AssignmentTurnedInOutlined />,
                    label: <FormattedMessage id="contests.scoring.sheets_list_title" />,
                    items: [{ key: "", label:  "xx", component: () => <ScoringSheetsManagementContainer {...props.scoringSheets as ScoringSheetsManagementProps} /> }]
                },] : []),

                ...(props.notificationTasks && checkAccess("notification-tasks") ? [{
                    key: "notification-tasks",
                    icon: <EmailOutlined />,
                    label: <FormattedMessage id="notifications.title" />,
                    items: [{ key: "", label:  "xx", component: () => <NotificationTasksContainer {...props.notificationTasks as NotificationTasksProps } /> }]
                },] : []),

                ...(props.emails && checkAccess("emails") ? [{
                    key: "emails",
                    icon: <AlternateEmailOutlined />,
                    label: <FormattedMessage id="emails.screen_title" />,
                    items: [{ key: "", label:  "xx", component: () => <EmailsPage apiPrefix={props.emails?.apiPath} /> }]
                },] : []),

                ...(props.users && checkAccess("users") ? [{
                    key: "users",
                    icon: <Group />,
                    label: <FormattedMessage id="userManagement.title" />,
                    items: [{ key: "", label:  "xx", component: () => <UserManagementForm {...props.users as UserManagementFormProps<IUserRole> } /> }]
                },] : []),
                ...(props.apiTokens && checkAccess("apitokens") ? [{
                  key: "apitokens",
                  icon: <VpnKeyOutlined />,
                  label: <FormattedMessage id="auth.apitokens.title" />,
                  items: [{ key: "", label:  "xx", component: APITokensManagementContainer, componentProps: props.apiTokens }]
              },] : []),

                ...(props.messages && checkAccess("messages") ? [{
                    key: "messages",
                    icon: <Translate />,
                    label: <FormattedMessage id="localization.messages.title" />,
                    items: [{ key: "", label:  "xx", component: () => <LocalizationMessagesContainer {...props.messages} /> }]
                },] : []),

                ...(props.robud && checkAccess("robud") ? [{
                    key: "robud",
                    icon: <AllInclusive />,
                    label: <FormattedMessage id="robud.title" />,
                    items: [{ key: "program", label:  "xx", component: RobudRoutes, componentProps: props.robud }]
                },] : []),

                ...(props.eventLog && checkAccess("event-log") ? [{
                    key: "event-log",
                    icon: <PlaylistPlay />,
                    label: <FormattedMessage id="eventlog.screen_title" />,
                    items: [{ key: "", label:  "xx", component: () => <EventLogContainer {...props.eventLog as { apiPath: string} } /> }]
                },] : []),

                ...(props.queryConsole && checkAccess("query-console") ? [{
                    key: "query-console",
                    icon: <Code />,
                    label: <FormattedMessage id="queryconsole.screen_title" />,
                    items: [{ key: "", label:  "xx", component: () => <QueryConsoleContainer {...props.queryConsole } /> }]
                },] : []),

                ...(props.schemas && checkAccess("schemas") ? [{
                    key: "schemas",
                    icon: <BuildOutlined />,
                    label: <FormattedMessage id="schemas.title" />,
                    items: [{ key: "", label:  "xx", component: () => <SchemasPage /> }]
                },] : []),

                ...(props.cms && checkAccess("cms") ? [{
                  key: "cms",
                  icon: <PrintOutlined />,
                  label: <FormattedMessage id="cms.title" />,
                  items: [{ key: "", label:  "xx", component: CMSMenuWithEditorSettings, componentProps: props.cms }]
              },] : []),

                ...(props.extraItems || []).filter(x => checkAccess(x.key)),
            ]}
            />
    );
}
