import { FieldType, Schema } from "../../../hooks/useSchema";

export interface CalendarEvent {
  start: string;
  end: string;
  summary: string;
  id: string | number;
  timezone?: string;
  description: string;
  location: string;
  organizer_name: string;
  organizer_email: string;
}

export const CalendarEventSchema: Schema = {
  start: { label_id: "notifications.task.calendar_event.fields.start", type: FieldType.datetime },
  end: { label_id: "notifications.task.calendar_event.fields.end", type: FieldType.datetime },
  summary: { label_id: "notifications.task.calendar_event.fields.summary" },
  description: { label_id: "notifications.task.calendar_event.fields.description", type: FieldType.textlong },
  id: { label_id: "notifications.task.calendar_event.fields.id" },
  timezone: { label_id: "notifications.task.calendar_event.fields.timezone" },
  location: { label_id: "notifications.task.calendar_event.fields.location" },
  organizer_name: { label_id: "notifications.task.calendar_event.fields.organizer_name" },
  organizer_email: { label_id: "notifications.task.calendar_event.fields.organizer_email" },
}