import React from 'react';
import { useParams } from 'react-router-dom';
import { EditPageForm } from './EditPageForm';
import { useEditPage } from './usePages';

interface Props {
    apiPath: string;
    customComponentsDescription?: [string,string][];
}

export const EditPageFormContainer = ({ apiPath, customComponentsDescription }: Props) => {
    const { id } = useParams<{ id: string }>();
    const data = useEditPage(apiPath, id);
    return <EditPageForm data={data} customComponentsDescription={customComponentsDescription} />
}
