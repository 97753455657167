import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useHistoryFavorites } from "./HistoryFavoritesContext"
import { HistoryItem } from "./useHistoryFavoritesMain";

interface Config {
    includeQuery?: boolean;
}

export const useTrackHistory = (title: HistoryItem["title"] | undefined, cfg?: Config) => {
    const data = useHistoryFavorites();
    const location = useLocation();

    useEffect(() => {
        if(title) {
            const path = cfg?.includeQuery ? `${location.pathname}${location.search}` : location.pathname;
            data.trackHistory(path, title);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [title]);
}
