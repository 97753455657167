import { Editor, Transforms } from "slate";
import { EditorPlugin } from "../../slate/PowerEditorConfig";
import { deserialize } from "./deserialize";

const isHtmlFromWord = (text: string) => text.includes("urn:schemas-microsoft-com:office:office");

const withPasteHtml = (editor: Editor) => {
    const { insertData } = editor;

    editor.insertData = data => {
        if(data.getData("application/x-slate-fragment")) {
            insertData(data);
            return;
        }
        
        let html = data.getData('text/html');
        if(isHtmlFromWord(html)) {
            html = html.replace(/(\r?\n)+/g, " ").replace(/<br\s*\/?>/ig, "\n");
        }
        if (html) {
            const parsed = new DOMParser().parseFromString(html, 'text/html');
            const fragment = deserialize(parsed.body);
            Transforms.insertFragment(editor, Array.isArray(fragment) ? fragment : [fragment]);
            return;
        }

        insertData(data);
    }

    return editor;
}

export const PasteHtmlPlugin: EditorPlugin = {
    key: "paste-html",
    inject: withPasteHtml,
}