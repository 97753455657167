import { useState } from "react";
import { Transforms } from "slate";
import { ReactEditor, useSlateStatic } from "slate-react";
import { CustomElement } from "../../../../../slate";

interface WithPanel {
    content: any;
    isEditorCollapsed?: boolean;
    title?: string;
}

export const usePanel = (element: CustomElement) => {
    const editor = useSlateStatic();
    const path = ReactEditor.findPath(editor, element);

    const [localState, setLocalState] = useState<WithPanel>({
        content: (element as any).content as any,
        isEditorCollapsed: (element as any).isEditorCollapsed || false,
        title: (element as any).title || "",
    });

    const [hasTitle, setHasTitle] = useState<boolean>(!!(element as any).title);

    const update = (changes: Partial<WithPanel>) => {
      const updated = { ...localState, ...changes };
      setLocalState(updated);
        Transforms.setNodes(
            editor,
            updated as any,
            { at: path },
        );
    }

    const toggleHasTitle = () => {
      if(hasTitle && localState.title) {
        update({ title: "" });
      }
      setHasTitle(!hasTitle);
    }

    return {
        ...localState,
        update,
        updateContent: (c: any) => update({ content: c }),
        hasTitle,
        toggleHasTitle,
    }
}
