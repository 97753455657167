import React, { PropsWithChildren, useContext } from 'react';
import { HistoryFavoritesPanel } from './HistoryFavoritesPanel';
import { DefaultHistoryData, HistoryFavoritesConfig, HistoryFavoritesData, useHistoryFavoritesMain } from './useHistoryFavoritesMain';


const HistoryFavoritesContext = React.createContext<HistoryFavoritesData>({
    history: DefaultHistoryData,
    trackHistory: () => { },
    saveFavorite: () => { },
    removeFavorite: () => { },
});

export const useHistoryFavorites = () => useContext(HistoryFavoritesContext);

interface Props extends HistoryFavoritesConfig {
    
}

export const HistoryFavorites = (props: PropsWithChildren<Props>) => {
    const { children, ...config } = props;
    const data = useHistoryFavoritesMain(config);

    return (
        <HistoryFavoritesContext.Provider value={data}>
            {children}
            <HistoryFavoritesPanel data={data} />
        </HistoryFavoritesContext.Provider>
    );
}
