import { useLoadedData } from "../../hooks/useLoadedData";
import { ProgramExecution } from "./types";

export const useProgramExecutions = (apiPath: string, programId: number) => {
    const data = useLoadedData<ProgramExecution[]>(`${apiPath}/execution?program_id=${programId}`, [], programId !== undefined);

    return {
        ...data,
    }
}
