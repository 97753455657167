import { utc } from "../timezone";
import { useState } from "react";
import { LoadedData, useLoadedData } from "../../hooks/useLoadedData";
import moment, { MomentInput } from "moment";
import { DateTimeInternalFormat } from "../../api/datetimeUtil";

export interface EventLog {
    _id: number;
    kind: string;
    message: any;
    created_at: string;
}

interface ELFilters {
    kind?: string | null;
    timeFrom?: MomentInput | null;
    timeTo?: MomentInput | null;
}

export interface EventLogData extends LoadedData<EventLog[]> {
    filters: ELFilters;
    updateFilters: (changes: Partial<ELFilters>) => void;
    applyFilters: () => void;
    getJson: () => string;
}

export const useEventLog = (apiPath: string): EventLogData => {
    const defaultFilter = {
        timeFrom: moment(new Date()).add(-24, 'hours'),
        timeTo: moment(new Date()),
    }
    const [filters, setFilters] = useState<ELFilters>(defaultFilter);
    const [activeFilters, setActiveFilters] = useState<ELFilters>(defaultFilter);

    const filtersQuery = [
        activeFilters.kind ? `kind=${ activeFilters.kind}` : null,
        activeFilters.timeFrom ? `from=${utc.toServer(activeFilters.timeFrom).format(DateTimeInternalFormat)}` : null,
        activeFilters.timeTo ? `to=${utc.toServer(activeFilters.timeTo).format(DateTimeInternalFormat)}` : null,
    ].filter(x => !!x).join("&");

    const data = useLoadedData<EventLog[]>(`${apiPath}?${filtersQuery}`, []);

    const getJson = () => JSON.stringify(data.data, null, 2);

    return {
        ...data,
        filters,
        updateFilters: c => setFilters(f => ({ ...f, ...c })),
        applyFilters: () => setActiveFilters(filters),
        getJson,
    }
}
