import React, { createContext, ReactNode, useContext } from "react";
import { useNotificationsData } from "./useNotifications";

type NotificationsContextType = ReturnType<typeof useNotificationsData>;

export const NotificationsContext = createContext<NotificationsContextType>({
    notifications: [],
    read: (_id: string) => {},
    readAll: () => {},
})

export const useNotifications = () => useContext(NotificationsContext);

export const NotificationsProvider = ({ children }: {children: ReactNode}) => {
    return <NotificationsContext.Provider value={useNotificationsData()}>
        {children}
    </NotificationsContext.Provider>
}