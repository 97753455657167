import React from 'react';
import { ViewModule } from "@material-ui/icons";
import { Editor } from "slate";
import { CustomElement } from "../../../../../slate";
import { EditorPlugin } from "../../slate/PowerEditorConfig";
import { generateCode } from "../common";
import { TilesBlockElement, TilesBlockElementType } from "./TilesBlockElement";

const withTilesBlock = (editor: Editor) => {
    const { isVoid } = editor;
    editor.isVoid = (element: CustomElement) => {
        return element.type === TilesBlockElementType ? true : isVoid(element);
    }
    
    return editor;
}

export const TilesPlugin: EditorPlugin = {
    key: "tiles-block",
    commands: [{
        name: "insert-tiles-block",
        menu: { section: "insert-item", label: "Tiles", label_id: "powerdoc.plugins.tiles.title", icon: <ViewModule /> },
        invoke: editor => {
            const firstFieldCode = generateCode();
            editor.insertNode({
                type: TilesBlockElementType,
                tiles: [{ id: firstFieldCode }],
                children: [{ text: "", }],
            } as CustomElement);
        }
    }],
    customBlocks: { [TilesBlockElementType]: TilesBlockElement },
    inject: e => withTilesBlock(e),
};
