import { useState } from "react";
import { apiFetch } from "../../api/core";
import { useAction } from "../../api/useAction";
import { DialogState, useDialogState } from "../primitives";
import { IDefaultRolesUser } from "./useUserManagement";

interface Config {
    apiPath: string;
    onSuccess?: () => void;
}

export interface SendInvitesData<IUser extends IDefaultRolesUser> {
    dryRunUsers: IUser[];
    isRunning: boolean;
    doDryRun: () => Promise<IUser[]>;
    sendInvites: () => Promise<IUser[]>;
    sendUserInvite: (userId: string) => Promise<IUser>;
    start: () => void;
    dialog: DialogState;
};

export const useSendInvites = <IUser extends IDefaultRolesUser>(cfg: Config): SendInvitesData<IUser> => {
    const [dryRunUsers, setDryRunUsers] = useState<IUser[]>([]);
    const dialog = useDialogState();

    const apiPath = `${cfg.apiPath}/invite-new`;

    const dryRunAction = useAction(
        () => apiFetch<IUser[]>(`${apiPath}?dry-run=true`, "post")
            .then(users => {
                setDryRunUsers(users);
                return users;
            }));
            
    const sendAction = useAction(
        () => apiFetch<IUser[]>(apiPath, "post")
            .then(users => {
                if(cfg.onSuccess) {
                    cfg.onSuccess();
                }
                return users;
            }));

    const sendUserInvite = (userId: string) => {
        return apiFetch<IUser>(`${cfg.apiPath}/${userId}/invite`, "post")
            .then(x => {
                if(cfg.onSuccess) {
                    cfg.onSuccess();
                }
                return x;
            });
    }
    
    return {
        dryRunUsers,
        isRunning: dryRunAction.isRunning || sendAction.isRunning,
        doDryRun: dryRunAction.run,
        sendInvites: sendAction.run,
        sendUserInvite,
        dialog,
        start: () => {
            setDryRunUsers([]);
            dryRunAction.run();
            dialog.open();
        }
    }
}
