export const LinkedRecordsBlockElementType = "cms-linked-records";

export const UpdateLinkedRecordsProps = "cms.linked-records.update-props";
export const StartSelectingLinkedRecords = "cms.linked-records.start-select";


export interface LinkedRecordsSettings {
    record_type: string | null;
    records_ids: number[];
}
