import React from 'react';
import styled from '@emotion/styled';
import { FormattedMessage } from 'react-intl';
import { OccupyFreeSpace } from '../primitives/ActionRow';
import { SaveButton } from '../primitives/Buttons';
import { DeleteButton } from '../primitives/DeleteButton';
import { Form } from '../primitives/Forms';
import { FieldDefinition, FormControlsForFields } from '../schemed';
import { EditPageData } from './usePages';
import { Divider, IconButton } from '@material-ui/core';
import { ComponentManagementForm } from './ComponentManagementForm';
import { useHistory, useLocation } from 'react-router-dom';
import { KeyboardReturn } from '@material-ui/icons';

interface Props {
    data: EditPageData;
    customComponentsDescription?: [string,string][];
}

const UpperForm = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 0.75rem;
    row-gap: 0.25rem;

    grid-template-areas: 
        "slug lang"
        "styles styles"
        "properties properties";

    & > :nth-child(1) {
        grid-area: slug;
    }
    & > :nth-child(2) {
        grid-area: lang;
    }
    & > :nth-child(3) {
        grid-area: styles;
    }
    & > :nth-child(4) {
        grid-area: properties;
    }
`;

export const EditPageForm = ({ data, customComponentsDescription }: Props) => {
    const history = useHistory();
    const location = useLocation();

    const backToPageList = () => {
        let newPath = location.pathname.replace(data.data._id, '');
        if(newPath.endsWith('/')) {
            newPath = newPath.substr(0, newPath.length-1);
        }
        history.replace({ pathname: newPath, search: location.search });
    }

    const pageControls = (fields: FieldDefinition[]) => (
        <FormControlsForFields
            data={data.data}
            fields={fields}
            schema={data.schema}
            onChange={(o,c) => data.update(c)}
            />
    );

    return (
        <Form
            title={<FormattedMessage id="pages.editPageTitle" />}
            headerItems={
                <>
                    <OccupyFreeSpace />
                    {data.hasChanges && <SaveButton action={() => data.save()} />}
                    <IconButton size="small" onClick={() => backToPageList()}><KeyboardReturn /></IconButton>
                    <DeleteButton
                        preventGoBack
                        title={<FormattedMessage id="pages.deleteConfirmation" />}
                        remove={() => data.remove().then(() => backToPageList())}
                        />
                </>
            }>
            <UpperForm>
                {pageControls([
                    ["slug"],
                    ["language"],
                    ["styles", { rows: 4 }],
                    ["properties", { rows: 4 }],
                    ])}
            </UpperForm>
            <Divider />
            <ComponentManagementForm componentData={data.components} customComponentsDescription={customComponentsDescription} />
        </Form>
    );
}
