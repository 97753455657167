import moment from 'moment';
import "moment-timezone";

export const toLocal = (date: moment.MomentInput) => 
    moment.tz(date, 'Europe/Moscow').local();

export const toServer = (date: moment.MomentInput) =>
    moment(date).tz('Europe/Moscow');

export const utc = {
    toLocal: (date: moment.MomentInput) => 
        moment.utc(date).local(),

    toServer: (date: moment.MomentInput) =>
        moment(date).utc(),
}