import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { NewItemProps } from '../../api/useNewItem';
import { useItemWithControls } from '../../api/useWithControls';
import { Schema } from '../../hooks/useSchema';
import { FormGrid, SimpleDialog, SimpleList } from '../primitives';
import { RobudConfig } from './Config';
import { RobudPagesLinks } from './RobudPagesLinks';
import { CreateProgram, Program } from './types';
import { useProgramsList } from './useProgramsList';

interface Props extends RobudConfig {
    
}

export const RobudProgramsListPage = (props: Props) => {
    const data = useProgramsList(props.apiPath);
    const history = useHistory();
    let { path } = useRouteMatch();

    const { formatMessage } = useIntl();

    return (<>
        <SimpleList
            title={<FormattedMessage id="robud.programs.page_title" />}
            headerActions={<>
                <RobudPagesLinks {...props} active="programs" />
            </>}
            data={data}
            schema={data.schema}
            getLinkValue={n => `${path}/${n._id}`}
            searchAuto
            searchAutoFocus
            fields={[
                ["_id"],
                ["title"],
                ["data"],
                ["operations"],
                ["created_at", { utcToLocal: true }],
            ]}
            linkFields={["title", "_id"]}
            fieldElement={f => {
                switch(f) {
                    case "data":
                        return r => r.data?.kind ? formatMessage({ id: `robud.program.data.kind.${r.data.kind}` }) : "";
                    case "operations":
                        return r => r.operations.map(o => formatMessage({ id: `robud.program.operations.kind.${o.kind}` })).join(", ");
                }
            }}
            />
        <CreateProgramPopup
            {...data.newItem}
            save={() => {
                return data.newItem.save()
                    .then(n => {
                        history.push(`${path}${n._id}`);
                        return n;
                    });
            }}
            schema={data.schema}
            />
    </>);
}

interface CreateProgramProps extends NewItemProps<CreateProgram, Program> {
    schema: Schema;
}

const CreateProgramPopup = (props: CreateProgramProps) => {
    const { isEditing, item, save, cancel, schema, update, errors } = props;
    const { controls } = useItemWithControls({ data: item || {} as CreateProgram, update, errors }, { schema });

    return (
        <SimpleDialog
            isOpen={isEditing}
            close={cancel}
            dialogTitle={<FormattedMessage id="robud.programs.create_program" />}
            save={() => save()}
            noFullscreen
            >
            <FormGrid columns="1fr">
                {controls([
                    ["title"],
                ])}
            </FormGrid>
        </SimpleDialog>);
}