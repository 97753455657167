import { useState } from 'react';

export interface IError {

}

export interface ValidationErrors {
    errors: Record<string, IError[]>;
    hasErrors: boolean;
    getErrorsForField: (field: string) => IError[];
    fieldHasErrors: (field: string) => boolean;

    captureErrors: (e: any) => void;
    handleErrors: (e: any) => void;
    clearErrors: () => void;
    getInnerErrors: (accessor: (string|number)[]) => ValidationErrors;
}

export const useValidationErrors = (): ValidationErrors => {
    type ET = Record<string, IError[]>;
    const [errors, setErrors] = useState<ET>({} as ET);

    const getErrorsForField = (field: string) => errors[field] || [];
    const clearErrors = () => { setErrors({} as ET); };

    const captureErrors = (e: any) => {
        const errors = e.response?.data?.errors;
        setErrors({  ...(errors || {}) as ET, });
        return errors;
    };

    const handleErrors = (e: any) => {
        if(captureErrors(e)) {
            const x = { ...e };
            if(!x.response.data.error_code) {
                x.response.data.error_code = "error.validation";
            }
            throw x;
        }
    }

    const getInnerErrors = (accessor: (string|number)[]): ValidationErrors => {
        const noControl = () => { throw new Error("Can't call control methods on inner errors object.") };

        const innerErrors = accessor.reduce((r,k) => r ? (r[k] || {}) : {}, errors);

        const getForField = (field: string) => innerErrors[field] || [];

        return {
            errors: innerErrors,
            hasErrors: Object.keys(innerErrors).length > 0,
            getErrorsForField: getForField,
            fieldHasErrors: (field: string) => getForField(field).length > 0,
    
            captureErrors: noControl,
            handleErrors: noControl,
            clearErrors: noControl,
    
            getInnerErrors: noControl,
        }
    }

    return {
        errors,
        hasErrors: Object.keys(errors).length > 0,
        getErrorsForField,
        fieldHasErrors: (field: string) => getErrorsForField(field).length > 0,

        captureErrors,
        handleErrors,
        clearErrors,

        getInnerErrors,
    }
}