import { useMemo, useState } from "react";

export interface WithTextFilter {
    filter: string;
    setFilter: (v: string) => void;
}

export interface TextFilter<T> extends WithTextFilter {
    filterData: (rows: T[]) => T[];
}

interface Config<T> {
    exactFields?: (keyof T)[];
}


export const useTextFilter = <T, >(rowToString: (row: T) => string, defaultValue: string = "", cfg?: Config<T>): TextFilter<T> => {
    const [filter, setFilter] = useState<string>(defaultValue);
    
    const filterData: TextFilter<T>["filterData"] = useMemo(() => {
      const searchMatches = (r: T) => rowToString(r).toLowerCase().includes(filter.toLowerCase());
      const exactFieldsMatch = cfg?.exactFields && cfg?.exactFields.length ? (r: T) => (cfg?.exactFields || []).filter(f => (r[f] || "") === filter).length > 0 : () => false;
      return rows => filter.length ? rows.filter(r => searchMatches(r) || exactFieldsMatch(r)) : rows;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter, cfg?.exactFields?.length, rowToString]);

    return {
        filter,
        setFilter,
        filterData,
    }
}
