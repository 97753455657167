import { EditorPlugin } from "../../slate/PowerEditorConfig";
import { createWithVoidInjector } from '../common';
import { DividerBlockType, DividerBlockElement } from './Divider';

const withDivider = createWithVoidInjector(DividerBlockType);


export const BasicElementsPlugin: EditorPlugin = {
    key: "basic-elements",
    inject: withDivider,
    customBlocks: {
        [DividerBlockType]: DividerBlockElement,
    },
    commands: [
        {
            name: "insert-divider",
            invoke: e => {
                e.insertNode({
                    type: DividerBlockType,
                    children: [{ text: "", }],
                });
            },
            menu: {
                section: "elements",
                label_id: "powerdoc.elements.divider",
            }
        },
    ],
}
