import { useState } from "react";
import { Transforms } from "slate";
import { ReactEditor, useSlateStatic } from "slate-react";
import { CustomElement } from "../../../../../slate";

interface WithColumns {
    columns_content: any[];
}

export const useColumns = (element: CustomElement) => {
    const editor = useSlateStatic();
    const path = ReactEditor.findPath(editor, element);

    const columns = (element as any).columns as number || 2;

    const [localState, setLocalState] = useState<WithColumns>({
        columns_content: (element as any).columns_content as any[] || Array(columns).map(c => ({})),
    });

    const update = (updated: WithColumns) => {
        setLocalState(updated);
        Transforms.setNodes(
            editor,
            updated as any,
            { at: path },
        );
    }

    const updateColumnContent = (columnIdx: number, newContent: any) => {
        update({
            ...localState,
            columns_content: localState.columns_content.map((cc,i) => i === columnIdx ? newContent : cc),
        });
    }

    const updateColumnsNumber = (nColumns: number) => {
        if(nColumns === localState.columns_content.length) {
            return;
        }

        update({
            ...localState,
            columns_content: localState.columns_content.length < nColumns
                ? [...localState.columns_content, ...Array(nColumns - localState.columns_content.length).map(x => ({}))]
                : localState.columns_content.slice(0, nColumns),
        });
    }

    return {
        ...localState,
        updateColumnContent,
        updateColumnsNumber,
    }
}