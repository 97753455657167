import { flatten } from 'flat';
import React, { ReactNode, useContext, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { messagesDict } from './messagesDict';

export interface LocalizationProviderProps {
    locale: string;
    defaultLocale: string;
    messages?: any;
    children?: ReactNode;
    customMessagesMultiLocale?: boolean;
}

const LocalizationProvider: React.FC<LocalizationProviderProps> = (props: LocalizationProviderProps) => {
    const { locale, defaultLocale, messages, customMessagesMultiLocale } = props;

    const customMessages = messages || {};
    let mergedMessages = flatten(customMessagesMultiLocale ? customMessages[locale] : customMessages) as any;

    const typedMessagesDict = messagesDict as { [locale: string]: any };

    if (typedMessagesDict[locale]) {
        const localeMessages = flatten(typedMessagesDict[locale]) as Object;
        mergedMessages = { ...localeMessages, ...mergedMessages };
    }

    return (
        <IntlProvider messages={mergedMessages} locale={locale} defaultLocale={defaultLocale}>
            {props.children}
        </IntlProvider>
    )
}

export interface ILocaleContext {
    locale: string;
    setLocale: (locale: string) => void;
}

const LocaleContext = React.createContext<ILocaleContext>({ locale: "en", setLocale: () => { }});

export const useLocale = () => useContext(LocaleContext);

const HookedLocalizationProvider = (props: Omit<LocalizationProviderProps, "locale">) => {
    const { locale } = useLocale();

    return (
        <LocalizationProvider
            locale={locale}
            defaultLocale={props.defaultLocale}
            customMessagesMultiLocale={props.customMessagesMultiLocale}
            messages={props.messages}>
            {props.children}
        </LocalizationProvider>
    )
}

interface SwitchableProps extends Omit<LocalizationProviderProps, "locale"> {
    localStorageKey?: string;
}

export const SwitchableLocalizationProvider = (props: SwitchableProps) => {
    const defaultLocale = (props.localStorageKey ? localStorage.getItem(props.localStorageKey) : null) || props.defaultLocale;
    const [locale, setLocaleX] = useState<string>(defaultLocale);

    const setLocale = (locale: string) => {
        if(props.localStorageKey) {
            localStorage.setItem(props.localStorageKey, locale);
        }
        setLocaleX(locale);
    }

    return <LocaleContext.Provider value={{ locale, setLocale }}>
        <HookedLocalizationProvider {...props} />
    </LocaleContext.Provider>
}

export default LocalizationProvider;