import React, { ReactNode, useEffect } from 'react';
import styled from '@emotion/styled';
import { Button, Typography, withTheme } from '@material-ui/core';
import { MarkdownDisplay } from '../../schemed/Markdown';
import { ScoringData } from './useScoring';
import { FormattedMessage } from 'react-intl';
import { NumberScore, ScoringControlProps } from './Controls';
import { TextScore } from './Controls/TextScore';
import { CheckboxScore } from './Controls/CheckboxScore';
import { LoadingIndicator } from '../../primitives/LoadingIndicator';

export type ScoringComponentMapping = Record<string, React.ComponentType<ScoringControlProps>>;

interface Props {
    data: ScoringData;
    wrapper?: React.ComponentType<any>;
    hideTitle?: boolean;
    hideDescription?: boolean;
    onCancel?: () => void;
    onSubmit?: () => void;

    title?: ReactNode;
    frontHint?: ReactNode;
    tailHint?: ReactNode;
    customComponents?: ScoringComponentMapping;
    noButtons?: boolean;
}

export const SheetButtons = styled.div`
    margin-top: 1rem;
    display: flex;
    flex-flow: row;
    justify-content: flex-end;
    gap: 0.5rem;
`;

export const SheetWrapper = styled.div`
`;

const CriteriaWrapper = withTheme(styled.div`
    margin: 1rem 0;
    border-top: 1px solid ${props => props.theme.palette.grey[200]};
`);

const ControlWrapper = withTheme(styled.div`
    border-bottom: 1px solid ${props => props.theme.palette.grey[200]};
    display: grid;
    position: relative;
    padding: 0.5rem 2.5rem;

    & .score-idx {
        position: absolute;
        top: 0.5rem;
        left: 0.5rem;
    }
`);

const DefaultComponents: ScoringComponentMapping = {
    number: NumberScore,
    text: TextScore,
    bool: CheckboxScore,
}

export const ScoringSheetForm = (props: Props) => {
    const {
        sheet,
        canSubmit,
        submit,
        isSubmitted,
        isLoading,
    } = props.data;

    useEffect(() => {
        if(isSubmitted && props.onSubmit) {
            props.onSubmit();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSubmitted]);
    
    const Wrapper = props.wrapper || SheetWrapper;

    const components = { ...DefaultComponents, ...(props.customComponents || {}) };

    return (
        <Wrapper>
            {!props.hideTitle && <Typography variant="h6">{props.title || sheet.title}</Typography>}
            {!props.hideDescription && sheet.description && sheet.description.length && <MarkdownDisplay text={sheet.description} />}

            {props.frontHint}

            {isLoading && <LoadingIndicator />}
            
            {!isLoading && <CriteriaWrapper className="scores-container">
                {(sheet.criteria || []).map((c,idx) => {
                    const Component = components[c.type];
                    return (
                        <ControlWrapper className="score">
                            <Typography className="score-title">{c.name}</Typography>
                            <Typography className="score-idx">{idx+1}.</Typography>
                            {c.comment && c.comment.length &&
                                <Typography variant="caption" className={`score-comment ${c.type}`}>{c.comment}</Typography>}

                            {Component && <Component key={c._id} data={props.data} criteria={c} />}
                        </ControlWrapper>
                )})}
            </CriteriaWrapper>}

            {props.tailHint}

            {!props.noButtons && <SheetButtons>
                {props.onCancel && <Button onClick={() => props.onCancel ? props.onCancel() : null}>
                    <FormattedMessage id="common.cancel" />
                </Button>}
                <Button
                    variant="contained"
                    color="primary"
                    disabled={!canSubmit}
                    onClick={submit}>
                    <FormattedMessage id="common.save" />
                </Button>
            </SheetButtons>}
        </Wrapper>
    );
}
