import React from 'react';
import { usePages } from './usePages';
import { PageManagementForm } from './PageManagementForm';

interface Props {
    apiPath: string;   
}

export const PagesListContainer = ({ apiPath }: Props) => {
    const data = usePages(apiPath);
    return <PageManagementForm pagesData={data} />;
}
