import moment from "moment";

export interface ExpressionProcessor {
    getValue: (expression?: string) => any;
    getFormattedValue: (expression?: string) => string;
    checkCondition: (expression?: string) => boolean;
}

const processAddition = (l: any, r: any, rSign: 1 | -1) => {
    if(/\d+d/.test(r)) {
        const nDays = +(r.replace(/[a-zA-Z]+/, ""));
        return moment(l).add(rSign*nDays, "days").toString();
    } else {
        return +l + (rSign * (+r));
    }
}

const binaryOperations: Record<string, (l: any, r: any) => any> = {
    ">=": (l,r) => l >= r,
    "<=": (l,r) => l <= r,
    // eslint-disable-next-line eqeqeq
    "=": (l,r) => l == r,
    ">": (l,r) => l > r,
    "<": (l,r) => l < r,
    "+": (l,r) => processAddition(l,r, 1),
    "-": (l,r) => processAddition(l,r, -1),
}

const tokenizeExpression = (expression?: string) => {
    let e = (expression || "").trim();
    const operation = Object.keys(binaryOperations).find(op => e.includes(op));
    if(operation) {
        const [left,right] = e.split(operation);
        return {
            operation,
            left: left.trim(),
            right: right.trim(),
        }
    } else {
        return {
            left: e,
        }
    }
}

const partRE = /([.*]*)([^.*]+)/;

const breakAccessor = (expression?: string): { dot: string, accessor: string }[] => {
    const e = (expression || "").trim();
    let remaining = e;
    const result = [];
    while(remaining.length) {
        const match = remaining.match(partRE);
        if(match) {
            const [m, dot, accessor] = match;
            result.push({ dot, accessor });
            remaining = remaining.substring(m.length).trim();
        } else {
            return result;
        }
    }
    return result;
}

const getAccessorFn = (accessor: string): (v: any) => any => {
    if(accessor === "count") {
        return v => Array.isArray(v) ? v.length : 0;
    } else if(accessor.startsWith("join")) {
        let separator = accessor.substring("join".length);
        if(separator.startsWith("(")) {
            separator = separator.substring(1, separator.length - 1);
        }
        separator = separator || " ";
        return v => Array.isArray(v) ? v.join(separator) : v;
    }
    return v => (v || {})[accessor];   
}

export const entitySetExpressionProcessor = (entities: Record<string, any>, formatValue: (entity: string, field: string, value: any) => string): ExpressionProcessor => {
    const getValueBase = (expression?: string): any => {
        const { left, operation, right } = tokenizeExpression(expression);

        if(operation) {
            // console.log("OP", operation, getValue(left.trim()), getValue(right.trim()), binaryOperations[operation](getValue(left.trim()), getValue(right.trim())));
            return binaryOperations[operation](getValue(left), getValue(right));
        }

        // const parts = left.split(".");
        const parts = breakAccessor(left);
        if(parts.length > 1) {
            return parts.reduce(
                (r,part) => {
                    const accessorFn = getAccessorFn(part.accessor);
                    return part.dot === "*" ? (r || []).map(accessorFn) : accessorFn(r);
                },
                entities);
        } else if(parts.length === 1) {
            const val = parts[0].accessor;
            const valLc = val.toLowerCase();
            if(valLc === "false") {
                return false;
            }
            if(valLc === "true") {
                return true;
            }
            if(valLc === "null") {
                return null;
            }
            if(val.startsWith("\"") && val.endsWith("\"")) {
                return val.substring(1, val.length-1);
            }
            return val;
        } else {
            return undefined;
        }
    };

    const getValue = (expression?: string): any => {
        let e = (expression || "").trim();
        if(e.startsWith("!")) {
            return !getValueBase(e.replace(/!\s*/, ""));
        }

        return getValueBase(e);
    };

    const getFormattedValue = (expression?: string): string => {
        const value = getValue(expression);

        const [entity,field] = tokenizeExpression(expression).left.split(".");

        if(entity && field) {
            return formatValue(entity, field, value);
        } else {
            return (value || "").toString();
        }
    }

    const checkCondition = (expression?: string): boolean => {
        if(expression === "true" || expression === "1") {
            return true;
        } else {
            return !!getValue(expression);
        }
    }

    return {
        getValue,
        getFormattedValue,
        checkCondition,
    }
}
