import { withTheme } from '@material-ui/core';
import styled from '@emotion/styled';
import { ScrollBarStyle as pScrollBarStyle, scrollStyle as pscrollStyle } from '../../primitives';

export const AppContent = styled.div`
    display: grid;
    grid-template-rows: max-content;
    grid-auto-rows: 1fr;
    box-sizing: border-box;

    flex-grow: 1;
    width: 100%;
    min-height: 100vh;
    background-color: white;
    overflow-x: hidden;
    padding: 0;

    position: relative;

    & .MuiAppBar-root {
        box-shadow: none;
    }
`;
AppContent.defaultProps = {className: 'AppContent'};

export type ScrollBarStyle = pScrollBarStyle;
export const scrollStyle = pscrollStyle;


export const AppContentSimple = withTheme(styled.div<{ fit100Height?: boolean, scrollBarsStyle?: ScrollBarStyle, }>`
    overflow-x: hidden;
    width: 100%;
    min-height: 100vh;
    max-height: ${props => props.fit100Height ? '100vh' : 'none'};
    background-color: white;

    ${props => props.scrollBarsStyle ? scrollStyle(props.theme, props.scrollBarsStyle) : ''}
`);
