import React from 'react';
import styled from '@emotion/styled';
import { RenderElementProps, useSelected } from 'slate-react';
import { withTheme } from '@material-ui/core';

export const DividerBlockType = "divider";

const DividerWrapper = withTheme(styled.div<{ isSelected?: boolean }>`
    width: 100%;
    & > hr {
        width: 100%;
        margin: 0.5rem 0;
        border-top: 2px solid ${props => props.isSelected ? props.theme.palette.primary.main : props.theme.palette.divider};
        border-bottom: none;
    }
`);

export const DividerBlockElement = (props: RenderElementProps) => {
    const { attributes, children } = props;

    const isSelected = useSelected();

    return (
        <DividerWrapper {...attributes} isSelected={isSelected}>
            {children}

            <hr contentEditable={false} />
        </DividerWrapper>
    );
}
