import { IconButton } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FieldType } from '../../hooks/useSchema';
import { OccupyFreeSpace } from '../primitives/ActionRow';
import { PseudoLink } from '../primitives/Common.styles';
import { Form } from '../primitives/Forms';
import { LoadingIndicator } from '../primitives/LoadingIndicator';
import { FormControl, TableForFields } from '../schemed';
import { NotificationTaskPopup } from './NotificationTaskPopup';
import { NotificationTasksData } from './useNotificationTasks';
import { SearchField } from '../primitives';

export interface NotificationTasksListConfig {
    emailsPagePath?: string;
}

interface Props extends NotificationTasksListConfig {
    data: NotificationTasksData;
}

export const NotificationTaskList = (props: Props) => {
    const { data } = props;

    return (
        <Form
            title={<FormattedMessage id="notifications.task.controls.title" />}
            headerItems={<>
                <IconButton onClick={data.startCreate}>
                    <Add />
                </IconButton>
                {data.isLoading && <LoadingIndicator sizeVariant="m" />}
                <OccupyFreeSpace />

                <FormControl
                    field="show_executed"
                    schema={{ type: FieldType.bool, label_id: "notifications.task.controls.show_executed"}}
                    row={{ show_executed: data.isShowExecuted }}
                    onChange={(o,c) => data.setIsShowExecuted(c.show_executed)}
                    />
                <SearchField
                  filter={data.filter.filter}
                  setFilter={data.filter.setFilter}
                  noButton
                  autoFocus
                  small
                  doSearch={() => {
                    if(data.tasks.length === 1) {
                      data.startEdit(data.tasks[0]);
                    }
                  }}
                  />
            </>}
        >
            <TableForFields
                data={data.tasks}
                schema={data.schema}
                fields={[
                    ["title"],
                    ["comment"],
                    ["targets"],
                    ["executed_time", { utcToLocal: true }],
                ]}
                fieldElement={f => {
                    switch(f) {
                        case "targets":
                            return (row) => (row.targets || []).length.toString();
                        case "title":
                            return (row, _, original) => <PseudoLink onClick={() => data.startEdit(row)}>{original}</PseudoLink>;
                        default:
                            return null;
                    }
                }}
            />

            <NotificationTaskPopup {...props} />
        </Form>
    );
}
