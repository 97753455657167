import { Descendant } from "slate";
import { CustomElement } from "../../../../slate";
import { ConditionalBlockElementType } from "../plugins";
import { PlaceholderElementType } from "../plugins/Placeholders/usePlaceholders";
import { PowerDoc } from "../types";
import { ExpressionProcessor } from "./expressionProcessor";


const fillPlaceholders = (block: Descendant, exp: ExpressionProcessor) => {
    const { children, ...itemParams } = block as any;
    if(itemParams.type === PlaceholderElementType) {
        return {
            ...(itemParams.marks || {}),
            text: exp.getFormattedValue(itemParams.expression),
        };
    } else {
        return children
            ? { ...itemParams, children: children.map((c: Descendant) => fillPlaceholders(c, exp)) }
            : { ...itemParams };
    }
}

const checkCondition = (condition: string | undefined, exp: ExpressionProcessor) => {
    return exp.checkCondition(condition);
}

const processConditionalBlocks = (blocks: Descendant[], exp: ExpressionProcessor): Descendant[] => {
    return blocks.reduce<Descendant[]>((result, block) => {
        if((block as any).type === ConditionalBlockElementType) {
            const b = (block as CustomElement);
            if(checkCondition(b.condition, exp)) {
                ((b.content.blocks || []) as Descendant[]).forEach(c => result.push(c));
            }
        } else {
            result.push(block);
        }

        return result;
    }, []);
}

export const processPowerDocTemplate = (doc: PowerDoc, exp: ExpressionProcessor) => {
    const blocks = processConditionalBlocks(doc.content.blocks || [], exp)
        .map(b => fillPlaceholders(b, exp));

    return {
        ...doc,
        content: {
            ...doc.content,
            blocks,
        },
    };
}
