import React, { ReactNode, useState } from 'react';
import { TextField, Button, Typography } from '@material-ui/core';
import { Signup, SignupMode } from '../../hooks/auth';
import { OuterCentralizedForm } from '../primitives/Forms';
import { FormattedMessage } from 'react-intl';

interface Props {
    mode: SignupMode;
    onErrorMessage?: ReactNode;
    onSuccessMessage?: ReactNode;
    signupData: Signup;
}

export const SignupForm: React.FC<Props> = (props: Props) => {
    const { mode, onErrorMessage, onSuccessMessage, signupData } = props;
    const { send: submit, credentials, update: onChangeCredentials, isError } = signupData;
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

    const isPremade = mode === SignupMode.Invite;

    const onSubmit = () => {
        submit()
            .then(() => setIsSubmitted(true))
    }

    return (
        <form onSubmit={e => { e.preventDefault(); onSubmit(); }}>
            <OuterCentralizedForm>
                {isSubmitted ?
                    isError ?
                        (onErrorMessage || <Typography><FormattedMessage id="signup.errorMessage"/></Typography>)
                        : (onSuccessMessage || <Typography><FormattedMessage id="signup.successMessage"/></Typography>)
                    :
                    <>
                        <TextField
                            label={<FormattedMessage id="signup.email"/>}
                            name='email'
                            variant='outlined'
                            disabled={isPremade}
                            value={credentials.login}
                            onChange={e => onChangeCredentials({ ...credentials, login: e.target.value || '' })} />
                        <TextField
                            label={<FormattedMessage id="signup.password"/>}
                            name='password'
                            type='password'
                            variant='outlined'
                            value={credentials.password}
                            onChange={e => onChangeCredentials({ ...credentials, password: e.target.value || '' })} />
                        <TextField
                            label={<FormattedMessage id="signup.passwordRepeat"/>}
                            name='password'
                            type='password'
                            variant='outlined'
                            value={credentials.passwordRepeat}
                            onChange={e => onChangeCredentials({ ...credentials, passwordRepeat: e.target.value || '' })} />
                        <Button type='submit' variant='contained' color='primary'>
                            <FormattedMessage id="signup.submit"/>
                        </Button>
                    </>
                }
            </OuterCentralizedForm>
        </form>
    )
};
