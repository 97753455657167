import React from 'react';
import { TextField } from '@material-ui/core';
import { ScoringControlProps } from './typings';

export const TextScore = ({ data, criteria }: ScoringControlProps) => {
    const {
        scores,
        update,
    } = data;

    const id = criteria._id as string;
    
    return <TextField multiline className="text" value={scores[id] || ""} onChange={e => update({ [id]: e.target.value })} />
}
