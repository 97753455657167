import { useHistory } from "react-router-dom";
import { apiFetch, FetchTypes } from "../../api/core";
import { login, useUser } from "../../userContext/UserContext";

export type LoginAs = (userId: string) => void;

export const useLoginAs = (apiPath: string, redirectTarget: string): LoginAs => {
    const history = useHistory();
    const userContext = useUser();

    return (userId: string) =>
        apiFetch<string>(`${apiPath}/${userId}`, FetchTypes.POST)
            .then(token => {
                login(userContext, token);
                history.replace(redirectTarget)
            });
}