import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Editor, Transforms } from 'slate';
import { ReactEditor, RenderElementProps, useSelected, useSlateStatic } from 'slate-react';
import { CustomElement } from '../../../../../slate';
import { EditorPlugin } from '../../slate/PowerEditorConfig';
import { BlockSelectionCss } from '../../elements/Common';
import { TextField, withTheme } from '@material-ui/core';
import { usePowerEditorContext } from '../../slate/PowerEditorContext';
import { FormattedMessage } from 'react-intl';

export const ScriptElementType = "script";

const Wrapper = withTheme(styled.div<{ isSelected?: boolean }>`
    padding: 0.5rem 1rem;
    border-top: 2px solid #eeeeee;
    border-bottom: 2px solid #eeeeee;
    ${props => BlockSelectionCss(props)}
    width: 100%;
    box-sizing: border-box;

    & textarea {
        font-family: 'Courier New', monotype;
    }
`);

const ScriptElement = (props: RenderElementProps) => {
    const { attributes, element, children } = props;

    const editor = useSlateStatic();
    const { viewMode } = usePowerEditorContext();
    const isSelected = useSelected();

    const [valueLocal, setValueLocal] = useState<string>(element.value || "");

    const update = (v: string) => {
        const path = ReactEditor.findPath(editor, element);

        setValueLocal(v);
        Transforms.setNodes(
            editor,
            { value: v },
            { at: path },
        )
    }

    return (
        <Wrapper {...attributes} isSelected={isSelected}>
            {children}
            <div contentEditable={false}>
                <TextField
                    value={valueLocal}
                    onChange={e => update(e.target.value)}
                    InputProps={{ readOnly: viewMode }}
                    label={<FormattedMessage id="powerdoc.plugins.script.title" />}
                    multiline
                    fullWidth
                    variant="outlined"
                    />
            </div>
        </Wrapper>
    )
}

const withScript = (editor: Editor) => {
    const { isVoid } = editor;
    
    editor.isVoid = (element: CustomElement) => {
        return element.type === ScriptElementType ? true : isVoid(element)
    }
    
    return editor
}

export const ScriptPlugin: EditorPlugin = {
    key: "embed",
    customBlocks: { [ScriptElementType]: ScriptElement },
    inject: withScript,
    commands: [{
        name: "insert-script",
        invoke: e => {
            e.insertNode({ type: ScriptElementType, children: [{ text: "" }]});
        },
        menu: { section: "insert-item", label: "Script", label_id: "powerdoc.plugins.script.title" },
    }]
}