import { useSnackbar } from 'notistack';
import React, { useState, useEffect, useContext, createContext, ComponentPropsWithoutRef, useCallback, PropsWithChildren } from 'react';
import { useIntl } from 'react-intl';
import { apiFetch, FetchTypes } from '../api/core';
import { openFileUploader } from '../api/files';
import { Action, ActionOnItemWithConfirmation, useAction, useItemActionWithConfirmation } from '../api/useAction';
import { Schema } from './useSchema';
import { EditItemProps, useEditItem2 } from '../api/useNewItem';

export interface DictionaryRecord {
    code: string;
    label: string;
    disabled: boolean;
    comment: string;
    sortorder: number;
    label_translations?: { [_: string]: string };
    extra?: Record<string, any>;
}

export interface Dictionary {
    id: string;
    name: string;
    comment: string;
    records: DictionaryRecord[];

    values?: { value: any, label: string }[];
    valueDict?: any;
    extra?: { ui?: Schema };
}

export interface Dictionaries {
    [k: string]: Dictionary;
}

export type DictionariesService = Dictionaries & {
    reload: () => void;
}

const prepareDictionary = (dictionary: Dictionary) => {
    const values = (dictionary.records || []).map(({ code, label }) => ({ value: code, label }));
    const valueDict = values.reduce((result, { value, label }) => ({ ...result, [value]: label}), {});

    return {
        ...dictionary,
        values,
        valueDict,
    }
}

export const DictionariesContext = createContext<DictionariesService>({ reload: () => { }} as any);

interface DictionariesConfig {
    apiPath?: string;
    lang?: string;
}

export const DictionariesProvider = (props: ComponentPropsWithoutRef<any> & DictionariesConfig) => {
    const [dicts, setDicts] = useState<Dictionaries>({});

    const apiPath = props.apiPath || "/api/dictionary";

    const reload = useCallback(() => {
        apiFetch<Dictionary[]>(`${apiPath}/all-with-records${props.lang ? `?lang=${props.lang}` : ""}`)
            .then(ds => {
                const dicts = Object.values(ds).reduce((result,d) => ({ ...result, [d.name]: prepareDictionary(d) }), {});
                setDicts(dicts)
            })
    }, [apiPath, props.lang])

    useEffect(() => reload(), [reload]);

    const accessGuard = {
        get: (target: Dictionaries, name: string) => {
            const result = target[name];
            if(!result) {
                console.log(`Dictionary ${name} not loaded yet`);
                return prepareDictionary({} as Dictionary);
            } else {
                return result;
            }
        }
    };

    const guardedDicts = new Proxy(dicts, accessGuard);
    (guardedDicts as any).reload = () => {
        reload();
    }

    return (
        <DictionariesContext.Provider value={guardedDicts as DictionariesService}>
            {props.children}
        </DictionariesContext.Provider>
    )
}

interface DictionarisManual {
    dictionaries: Dictionaries;
}

export const DictionariesManualProvider = (props: PropsWithChildren<DictionarisManual>) => {
    const accessGuard = {
        get: (target: Dictionaries, name: string) => {
            const result = target[name];
            if(!result) {
                console.log(`Dictionary ${name} not loaded yet`);
                return prepareDictionary({} as Dictionary);
            } else {
                return result;
            }
        }
    };

    const guardedDicts = new Proxy(props.dictionaries, accessGuard);
    (guardedDicts as any).reload = () => { }

    return (
        <DictionariesContext.Provider value={guardedDicts as DictionariesService}>
            {props.children}
        </DictionariesContext.Provider>
    )
}

export const useDictionaries = (): DictionariesService => useContext(DictionariesContext);

export const ExtraFieldPrefix = "__extra_";

export interface DictsApi {
    loading: boolean;
    dicts: Dictionaries;
    dict: Dictionary | null;
    onUpdate: (record: DictionaryRecord, changes: any) => void;
    setDictByKey: (key: string) => void;
    
    newRecord: EditItemProps<Pick<DictionaryRecord, "code"| "label">>;
    remove: ActionOnItemWithConfirmation<DictionaryRecord, {}>;
    hasChanges: boolean;
    save: () => Promise<void>;

    exportAction: Action<void>;
    importAction: Omit<Action<void>, "buttonProps">;
};

export const useDictsApi = (apiPath: string = "/api/dictionary"): DictsApi => {
    const [dicts, setDictionaries] = useState<Dictionaries>({});
    const [loading, setLoading] = useState(false);
    const [dict, setCurrentDict] = useState<Dictionary | null>(null);

    const { enqueueSnackbar } = useSnackbar();
    const { formatMessage } = useIntl();

    const [accumulatedChanges, setAccumulatedChanges] = useState<Record<string, Partial<DictionaryRecord>>>({});

    const accumulateChange = (dictCode: string, recordCode: string, lang: string | undefined, changes: Partial<DictionaryRecord>) => {
        if(changes?.sortorder === null) {
            delete changes.sortorder;
        }
        if(Object.keys(changes).length === 0) {
            return;
        }

        const k = lang ? `${dictCode}::${recordCode}::${lang}` : `${dictCode}::${recordCode}`;
        setAccumulatedChanges(c => ({ ...c, [k]: { ...c[k], ...changes }}));
    }

    const save = () => {
        setLoading(true);
        return Promise.all(Object.entries(accumulatedChanges).map(([k, c]) => {
            const [dictCode, recordCode, lang] = k.split("::");
            const langUrlBit = lang ? `lang=${lang}` : '';
            return apiFetch<DictionaryRecord>(`${apiPath}/${dictCode}/${recordCode}?${langUrlBit}`, FetchTypes.PUT, c);
        })).then(() => {
            setAccumulatedChanges({});
            load();
        });
    }

    const setDicts = (ds: Dictionaries) => {
        Object.values(ds).forEach(d => {
            d.records.forEach(r => {
                Object.keys(r.label_translations || {}).forEach(l => { (r as { [_: string]: any })[`label_${l}`] = (r.label_translations || {})[l] });
                Object.keys(r.extra || {}).forEach(f => { (r as { [_: string]: any })[`${ExtraFieldPrefix}${f}`] = (r.extra || {})[f] });
            })
        });

        setDictionaries(ds);
    }

    useEffect(() => {
        load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const load = async () => {
        setLoading(true);
        const data = await apiFetch<Dictionaries>(`${apiPath}/all-with-records`);

        setDicts(data);
        setLoading(false);
    }

    const newRecord = useEditItem2<Pick<DictionaryRecord, "code"| "label">>({
      getApiPath: () => "",
      save: (newRecord) => {
        if (!dict || loading || !newRecord.code.trim()) {
          return Promise.resolve(newRecord);
        }
        setLoading(true);

        const lastSortorder = dict.records.length > 0 ? dict.records[dict.records.length - 1].sortorder : 0;

        const record: DictionaryRecord = { ...newRecord, comment: '', sortorder: lastSortorder + 1, disabled: false };

        return apiFetch<DictionaryRecord[]>(`${apiPath}/${dict.id}`, FetchTypes.POST, record)
          .then(records => {
            dict.records = records;
            setLoading(false);
            return newRecord;
          })
          .catch(e => {
            setLoading(false);
            throw e;
          });
      }
    });

    const currentDictId = dict?.id;

    const onUpdate = useCallback((record: DictionaryRecord, changes: any) => {
        if(!currentDictId) return;

        const resRecord = {...record, ...changes};

        const translationUpdated = Object.keys(changes).find(k => k.startsWith("label_"));
        const extraUpdated = Object.keys(changes).find(k => k.startsWith(ExtraFieldPrefix));
        if(translationUpdated) {
            const lang = translationUpdated.substr(6);
            resRecord.label_translations = { ...resRecord.label_translations, [lang]: changes[translationUpdated] };
            accumulateChange(currentDictId, record.code, lang, { label: changes[translationUpdated]});
        } else if(extraUpdated) {
            const changesCopy = { ...changes };
            changesCopy.extra = record.extra || {};
            Object.entries(changes)
              .filter(([f]) => f.startsWith(ExtraFieldPrefix))
              .forEach(([f,v]) => {
                delete changesCopy[f];
                changesCopy.extra[f.substring(ExtraFieldPrefix.length)] = v;
              });
            accumulateChange(currentDictId, record.code, undefined, changesCopy);
        }
        else {
            accumulateChange(currentDictId, record.code, undefined, changes);
        }

        setCurrentDict(d => d
            ? ({ ...d, records: d.records.map(r => r.code === resRecord.code ? resRecord : r) })
            : d);
    }, [currentDictId]);

    const setDictByKey = (key: string) => {
        setCurrentDict(dicts[key])
    }

    const [isAsyncDictReselectRequested, setIsAsyncDictReselectRequested] = useState<boolean>(false);
    useEffect(() => {
      if(isAsyncDictReselectRequested) {
        setIsAsyncDictReselectRequested(false);
        if(dict?.id) {
          setCurrentDict(dicts[dict?.id])
        }
      }
    }, [isAsyncDictReselectRequested, dict, dicts]);


    const remove = useItemActionWithConfirmation<DictionaryRecord, {}>(
      item => apiFetch<DictionaryRecord>(`${apiPath}/${dict?.id}/${item.code}`, "delete")
        .then(x => {
          setAccumulatedChanges({});
          return load().then(() => {
            setIsAsyncDictReselectRequested(true);
            return x;
          });
      }),
      {
        canRun: !!dict,
        title: formatMessage({ id: "dictionaries.remove_title" }),
        confirmationHint: formatMessage({ id: "dictionaries.remove_hint" }),
      }
    );


    const [isImporting, setIsImporting] = useState<boolean>(false);

    const performImport = () => {
      const currentDictId = dict?.id;
      if(!currentDictId) {
        return;
      }

      openFileUploader(f => {
          setIsImporting(true);
          return new Promise<string>(resolve => {
              let reader = new FileReader();

              reader.onload = (e) => {
                  resolve(e.target?.result?.toString() || "");
              }
              reader.readAsText(f);
          })
          .then(jsonString => JSON.parse(jsonString))
          .then(data => apiFetch(`${apiPath}/${currentDictId}/import`, "post", data))
          .then(result => {
              enqueueSnackbar(formatMessage({ id: "dictionaries.import_success"}), { variant: "success", autoHideDuration: 5000 });
              return load()
                .then(() => {
                  setIsAsyncDictReselectRequested(true);
                  setIsImporting(false);
                });
          })
          .catch(e => {
              setIsImporting(false);
              throw e;
          });
      })
    }

    const performExport = () => {
      return new Promise<void>(resolve => {
        if(!dict || dict.records.length === 0) {
          resolve();
          return;
        }

        const anchor = document.createElement("a");
        const data = new Blob([JSON.stringify({ records: dict.records })], { type: "text/plain;charset=utf-8" });
    
        const url = URL.createObjectURL(data);
        anchor.href = url;
        anchor.download = "export.json";
        document.body.appendChild(anchor);
        anchor.click();
        
        setTimeout(() => {
            document.body.removeChild(anchor);
            window.URL.revokeObjectURL(url);
            }, 0);
        resolve();
      })
  }

  const exportAction = useAction(performExport, !!dict?.id && dict.records.length > 0);

    return {
        loading, 
        dicts, 
        dict,
        onUpdate,
        setDictByKey,

        remove,
        save,
        hasChanges: Object.keys(accumulatedChanges).length > 0,

        newRecord: {
          ...newRecord,
          update: changes => {
            if(!/^[a-zA-Z0-9\-_]*$/.test(changes.code || "")) {
              delete changes.code;
            }
            return newRecord.update(changes);
          },
          save: () => newRecord.save().then(x => { newRecord.cancel(); return x; }),
        },

        exportAction,
        importAction: {
            run: () => { performImport(); return Promise.resolve(); },
            isRunning: isImporting,
            canRun: !isImporting,
        }
    }
}
