import React from 'react';
import { ViewColumn } from "@material-ui/icons";
import { CustomElement } from "../../../../../slate";
import { EditorPlugin } from "../../slate/PowerEditorConfig";
import { createWithVoidInjector } from "../common";
import { ColumnsBlockElement, ColumnsBlockElementType } from "./ColumnsBlockElement";

const withColumns = createWithVoidInjector(ColumnsBlockElementType);

export const ColumnsPlugin: EditorPlugin = {
    key: "columns-block",
    inject: withColumns,
    commands: [{
        name: "insert-columns-block",
        menu: { section: "insert-item", label: "Columns", label_id: "powerdoc.plugins.columns.title", icon: <ViewColumn /> },
        invoke: editor => {
            editor.insertNode({
                type: ColumnsBlockElementType,
                columns: 2,
                columns_content: [{}, {}],
                children: [{ text: "", }],
            } as CustomElement);
        }
    }],
    customBlocks: { [ColumnsBlockElementType]: ColumnsBlockElement },
};
