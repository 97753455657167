import { Editor } from "slate";
import { CustomElement } from "../../../../slate";

export const generateCode = (range: number = 1e8) => Math.floor(range+Math.random()*range).toString(32);

export const createWithVoidInjector = (elementType: string) => (editor: Editor) => {
    const { isVoid } = editor;
    editor.isVoid = (element: CustomElement) => {
        return element.type === elementType ? true : isVoid(element);
    }
    
    return editor;
}