import React from 'react';
import { useParams } from 'react-router-dom';
import { LoadingIndicator } from '../primitives/LoadingIndicator';
import { MarkdownDisplay } from '../schemed/Markdown';
import { ConferenceLink, DocumentLinks } from './PageComponents';
import { Tabs } from './PageComponents/Tabs';
import { PageData, usePage } from './usePage';
import { Component } from './usePages';

export type ComponentRenderers = Record<string, (c: Component) => JSX.Element>;

interface Props {
    data: PageData;
    customComponents?: ComponentRenderers;
    suppressLoadingIndicator?: boolean;
    suppressStyles?: boolean;
    wrapper?: React.ComponentType<any>;
    componentWrapper?: React.ComponentType<any>;
}

export const DefaultComponents: ComponentRenderers = {
    markdown: (c: Component) => <MarkdownDisplay key={c._id} text={c.content_text} />,
    documents: DocumentLinks,
    conference: ConferenceLink,
    tabs: Tabs,
}

/**
 * Page display component that supports pluggable page components.
 * Component types available by default: `markdown` (also used for components with empty type), `documents`
 * @param props 
 * @returns 
 */
export const PageDisplay = (props: Props) => {
    const {
        data,
        customComponents,
        suppressLoadingIndicator,
        suppressStyles,
        wrapper,
        componentWrapper,
    } = props;
    const { page, isLoading } = data;
    const Wrapper = wrapper;
    const ComponentWrapper = componentWrapper;

    const content = (
        <>
            {isLoading && !suppressLoadingIndicator && <LoadingIndicator />}
            {!suppressStyles && page.styles && <style>{page.styles}</style>}
            {page.components.map(c => {
                const t = (!c.subtype_text || c.subtype_text === "") ?   "markdown" : c.subtype_text;
                const renderer = (customComponents || {})[t] || DefaultComponents[t] || (() => null);
                const content = renderer(c);
                return content && ComponentWrapper ? <ComponentWrapper key={c._id}>{content}</ComponentWrapper> : content;
            })}
        </>
    );

    return Wrapper ? <Wrapper>{content}</Wrapper> : content;
}

interface PageContainerProps extends Omit<Props, "data"> {
    apiPath: string;
    slug?: string;
    slugUrlParam?: string;
}

/**
 * Fetches and displays a page with pluggable components (using `PageDisplay`).
 * Component types available by default: `markdown` (also used for components with empty type), `documents`
 * @param props 
 * @returns 
 */
export const PageContainer = (props: PageContainerProps) => {
    const { apiPath, slug, slugUrlParam } = props;
    const params = useParams() as Record<string, string>;
    const sl = slug || params[slugUrlParam || "slug"];
    const data = usePage(apiPath, sl);

    return <PageDisplay data={data} {...props} />
}
