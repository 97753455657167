import React from 'react';
import { FormControl } from './FormControl';
import { FieldSchema } from '../../hooks/useSchema';
import { OnFieldChange } from './Common';

interface Props {
    row: any;
    field: string;
    schema: FieldSchema;
    onChange: OnFieldChange;
}

export const TableEditor = (props: Props) => <FormControl {...props} extraProps={{ label: " "}} />;
