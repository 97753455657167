import React from 'react';
import { LayersOutlined } from '@material-ui/icons';
import { ReactEditor } from 'slate-react';
import { EditorPlugin } from "../../PowerDoc";
import { createWithVoidInjector } from "../../PowerDoc/plugins/common"
import { LinkedRecordsBlockElement } from './LinkedRecordsBlockElement';
import { LinkedRecordsBlockElementType, StartSelectingLinkedRecords, UpdateLinkedRecordsProps } from "./types"
import { Transforms } from 'slate';
import { useLinkedRecordsSelectionData } from './useLinkedRecordsSelectionData';
import { useCMSConfiguration } from '../Configuration/CMSConfigurationContext';
import { LinkedRecordsSelectionDialog } from './LinkedRecordsSelectionDialog';

const withLinkedRecords = createWithVoidInjector(LinkedRecordsBlockElementType);

const insertLinkedRecordsBlock = (editor: ReactEditor) => {
    editor.insertNode({
        type: LinkedRecordsBlockElementType,
        record_type: "",
        records_ids: [],
        children: [{ text: "", }],
    } as any);
}

const updateLinkedRecordsProps = (editor: ReactEditor, element: any, params?: any) => {
    const path = ReactEditor.findPath(editor, element);
    if(params && element && path) {
        Transforms.setNodes(
            editor,
            params,
            { at: path },
        );
    }
}

export const useLinkedRecordsEditorPlugin = (): EditorPlugin => {
    const { apiPath } = useCMSConfiguration();
    const recordsSelectionData = useLinkedRecordsSelectionData(
        apiPath,
        (e,el,records_ids) => updateLinkedRecordsProps(e, el, { records_ids }));
    
    return {
        key: "cms.insert-linked-records",
        commands: [{
            name: "cms.insert-linked-records",
            invoke: editor => insertLinkedRecordsBlock(editor),
            menu: {
                section: "insert-item",
                icon: <LayersOutlined />,
                label: "Linked records",
                label_id: "cms.editor.insert_linked_records",
            },
        }, {
            invoke: updateLinkedRecordsProps,
            name: UpdateLinkedRecordsProps,
            isInternal: true,
        }, {
            invoke: (e,element,params) => recordsSelectionData.open({
                record_type: params?.record_type || "",
                records_ids: params?.records_ids || [],
                editor: e,
                element: element,
            }),
            name: StartSelectingLinkedRecords,
            isInternal: true,
        }],
        inject: withLinkedRecords,
        customBlocks: { [LinkedRecordsBlockElementType]: LinkedRecordsBlockElement },
        dialogs: <LinkedRecordsSelectionDialog data={recordsSelectionData} />,
    };
}
