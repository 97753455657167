import { EditorCommand } from "../PowerEditorConfig";
import { StockCommands } from "../StockCommands";

export const Divider = "-";
export const Other = "...";

export interface MenuSection {
    section: string;
    label: string;
    label_id?: string;
    commands: string[];
}

export const FormattingSection: MenuSection = {
    section: "formatting",
    label: "Formatting",
    label_id: "powerdoc.menu.formatting",
    commands: [
        "bold",
        "italic",
        "underline",
        "strikethrough",
        "highlight",
        "insert-link",
        "code",
        Divider,
        "align-left",
        "align-center",
        "align-right",
        "align-justify",
        Divider,
        "block-settings",
        Divider,
        Other,
    ],
};

export const ElementsSection: MenuSection = {
    section: "elements",
    label: "Elements",
    label_id: "powerdoc.menu.elements",
    commands: [
        "ol",
        "ul",
        Divider,
        ...[1,2,3,4,5,6].map(hl => `heading${hl}`),
        Divider,
        Other,
    ]
};

export const InsertItemSection: MenuSection = {
    section: "insert-item",
    label: "Insert",
    label_id: "powerdoc.menu.insert_item",
    commands: [
        Other,
    ]
}

export const DefaultMenuConfig: MenuSection[] = [
    FormattingSection,
    ElementsSection,
    InsertItemSection,
];




interface MenuSectionInt extends Omit<MenuSection, "commands"> {
    commands: (EditorCommand | typeof Divider)[];
}

export const assembleMenuSectionsConfig = (config: MenuSection[], commands: EditorCommand[]): MenuSectionInt[] => {
    const commandsWithoutInternal = (commands || []).filter(c => !c.isInternal);

    const commandsBySection = config.reduce((result,section) => {
        const sectionCommands = section.commands.reduce((sc, command) => {
            if(command === Divider) {
                sc.push(Divider);
            } else if(command === Other) {
                const present = sc.map(c => (c as any).name).filter(x => !!x);
                const allCommandsForSection = [
                    ...StockCommands.filter(c => c.menu?.section === section.section),
                    ...commandsWithoutInternal.filter(c => c.menu?.section === section.section),
                ];
                allCommandsForSection.forEach(c => {
                    if(!present.includes(c.name)) {
                        sc.push(c);
                    }
                })
            } else {
                const commandReal = commandsWithoutInternal.find(c => c.name === command) || StockCommands.find(c => c.name === command);
                if(commandReal) {
                    sc.push(commandReal);
                }
            }
            return sc;
        }, [] as (EditorCommand | typeof Divider)[]);
        
        if(sectionCommands.length && sectionCommands[sectionCommands.length - 1] === Divider) {
            sectionCommands.pop();
        }
        if(sectionCommands.length && sectionCommands[0] === Divider) {
            sectionCommands.shift();
        }

        result[section.section] = sectionCommands;
        return result;
    }, {} as Record<string, (EditorCommand | typeof Divider)[]>)

    return config.map(s => ({
        ...s,
        commands: commandsBySection[s.section] || [],
    }))
    .filter(s => s.commands.length > 0);
}


type MenuAlteration = (m: MenuSection[]) => MenuSection[];

export const alterMenu = (menu: MenuSection[], ...alterations: MenuAlteration[]) => {
    return (alterations || []).reduce((result, alteration) => alteration(result), menu);
}

export const sectionTransformer = (section: string, ...alterations: ((section: MenuSection) => MenuSection)[]): MenuAlteration => {
    return menu => menu.map(s => s.section === section
        ? (alterations || []).reduce((result, alteration) => alteration(result), s)
        : s);
}

export const commandsTransformer = (section: string, ...alterations: ((commands: string[]) => string[])[]) => {
    return sectionTransformer(
        section,
        s => ({
            ...s,
            commands: (alterations || []).reduce((result, alteration) => alteration(result), s.commands),
        }))
}
