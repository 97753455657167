import { Dialog, DialogProps } from '@material-ui/core';
import React, { useState } from 'react'

type DialogButtonProps = Omit<DialogProps, 'open'> & {
    button: JSX.Element;
    children: (close: (callback?: () => void) => () => void) => JSX.Element;
}

export const DialogButton = ({button, children, ...props}: DialogButtonProps) => {
    const [open, setOpen] = useState(false);

    const close = (callback?: () => void) => () => {
        callback && callback();
        setOpen(false);
    }

    return <>
        <div style={{ margin: 0, display: "inline-flex" }} onClick={() => setOpen(true)}>{button}</div>
        <Dialog {...props} open={open} onClose={() => setOpen(false)}>
            {children(close)}
        </Dialog>
    </>
}