import {
    Editor,
    Element as SlateElement,
    Node,
    Transforms,
} from 'slate';
import { ParagraphElementType } from '../../slate/BlockElements';


export const TableElementType = "table";
export const TableRowElementType = "table-row";
export const TableCellElementType = "table-cell";

export const createCell = () => ({
    type: TableCellElementType,
    children: [{ text: '' }],
});

export const DefaultTableElement = {
    type: TableElementType,
    children: [
        {
            type: TableRowElementType,
            children: [
                createCell(),
                createCell(),
                createCell(),
            ],
        },
        {
            type: TableRowElementType,
            children: [
                createCell(),
                createCell(),
                createCell(),
            ],
        },
    ],
};

export const isInTable = (editor: Editor) => {
    const [currentTable] = Editor.nodes(editor, {
        match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === TableElementType,
    });
    return !!currentTable;
}

export const insertRow = (editor: Editor, position?: "before" | "after") => {
    const [currentRow] = Editor.nodes(editor, {
        match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && (n.type === TableRowElementType || n.type === "tr"),
    });
    if(currentRow) {
        const [row, path] = currentRow;
        const newPath = [...path];
        if(newPath.length && position !== "before") {
            newPath[newPath.length - 1] += 1;
        }
        Transforms.insertNodes(
            editor,
            {
                type: TableRowElementType,
                children: ((row as any).children as any[] || []).map(() => createCell()),
            } as unknown as Node,
            { at: newPath },
        );
    }
}

export const removeRow = (editor: Editor) => {
    const [currentTable] = Editor.nodes(editor, {
        match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === TableElementType,
    });

    if(currentTable) {
        const [table] = currentTable;
        const rows = ((table as any).children as any[] || []);
        if(rows.length === 1) {
            Transforms.removeNodes(editor, {
                match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === TableElementType,
            });
        } else {
            Transforms.removeNodes(editor, {
                match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && (n.type === TableRowElementType || n.type === "tr"),
            });
        }
    }
}

export const insertColumn = (editor: Editor, position?: "before" | "after") => {
    const [currentCell] = Editor.nodes(editor, {
        match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && (n.type === TableCellElementType || n.type === "td"),
    });
    const [currentTable] = Editor.nodes(editor, {
        match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === TableElementType,
    });
    if(currentCell) {
        const [, path] = currentCell;
        const cellPosition = path[path.length - 1];
        const insertPosition = position === "before" ? cellPosition : cellPosition+1;
        const [table,tablePath] = currentTable;

        ((table as any).children as any[] || []).forEach((_,idx) => {
            Transforms.insertNodes(
                editor,
                createCell() as unknown as Node,
                { at: [...tablePath, idx, insertPosition] },
            );
        });
    }
}

export const removeColumn = (editor: Editor) => {
    const [currentTable] = Editor.nodes(editor, {
        match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === TableElementType,
    });
    const [currentCell] = Editor.nodes(editor, {
        match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && (n.type === TableCellElementType || n.type === "td"),
    });
    if(currentCell) {
        const [, path] = currentCell;
        const cellPosition = path[path.length - 1];
        const [table,tablePath] = currentTable;
        const rows = ((table as any).children as any[] || []);
        const firstRowCells = rows.length ? ((rows[0] as any).children as any[] || []) : [];

        if(firstRowCells.length <= 1) {
            Transforms.removeNodes(editor, {
                match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === TableElementType,
            });
        } else {
            rows.forEach((_,idx) => {
                Transforms.removeNodes(
                    editor,
                    { at: [...tablePath, idx, cellPosition] },
                );
            });
        }

    }
}

const collectPath = (node: Node, branch: "first" | "last"): number[] => {
    const children = ((node as any).children as Node[] || []);
    if(children.length) {
        const takeIdx = branch === "first" ? 0 : children.length - 1;
        return [takeIdx, ...collectPath(children[takeIdx], branch)];
    } else {
        return [];
    }
}

export const moveRowCursor = (editor: Editor, direction: "up" | "down") => {
    const [currentCell] = Editor.nodes(editor, {
        match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && (n.type === TableCellElementType || n.type === "td"),
    });
    const [currentTable] = Editor.nodes(editor, {
        match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === TableElementType,
    });

    if(currentCell && currentTable) {
        const [table,tablePath] = currentTable;
        const totalRows = ((table as any).children as any[] || []).length;
        const [,path] = currentCell;

        const rowPathIdx = path.length - 2;

        const movingOut = path.length < 3 || (direction === "up" && path[rowPathIdx] === 0) || (direction === "down" && path[rowPathIdx] === totalRows-1);
        
        if(movingOut) {
            const tableSiblingPath = [...tablePath];
            tableSiblingPath[tableSiblingPath.length - 1] += direction === "up" ? -1 : 1;

            try {
                const [sibling] = Editor.nodes(editor, {
                    match: n => !Editor.isEditor(n) && SlateElement.isElement(n),
                    at: tableSiblingPath,
                });
                
                if(sibling) {
                    const [siblingNode, siblingPath] = sibling;
                    const siblingLastChildRelativePath = collectPath(siblingNode, direction === "up" ? "last" : "first");
                    Transforms.select(editor, {
                        path: [...siblingPath, ...siblingLastChildRelativePath],
                        offset: 0,
                    });
                }
            } catch(e) {
                const siblingInjectionPath = direction === "up" ? tablePath : tableSiblingPath;
                Transforms.insertNodes(
                    editor,
                    { type: ParagraphElementType, children: [{ text: "" }]},
                    { at: siblingInjectionPath });
                Transforms.select(editor, {
                    path: [...siblingInjectionPath, 0],
                    offset: 0,
                });
            }
            return;
        } else {
            const newPath = [...path];
            newPath[rowPathIdx] += (direction === "up" ? -1 : 1);
            Transforms.select(editor, {
                path: [...newPath, 0],
                offset: 0,
            });
        }
        
    }
}
