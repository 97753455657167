import React from 'react';
import { RenderElementProps } from "slate-react";
import { createWithVoidInjector } from '../common';
import { ScriptElementType } from './ScriptPlugin';
import { EditorPlugin } from '../../slate/PowerEditorConfig';

const ScriptInjectElement = (props: RenderElementProps) => {
  const { attributes, element, children } = props;

  return (
      <div {...attributes}>
          {children}
          <div contentEditable={false} dangerouslySetInnerHTML={{ __html: element.value || "" }} />
      </div>
  )
}

const withScript = createWithVoidInjector(ScriptElementType);


export const ScriptInjectPlugin: EditorPlugin = {
  key: "embed",
  customBlocks: { [ScriptElementType]: ScriptInjectElement },
  inject: withScript,
}
