import React from 'react';
import styled from '@emotion/styled';
import { RenderElementProps, useSelected, useSlateStatic } from 'slate-react';
import { withTheme } from '@material-ui/core';
import { CustomElement, InlineMarks } from '../../../../../slate';
import { Editor } from 'slate';


const PlaceholderWrapper = withTheme(styled.span<{ isSelected?: boolean, formats?: InlineMarks }>`
    background: ${props => props.isSelected ? "#ffbb00" : "#ffdd88"};
    padding: 0.2em 0.15em;
    border-radius: 4px;
    font-family: monospace;
    font-weight: ${props => props.formats?.bold ? 600 : "normal"};
    font-style: ${props => props.formats?.italic ? "italic" : "normal"};
    text-decoration: ${props => props.formats?.underline ? "underline" : props.formats?.strikethrough ? "line-through"  : "normal"};

`);

interface Props extends RenderElementProps {
    openEdit?: (editor: Editor, element: CustomElement) => void;
}

export const PlaceholderElement = (props: Props) => {
    const isSelected = useSelected();
    const editor = useSlateStatic();

    return (
        <PlaceholderWrapper
            {...props.attributes}
            isSelected={isSelected}
            formats={(props.element as any).marks as InlineMarks}
            onDoubleClick={() => {
                if(props.openEdit) {
                    props.openEdit(editor, props.element);
                }
            }}
            >
            {props.children}{"{{"}{props.element.expression}{"}}"}
        </PlaceholderWrapper>
      );
}
