import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import { Button } from '@material-ui/core';
import {
    Code,
    FormatAlignCenter,
    FormatAlignJustify,
    FormatAlignLeft,
    FormatAlignRight,
    FormatBold,
    FormatItalic,
    FormatListBulleted,
    FormatListNumbered,
    FormatQuote,
    FormatUnderlined,
} from '@material-ui/icons';
import { useSlate } from 'slate-react';
import { TextFormat, isBlockActive, isTextAlignType, toggleBlock, isMarkActive, toggleMark } from './EditorCommands';

interface ButtonProps {
    children: ReactNode;
    isActive?: boolean;
    onClick?: () => void;
}


const ToolbarButton = ({ children, isActive, onClick }: ButtonProps) => {
    return (
        <Button
            size="small"
            color={isActive ? "primary" : undefined}
            onClick={e => {
                if(onClick) {
                    e.preventDefault();
                    onClick();
                }
            }}
        >
            {children}
        </Button>
    )
}

interface BlockButtonProps extends Omit<ButtonProps, "onClick" | "isActive"> {
    format: string;
}

const BlockButton = ({ format, children }: BlockButtonProps) => {
    const editor = useSlate();
    const isActive = isBlockActive(
        editor,
        format as TextFormat,
        isTextAlignType(format) ? 'align' : 'type'
    );

    return (
        <ToolbarButton
            onClick={() => toggleBlock(editor, format as TextFormat)}
            isActive={isActive}
            >
                {children}
        </ToolbarButton>
    )
}

const MarkButton = ({ format, children }: BlockButtonProps) => {
    const editor = useSlate();
    const isActive = isMarkActive(
        editor,
        format as TextFormat,
    );

    return (
        <ToolbarButton
            onClick={() => toggleMark(editor, format as TextFormat)}
            isActive={isActive}
            >
                {children}
        </ToolbarButton>
    )
}

const ToolbarWrapper = styled.div`
    display: flex;
    flex-flow: row wrap;
`;

interface Props {
}

export const EditorToolbar = (props: Props) => {
    return (
        <ToolbarWrapper>
            <MarkButton format="bold"><FormatBold /></MarkButton>
            <MarkButton format="italic"><FormatItalic /></MarkButton>
            <MarkButton format="underline"><FormatUnderlined /></MarkButton>
            <MarkButton format="code"><Code /></MarkButton>

            {["h1","h2","h3","h4","h5","h6"].map(f => (
                <BlockButton key={f} format={f}>{f}</BlockButton>
            ))}
            <BlockButton format="blockquote"><FormatQuote /></BlockButton>
            <BlockButton format="ol"><FormatListNumbered /></BlockButton>
            <BlockButton format="ul"><FormatListBulleted /></BlockButton>
            <BlockButton format="left"><FormatAlignLeft /></BlockButton>
            <BlockButton format="center"><FormatAlignCenter /></BlockButton>
            <BlockButton format="right"><FormatAlignRight /></BlockButton>
            <BlockButton format="justify"><FormatAlignJustify /></BlockButton>

        </ToolbarWrapper>
    );
}
