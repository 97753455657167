import { Editor, Element, Transforms } from 'slate'
import { CustomElement, CustomText } from '../../../../slate';
import { BlockTypes, ParagraphElementType } from './BlockElements';
import { PlaceholderElementType } from '../plugins/Placeholders/usePlaceholders';

export type TextFormat = keyof Omit<CustomText, "text"> | keyof CustomElement | "ol" | "ul" | "left" | "center" | "right" | "justify";

const LIST_TYPES = ['ol', 'ul']
const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify']

export const isTextAlignType = (format: string) => TEXT_ALIGN_TYPES.includes(format);

export const toggleBlock = (editor: Editor, format: TextFormat) => {
    const isActive = isBlockActive(
        editor,
        format,
        TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
    )
    const isList = LIST_TYPES.includes(format)
    
    Transforms.unwrapNodes(editor, {
        match: n =>
        !Editor.isEditor(n) &&
        Element.isElement(n) &&
        LIST_TYPES.includes(n.type) &&
        !TEXT_ALIGN_TYPES.includes(format),
        split: true,
    })
    let newProperties: Partial<Element>
    if (TEXT_ALIGN_TYPES.includes(format)) {
        newProperties = {
        align: isActive ? undefined : format as CustomElement["align"],
        }
    } else {
        newProperties = {
            type: isActive ? ParagraphElementType : isList ? BlockTypes.li : format,
        }
    }
    Transforms.setNodes<Element>(editor, newProperties)
    
    if (!isActive && isList) {
        const block = { type: format, children: [] }
        Transforms.wrapNodes(editor, block)
    }
}
    
export const toggleMark = (editor: Editor, format: TextFormat) => {
    const isActive = isMarkActive(editor, format)
    
    const [placeholdersInSelection] = Editor.nodes(editor, { match: n => (n as any).type === PlaceholderElementType});
    if(placeholdersInSelection) {
        Transforms.setNodes(
            editor,
            { marks: { [format]: !isActive } } as any,
            {
                match: n => (n as any).type === PlaceholderElementType,
                voids: true,
                merge: (old,changes) => ({ ...(old || {}), ...(changes || {}) }),
            }
        );
    }
    
    if (isActive) {
        Editor.removeMark(editor, format)
    } else {
        Editor.addMark(editor, format, true)
    }
}
    
export const isBlockActive = (editor: Editor, format: TextFormat, blockType = 'type') => {
    const { selection } = editor
    if (!selection) return false
    
    const [match] = Array.from(
        Editor.nodes(editor, {
        at: Editor.unhangRange(editor, selection),
        match: n => !Editor.isEditor(n) && Element.isElement(n) && n[blockType as keyof CustomElement] === format,
        })
    )
    
    return !!match
}
    
export const isMarkActive = (editor: Editor, format: TextFormat) => {
    const marks = Editor.marks(editor)
    return marks ? (marks as any)[format] === true : false
}
