import {  Fab } from '@material-ui/core';
import styled from '@emotion/styled';

export const Icon = styled(Fab)<{open: boolean}>`
    ${props => props.open? `
        width: 0;
        height: 0;

        opacity: 0;

    ` : ''}
    transition: 150ms;
`;

export const Container = styled.div`
    width: 400px;
`;
Container.defaultProps = {className: 'Container'};

export const Header = styled.div<{subTitle?: boolean}>`
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: ${props => props.subTitle? '0' : '10px'};
    padding-left: ${props => props.subTitle? '0' : '25px'};
    font-size: ${props => props.subTitle? '20px' : '24px'};
`;
Header.defaultProps = {className: 'Header'};

export const Body = styled.div`
    padding: 15px;
    height: 100%;
    overflow-y: auto;
`;
Body.defaultProps = {className: 'Body'};

export const Title = styled.div`
    font-weight: bold;
    font-size: 16px;
`;
Title.defaultProps = {className: 'Title'};

export const Time = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-style: italic;
    color: grey;
    font-size: .8em;
`;
Time.defaultProps = {className: 'Time'};

export const Link = styled.a`
    text-decoration: none;
    color: black;
`;
Link.defaultProps = {className: 'Link'};

export const Content = styled.div`
    width: 100%;
`;
Content.defaultProps = {className: 'Content'};

export const BadgeMark = styled.div`
    margin-right: 10px;
`;
BadgeMark.defaultProps = {className: 'BadgeMark'};
