import { AlignmentType, convertInchesToTwip, INumberingOptions, LevelFormat } from "docx-dotx-hf-fix";


export const NumberingConfig: INumberingOptions["config"][number] = {
    reference: "default-numbering",
    levels: [
        {
            level: 0,
            format: LevelFormat.DECIMAL,
            text: "%1.",
            alignment: AlignmentType.START,
            style: {
                paragraph: {
                    indent: { left: convertInchesToTwip(0.5), hanging: convertInchesToTwip(0.18) },
                },
            },
        },
        {
            level: 1,
            format: LevelFormat.DECIMAL,
            text: "%2.",
            alignment: AlignmentType.START,
            style: {
                paragraph: {
                    indent: { left: convertInchesToTwip(1), hanging: convertInchesToTwip(0.68) },
                },
            },
        },
        {
            level: 2,
            format: LevelFormat.DECIMAL,
            text: "%3.",
            alignment: AlignmentType.START,
            style: {
                paragraph: {
                    indent: { left: convertInchesToTwip(1.5), hanging: convertInchesToTwip(1.18) },
                },
            },
        },
        {
            level: 3,
            format: LevelFormat.DECIMAL,
            text: "%4.",
            alignment: AlignmentType.START,
            style: {
                paragraph: {
                    indent: { left: 2880, hanging: 2420 },
                },
            },
        },
    ],
}
