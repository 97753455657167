import { useCrudItem } from "../../api/useSimpleCrud"
import { PowerDoc, PowerDocPageConfig, PowerDocSchema } from "./types";

export const usePowerDocEdit = (apiPath: string, id: string, cfg: PowerDocPageConfig) => {
    const data = useCrudItem<PowerDoc>(`${apiPath}/${id}`, {
        defaultValue: {} as PowerDoc,
        returnPath: `${cfg.pathPrefix}`,
    });

    return {
        ...data,
        schema: PowerDocSchema,
    };
}
