import React from 'react';
import styled from '@emotion/styled';
import LogoImg from '../../logo.svg';
import LogoImg2 from '../../logo_w.svg';

const LogoTag = styled.img`
    width: 100%;
    box-sizing: border-box;
    padding: 2rem 1.5rem;
`;

export const Logo = (props: {version?: 1 | 2}) => (
    <LogoTag src={props.version === 2 ? LogoImg2 : LogoImg} alt="toOlympus" />
);