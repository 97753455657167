import React from 'react';
import { ConditionalBlockPlugin } from '../plugins/ConditionalBlock';
import { CustomFieldsBlockPlugin } from '../plugins/CustomFieldsBlock';
import { GalleryPlugin } from '../plugins/Gallery';
import { useTemplateBlocksPlugin } from '../plugins/TemplateBlocks';
import { PowerEditorBase } from './PowerEditorBase';
import { PowerEditorProps, PowerEditorStockConfig } from './PowerEditorConfig';


export const PowerEditor = (props: PowerEditorProps & PowerEditorStockConfig) => {
    const blocks = useTemplateBlocksPlugin({ blocksApiPath: props.blocksApiPath });
    return (
        <PowerEditorBase
            plugins={[
                blocks.plugin,
                ConditionalBlockPlugin,
                GalleryPlugin,
                CustomFieldsBlockPlugin,
                ]}
            {...props}
            />
    );
}
