import { IconButton, Typography } from '@material-ui/core';
import { DeleteOutlined } from '@material-ui/icons';
import React, { ReactNode, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { SimpleDialog, SimpleList } from '../primitives';
import { PowerDoc, PowerDocPageConfig } from './types';
import { usePowerDocList } from './usePowerDocList';

interface Props extends PowerDocPageConfig {
    headerActions?: ReactNode;
    title?: ReactNode;
}

export const PowerDocListPage = (props: Props) => {
    const data = usePowerDocList(props.apiPath, props);
    const [documentToDelete, setDocumentToDelete] = useState<PowerDoc | null>(null);

    return (<>
        <SimpleList
            data={data}
            fields={[
                ["title"],
                ["created_datetime", { utcToLocal: true }],
            ]}
            linkFields={["title"]}
            getLinkValue={d => `${props.pathPrefix}/${d._id}`}
            title={props.title || <FormattedMessage id="powerdoc.pages.title" />}
            schema={data.schema}
            headerActions={props.headerActions}
            rowButtons={
                row => <IconButton onClick={() => setDocumentToDelete(row)} size="small"><DeleteOutlined /></IconButton>
            }
            searchAutoFocus
            />
        
        <SimpleDialog
            isOpen={!!documentToDelete}
            close={() => setDocumentToDelete(null)}
            save={() => {
                if(documentToDelete) {
                    data.removeDocument(documentToDelete._id);
                }
                setDocumentToDelete(null);
            }}
            dialogTitle={<FormattedMessage id="powerdoc.pages.remove_document" />}
            saveLabel={<FormattedMessage id="common.delete" />}
            noFullscreen
            >
            {documentToDelete && <Typography><FormattedMessage id="powerdoc.pages.remove_document" /> {documentToDelete.title}?</Typography>}
        </SimpleDialog>
    </>);
}
