import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Theme, withTheme } from '@material-ui/core';

export const BlockSelectionCss = (props: { theme: Theme, isSelected?: boolean }) => css`
    margin-top: 5px;
    box-shadow: 0 0 5px -2px ${props.isSelected ? props.theme.palette.primary.main : "transparent"};
`;

export const BlockWrapper = withTheme(styled.div<{ isSelected?: boolean }>`
    padding: 0.5rem 1rem;
    border-top: 1px solid #eeeeee;
    border-bottom: 1px solid #eeeeee;
    ${props => BlockSelectionCss(props)}
`);



export const Buttons = styled.div`
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    padding-top: 0.5rem;
`;
