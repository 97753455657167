import React from 'react';
import { NotificationTaskList, NotificationTasksListConfig } from './NotificationTaskList';
import { NotificationTasksConfig, useNotificationTasks } from './useNotificationTasks';

export interface NotificationTasksProps extends NotificationTasksConfig, NotificationTasksListConfig {
    apiPath: string;
}

export const NotificationTasksContainer = (props: NotificationTasksProps) => {
    const data = useNotificationTasks(props.apiPath, props);
    return <NotificationTaskList data={data} {...props} />;
}
