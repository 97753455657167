import { useState } from "react"
import { useValidationErrors, ValidationErrors } from "../components/schemed";

export interface OpenableEdit<T> {
    data: T | null;
    update: (changes: Partial<T>) => void;
    hasChanges: boolean;
    canComplete: boolean;

    isActive: boolean;
    startEdit: (data: T) => void;
    cancelEdit: () => void;
    completeEdit: () => void;
    isLoading: boolean;
    errors: ValidationErrors;
}

interface Config<T> {
    keepOpenOnComplete?: boolean;
    canComplete?: (data: T) => boolean;
}

export const useOpenableEdit = <T>(onComplete: (data: T, changes: Partial<T>) => Promise<any>, config?: Config<T>): OpenableEdit<T> => {
    const [data, setData] = useState<T | null>(null);
    const [changes, setChanges] = useState<Partial<T>>({});
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const errors = useValidationErrors();
    
    const startEdit = (data: T) => {
        setData(data);
        setChanges({});
    }

    const update = (changes: Partial<T>) => {
        if(data) {
            setData({ ...data, ...changes });
            setChanges({ ...changes });
        }
    }
    
    const cancelEdit = () => {
        setData(null);
        setChanges({});
    }

    const canComplete = !!data && (config?.canComplete ? config.canComplete(data) : true);

    const completeEdit = () => {
        if(!!data && canComplete) {
            errors.clearErrors();
            setIsLoading(true);
            onComplete(data as T, changes)
                .then(() => {
                    setIsLoading(false);
                    setChanges({});
                    if(!config?.keepOpenOnComplete) {
                        setData(null);
                    }
                })
                .catch(e => {
                    errors.captureErrors(e);
                    setIsLoading(false);
                    throw e;
                })
        }
    }

    return {
        data,
        update,
        hasChanges: Object.keys(changes).length >= 0,
        canComplete,

        isActive: !!data,
        startEdit,
        cancelEdit,
        completeEdit,
        isLoading,
        errors,
    }
}
