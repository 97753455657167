import React, { ReactNode, useState } from 'react';
import { useHistory } from 'react-router';
import { Button, DialogTitle, DialogContent, DialogActions, IconButton, TextField, IconButtonProps } from '@material-ui/core';
import { DeleteOutlined } from '@material-ui/icons';
import { DialogButton } from './DialogButton';
import { FormattedMessage } from 'react-intl';

type ProperSize = 'small' | 'medium';

interface Props {
    title: ReactNode;
    hint?: ReactNode;
    button?: JSX.Element;
    confirmationText?: string;
    size?: ProperSize | 'tiny';
    color?: IconButtonProps["color"];
    remove: () => Promise<any>;
    preventGoBack?: boolean;
}

export const DeleteButton = (props: Props) => {
    const { title, hint, remove, button, size, preventGoBack, confirmationText } = props;
    const history = useHistory();
    const [textEntered, setTextEntered] = useState("");

    const isTiny = size === 'tiny';

    return (
        <DialogButton
            button={button || <IconButton size={isTiny ? 'small' : size as ProperSize} color={props.color}>
                        <DeleteOutlined style={isTiny ? { fontSize: '16px' } : {}} />
                    </IconButton>}>
                {close => (<>
                    <DialogTitle>{title}</DialogTitle>
                    <DialogContent>
                        {hint}
                        {confirmationText && (
                            <TextField
                                fullWidth
                                value={textEntered}
                                onChange={e => setTextEntered(e.target.value)} />
                        )}
                        <DialogActions>
                            <Button
                                color='secondary'
                                variant='contained'
                                disabled={!!confirmationText && textEntered !== confirmationText}
                                onClick={() => {
                                    close()();
                                    remove().then(() => {
                                        if(!preventGoBack) {
                                            history.goBack()
                                        }
                                    });
                                }}>
                                <FormattedMessage id="common.delete" />
                            </Button>
                            <Button onClick={close()}><FormattedMessage id="common.cancel" /></Button>
                        </DialogActions>
                    </DialogContent>
                </>)}
            </DialogButton>
    );
}
