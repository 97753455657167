import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import isHotkey from 'is-hotkey';
import { IconButton, TextField, Typography } from '@material-ui/core';
import { Add, DeleteOutlined } from '@material-ui/icons';
import { FormattedMessage, useIntl } from 'react-intl';
import { Editor, Transforms } from 'slate';
import { ReactEditor, useSlateStatic } from 'slate-react';
import { CustomElement } from '../../../../../slate';
import { Dialog, FormGrid } from '../../../primitives';
import { Buttons } from '../../elements/Common';
import { usePowerEditorContext } from '../../slate/PowerEditorContext';
import { generateCode } from '../common';
import { usePowerEditorSettings } from '../../PowerEditorSettingsContext';
import { useBlockHints, useSuggestions } from '../../PowerEditorSettingsSuggestions';

interface Props {
    element: CustomElement | null;
    close: () => void;
}

interface FieldInternal {
    id: string;
    label: string;
    value: string;
    markForRemoval?: boolean;
    new?: boolean;
}

const useBlockSettings = (element: CustomElement | null) => {
    const [fieldsIds, setFieldsIds] = useState<string[]>([]);
    const [fields, setFields] = useState<Record<string, FieldInternal>>({});
    const [insertedId, setInsertedId] = useState<string>("");

    useEffect(() => {
        if(element) {
            const fieldsLabels = element.custom_settings || [];
            const fields = fieldsLabels.map(label => ({
                id: generateCode(),
                label: label,
                value: (element as any)[label] || "",
            }));
            

            setFieldsIds(fields.map(f => f.id));
            setFields(fields.reduce<Record<string, FieldInternal>>((r,f) => { r[f.id] = f; return r; }, {}))
        } else {
            setFieldsIds([]);
            setFields({});
        }
    }, [element]);

    const addField = () => {
        const id = generateCode();
        const field = {
            id,
            label: "",
            value: "",
            new: true,
        };
        setFieldsIds(x => [...x, id]);
        setFields(x => ({ ...x, [id]: field }));
        setInsertedId(id);
    }

    const updateField = (id: string, changes: Partial<Pick<FieldInternal, "label" | "value">>) => {
        setFields(x => x[id] ? { ...x, [id]: { ...x[id], ...changes }} : x);
    }

    const removeField = (id: string) => {
        setFields(x => x[id] ? { ...x, [id]: { ...x[id], markForRemoval: true }} : x);
    }

    const saveFieldsToBlock = (editor: Editor) => {
        if(element) {
            const fs = Object.values(fields);
            const updated: Record<string, any> & Pick<CustomElement, "custom_settings"> = {
                custom_settings: fs.filter(f => !f.markForRemoval).map(f => f.label)
            };
            fs.forEach(f => {
                if(f.markForRemoval) {
                    updated[f.label] = null;
                } else {
                    updated[f.label] = f.value;
                }
            });

            const path = ReactEditor.findPath(editor, element);
            Transforms.setNodes(
                editor,
                updated,
                { at: path },
            );
        }
    }

    return {
        addField,
        updateField,
        removeField,
        insertedId,
        fields: fieldsIds.map(id => fields[id]).filter(f => !f.markForRemoval),

        saveFieldsToBlock,
    }
}

const Suggestion = styled(Typography)`
    display: inline-block;
    margin-right: 1rem;
    cursor: pointer;
`;
Suggestion.defaultProps = { variant: "subtitle2", color: "primary", role: "button" };

const useFullSuggestions = () => {
  const { suggestedBlockSettings, suggestedBlockSettingsByType, suggestedBlockSettingValues, settingsSuggestions, blockHints } = usePowerEditorSettings();

  const suggestions = useSuggestions(settingsSuggestions || []);
  const hints = useBlockHints(blockHints || []);

  const suggestedFields = (element: CustomElement | null) => [...((suggestedBlockSettingsByType || {})[element?.type || ""] || []), ...(suggestedBlockSettings || [])];

  const suggestedValues = (element: CustomElement | null, setting: string) => setting === "" || !suggestedBlockSettingValues
    ? []
    : [
        ...(suggestedBlockSettingValues[element?.type || ""] || {})[setting] || [],
        ...(suggestedBlockSettingValues["any"] || {})[setting] || [],
    ];
  
  return {
    settings: (e: CustomElement | null) => Array.from(new Set([...suggestions.settings(e), ...suggestedFields(e)])).sort(),
    values: (e: CustomElement | null, setting: string) => Array.from(new Set([...suggestions.values(e, setting), ...suggestedValues(e, setting)])).sort(),
    hints: hints.hints,
  }
}

export const BlockSettingsDialog = (props: Props) => {
    const { element, close } = props;

    const settings = useBlockSettings(element);
    const { formatMessage } = useIntl();
    const { viewMode } = usePowerEditorContext();
    const editor = useSlateStatic();

    const suggestions = useFullSuggestions();

    const unusedSuggestedFields = suggestions.settings(element)
        .filter(suggested => !settings.fields.find(f => f.label === suggested));

    const suggestedValues = (setting: string) => suggestions.values(element, setting);

    const hints = suggestions.hints(element);

    return (
        <Dialog
            isOpen={!!element}
            close={() => {
                settings.saveFieldsToBlock(editor);
                close();
            }}
            dialogTitle={<FormattedMessage id="powerdoc.plugins.block_settings.dialog_title" values={{ block_type: element?.type }} />}
            noFullscreen>

            {!!hints.length &&
              <div>
                {hints.map(message => <Typography variant="caption" color="textSecondary" component="p">{message}</Typography>)}
              </div>}
            
            <FormGrid
                columns="1fr 1fr max-content"
                forceEvenColumns
                contentEditable={false}
                onKeyDown={e => {
                    if(isHotkey("mod+Enter", e)) {
                        settings.addField();
                        e.preventDefault();
                    }
                }}>
                {settings.fields.map(f => (
                    <React.Fragment key={f.id}>
                        <TextField
                            key="label"
                            value={f.label || ""}
                            onChange={e => settings.updateField(f.id, { label: (e.target.value || "").replace(/\s/, "") })}
                            label=""
                            placeholder={formatMessage({ id: "powerdoc.plugins.custom_fields_block.field.label"})}
                            autoFocus={f.id === settings.insertedId}
                            InputProps={{ readOnly: !f.new || viewMode }}
                            helperText={!f.label && !viewMode && unusedSuggestedFields.length > 0 && <>{
                                unusedSuggestedFields.map(suggestion => (
                                    <Suggestion onClick={() => settings.updateField(f.id, { label: suggestion })}>
                                        {suggestion}
                                    </Suggestion>))
                            }</>}
                            />
                        <TextField
                            key="value"
                            value={f.value || ""}
                            onChange={e => settings.updateField(f.id, { value: e.target.value })}
                            label=""
                            placeholder={formatMessage({ id: "powerdoc.plugins.custom_fields_block.field.value"})}
                            multiline
                            InputProps={{ readOnly: viewMode }}
                            helperText={!!f.label && !viewMode && <>{
                                suggestedValues(f.label).map(suggestion => (
                                    <Suggestion onClick={() => settings.updateField(f.id, { value: suggestion })}>
                                        {suggestion}
                                    </Suggestion>))
                            }</>}
                            />
                        <IconButton size="small" onClick={() => settings.removeField(f.id)}><DeleteOutlined /></IconButton>
                    </React.Fragment>
                ))}
            </FormGrid>
            <Buttons>
                <IconButton size="small" onClick={settings.addField}><Add /></IconButton>
            </Buttons>
        </Dialog>
    );
}
