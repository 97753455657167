import React from 'react';
import { RenderElementProps, useSelected, useSlateStatic } from 'slate-react';
import { usePowerEditorContext } from '../../slate/PowerEditorContext';
import styled from '@emotion/styled';
import { PossiblyProtectedImg, useMediaLibContext } from '../../../medialib';
import { BlockSelectionCss } from '../../elements/Common';
import { withTheme } from '@material-ui/core';
import { EditImageCommand } from './types';

export const ImageElementType = "image";

interface Props extends RenderElementProps {
}

const Wrapper = withTheme(styled.span<{ noSizeLimit?: boolean, isSelected?: boolean }>`
    & img {
        max-width: ${props => props.noSizeLimit ? "initial" : "50px"};
        max-height: ${props => props.noSizeLimit ? "initial" : "50px"};
        ${props => BlockSelectionCss(props)}
    }
`);

export const ImageElement = ({ element, attributes, children }: Props) => {
    const { viewMode, invokeCommand } = usePowerEditorContext();
    const mediaLib = useMediaLibContext();
    const editor = useSlateStatic();
    const isSelected = useSelected();

    const { width, height, _class } = element as any;
    
    return (
        <Wrapper
            noSizeLimit={!!width || !!height}
            isSelected={isSelected}
            {...attributes}
            onClick={viewMode ? undefined : e => invokeCommand(EditImageCommand, editor, element)}>
            {children}
            <PossiblyProtectedImg
                protected={mediaLib.protectedFiles}
                src={element.url}
                className={_class}
                width={width || undefined}
                height={height || undefined}
                alt=""
                />
        </Wrapper>);
}
