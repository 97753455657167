import styled from '@emotion/styled';
import { IconButton, TextField, withTheme } from '@material-ui/core';
import React from 'react';
import { RenderElementProps, useSelected } from 'slate-react';
import { BlockSelectionCss } from '../../elements/Common';
import { usePowerEditorSettings } from '../../PowerEditorSettingsContext';
import { PowerEditorBase } from '../../slate/PowerEditorBase';
import { usePowerEditorContext } from '../../slate/PowerEditorContext';
import { usePanel } from './usePanel';
import { ActionRow, OccupyFreeSpace } from '../../../primitives';
import { ExpandLess, ExpandMore, Title } from '@material-ui/icons';
import { useIntl } from 'react-intl';


export const PanelBlockElementType = "panel-block";

export const PanelWrapper = withTheme(styled.div<{ isSelected?: boolean, viewMode?: boolean }>`
    padding: 0.5rem 1rem;
    border-top: 2px solid ${props => props.viewMode ? "transparent" : "#eeeeee"};
    border-bottom: 2px solid ${props => props.viewMode ? "transparent" : "#eeeeee"};
    ${props => BlockSelectionCss(props)}

    & > div:last-child {
        width: 100%;
    }

    & .panel-block-element, & .tiles-block-element {
      border: 2px solid ${props => props.viewMode ? "transparent" : "#eeeeee"};
    }
`);

export const PanelBlockElement = (props: RenderElementProps) => {
    const { element, attributes, children } = props;

    const isSelected = useSelected();
    const { formatMessage } = useIntl();

    const { viewMode } = usePowerEditorContext();
    
    const settings = usePowerEditorSettings();
    const EditorComponent = settings.EditorComponent || PowerEditorBase;

    const panel = usePanel(element);

    return (
        <PanelWrapper {...attributes} className="panel-block-element" isSelected={isSelected} viewMode={viewMode}>
            {children}
            <div style={{ width: "100%" }} contentEditable={false}>
              <ActionRow firstItemNoMargin itemMarginTop='0' lastItemMarginRight='0'>
                {!viewMode && panel.hasTitle && <TextField
                    value={panel.title || ""}
                    onChange={e => panel.update({ title: e.target.value })}
                    label=""
                    placeholder={formatMessage({ id: "powerdoc.plugins.tiles.tile.title"})}
                    fullWidth
                    />}

                {(viewMode || !panel.hasTitle) && <OccupyFreeSpace />}
                
                {!viewMode && <IconButton size="small" onClick={e => panel.toggleHasTitle()}>
                    <Title />
                </IconButton>}
                {!viewMode && <IconButton size="small" onClick={e => panel.update({ isEditorCollapsed: !panel.isEditorCollapsed})}>
                    {!panel.isEditorCollapsed ? <ExpandLess /> : <ExpandMore />}
                </IconButton>}
              </ActionRow>
              
              {!panel.isEditorCollapsed && <EditorComponent
                  content={panel.content}
                  update={v => panel.updateContent(v)}
                  viewMode={viewMode}
                  />}
            </div>
        </PanelWrapper>
    );
}
