import { IconButton, IconButtonProps, Menu, MenuItem } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import React, { ReactNode, useState } from 'react';

interface Props extends Pick<IconButtonProps, "size"> {
  icon?: ReactNode;
  actions: ([string,ReactNode, () => void] | null)[];
}

export const MoreActionsMenuButton = (props: Props) => {
  const [menuAnchor,setMenuAnchor] = useState<any>(null);

  const { actions, icon, ...otherProps } = props;
  
  return (<>
    <IconButton size="small" {...otherProps} onClick={e => setMenuAnchor(e.target)}>{icon || <MoreVert />}</IconButton>
    <Menu anchorEl={menuAnchor} open={!!menuAnchor} onClose={() => setMenuAnchor(null)} keepMounted>
      {(actions.filter(x => !!x) as [string,ReactNode, () => void][]).map(([key,label,action]) => (
        <MenuItem key={key} onClick={() => action()}>{label}</MenuItem>
      ))}
    </Menu>
  </>)
}
