import React, { useState } from "react"

interface Point {
    x: number;
    y: number;
}

export const useContextMenu = () => {
    const [anchor, setAnchor] = useState<Point | null>(null);
    
    const openAt = ({ x, y }: Point) => {
        setAnchor(anchor === null
            ? { x, y }
            : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
            // Other native context menus might behave different.
            // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
            null,
        );
    }

    const onContextMenu = (e: React.MouseEvent) => {
        if(!e.ctrlKey) {
            e.preventDefault();
            e.stopPropagation();
            openAt({ x: e.clientX + 2, y: e.clientY - 6 });
        }
    }

    const close = () => setAnchor(null);

    return {
        isOpen: !!anchor,
        close,
        anchor,
        onContextMenu,
    }
}

export type ContextMenuState = ReturnType<typeof useContextMenu>;