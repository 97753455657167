export interface RobudConfig {
    apiPath: string;
    pagePath: string;
}

export const FocusTriggerParam = "focus-trigger";

export const getProgramLink = (pagePath: string, params: { program_id: number, trigger_id?: number }) => {
    const programLink = `${pagePath}/program/${params.program_id}`;
    return params.trigger_id 
        ? `${programLink}?tab=triggers&${FocusTriggerParam}=${params.trigger_id}`
        : programLink;
}