import { useCallback, useEffect, useState } from "react"
import { apiFetch } from "../api/core";

interface Message {
    _type: string;
    [key: string]: any;
}

export interface WebSocketHandle {
    send: (data: any) => void;
    socket?: WebSocket;
    open: boolean;
}

type UseWebsocket = (
    getSocket: () => Promise<WebSocket>, 
    onMessage: (message: Message) => void,
    dontConnect?: boolean,
) => WebSocketHandle;

/**
 * Хук который берет на себя базовую работу с сокетами
 * 
 * @param getSocket Функия устанавливающая вебсокет
 * @param onMessage Обработчик сообщений
 * @param onConnect Обработчик при подключении (открытии)
 */
export const useWebSocket: UseWebsocket = (getSocket, onMessage, dontConnect) => {
    const [socket, setSocket] = useState<WebSocket>();
    const [open, setOpen] = useState(false);
    
    useEffect(() => {
        if(!dontConnect) {
            let initSocket: WebSocket;

            getSocket().then(socket => {
                initSocket = socket;
                socket.onopen = () => setOpen(true);
                socket.onclose = () => setOpen(false);
                setSocket(socket);
            });

            return () => initSocket && initSocket.close();    
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dontConnect]);

    useEffect(() => {
        if (socket) {
            socket.onmessage = evt => onMessage(JSON.parse(evt.data));
        }   
    });

    const send = useCallback(
        (data: any) => open && socket && socket.send(JSON.stringify(data)), 
    [open, socket]);

    return {
        send,
        socket,
        open,
    };
}

const ws_protocol = localStorage.getItem("ws_protocol") || "wss";

/**
 * Функция для короткого получения соединения через урл токена и соединения
 * 
 * @example
 * useWebSocket(socketByToken('/chat/token', token => `chat?token=${token}`))
 * 
 * @param getTokenUrl - Урл токена без /api
 * @param getConnectUrlLessPrefix
 */
export const socketByToken =
    (getTokenUrl: string, getConnectUrlLessPrefix: (tocken: string) => string) => () =>
        apiFetch<string>('/api' + getTokenUrl)
            // eslint-disable-next-line no-restricted-globals
            .then(token => new WebSocket(`${ws_protocol}://${location.host}/api/${getConnectUrlLessPrefix(token)}`))
