import React, { ReactNode } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { MenuItem } from '.';

export interface MenuRoute {
    path: string;
    title?: string;
    icon?: JSX.Element;
    menuComponent?: React.ComponentType<{ selected?: boolean }>;
    suffix?: ReactNode;
    component: React.ComponentType<any>;
    componentProps?: any;
    alsoActivateForPath?: (path: string) => boolean;
    resolveHidden?: (roles: string[], context?: any) => boolean;
    hidden?: boolean;
    exact?: boolean;
}

export const Routes = ({ routes, notFoundRedirect }: { routes: MenuRoute[], notFoundRedirect?: string }) => {
    return (
        <Switch>
            {routes.map(r => {
                const Comp = r.component;
                return (
                <Route path={r.path} key={r.path} exact={r.exact}><Comp {...(r.componentProps || {})} /></Route>
            )})}
            {notFoundRedirect && <Redirect to={notFoundRedirect} />}
        </Switch>
    )
}

interface RoutesToRender {
    routes: MenuRoute[];
    menuItems: MenuItem[];
}

export const getRoutesToRender = (routesin: MenuRoute[], user: { roles?: string[] } | null): RoutesToRender => {
    const routes = routesin.filter(r => r.resolveHidden === undefined || r.resolveHidden(user?.roles || []));
    const menuItems = routes
        .filter(r => !r.hidden && r.title)
        .map(r => ({ ...r, title: r.title || "" }));

    return {
        routes,
        menuItems,
    }
}
