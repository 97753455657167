import React, { ReactNode, useContext } from "react";
import { MediaLib, MediaLibConfig, useMediaLib } from "./useMediaLib";

const MediaLibContext = React.createContext<MediaLib & { isAvailable?: boolean }>({
    data: [],
    isLoading: true,
    isUploading: false,
    protectedFiles: false,
    filter: "",
    setFilter: () => { },
    getFilepath: mf => mf.filename,
    reload: () => { },
    downloadFile: () => { throw new Error("Missing media lib context"); },
    removeFile: () => { throw new Error("Missing media lib context"); },
    replaceFile: () => { throw new Error("Missing media lib context"); },
    uploadFile: () => { throw new Error("Missing media lib context"); },
});

export interface MediaLibProviderProps extends MediaLibConfig {
    apiPath: string;
    spaceId: string;
    children?: ReactNode;
}

export const MediaLibProvider = (props: MediaLibProviderProps) => {
    const data = useMediaLib(props.apiPath, props.spaceId, props);

    return (
        <MediaLibContext.Provider value={{ isAvailable: true, ...data }}>
            {props.children}
        </MediaLibContext.Provider>
    );
}

export const useMediaLibContext = () => useContext(MediaLibContext);