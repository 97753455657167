import React from 'react';
import { QueryConsole } from './QueryConsole';
import { useQueryConsole } from './useQueryConsole';

interface Props {
    apiPath?: string;
}

export const QueryConsoleContainer = (props: Props) => {
    const data = useQueryConsole(props.apiPath || '/api/query-console');
    return <QueryConsole data={data} />;
}
