import React, { ReactNode } from 'react';
import { useResetPassword } from '../../hooks/auth';
import { OuterCentralizedForm } from '../primitives/Forms';
import { SimpleButton } from '../primitives/Buttons';
import { TextField, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

interface ResetPasswordFormProps {
    restoreAPIPath: string;
    logoAlt?: string;
    messageOnSubmit?: ReactNode;
}

export const ResetPasswordForm: React.FC<ResetPasswordFormProps> = (props: ResetPasswordFormProps) => {
    const { email, updateEmail, restore, isSaving, isSaved } = useResetPassword(props.restoreAPIPath);

    return (
        <OuterCentralizedForm>
            {!isSaved && <>
                <TextField
                    label={<FormattedMessage id="resetPassword.email"/>}
                    value={email}
                    onChange={(ev) => updateEmail(ev.target.value || '')}
                />
                <SimpleButton
                    label={<FormattedMessage id="resetPassword.submit"/>}
                    action={restore}
                    isLoading={isSaving}
                />
            </>}
            {isSaved && (props.messageOnSubmit || <Typography><FormattedMessage id="resetPassword.success"/></Typography>)}
        </OuterCentralizedForm>
    )
};
