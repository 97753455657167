import React from 'react';

export const InlineChromiumBugfix = () => (
    <span
      contentEditable={false}
      style={{ fontSize: 0 }}
    >
      ${String.fromCodePoint(160) /* Non-breaking space */}
    </span>
);
