import React from 'react';
import { RenderElementProps, useSelected } from 'slate-react';
import { EditorPlugin, PowerEditorBase } from '../PowerDoc';
import { createWithVoidInjector } from '../PowerDoc/plugins/common';
import { CustomElement } from '../../../slate';
import { FormGrid } from '../primitives';
import { useIntl } from 'react-intl';
import { usePowerEditorContext } from '../PowerDoc/slate/PowerEditorContext';
import { usePowerEditorSettings } from '../PowerDoc/PowerEditorSettingsContext';
import { TextField } from '@material-ui/core';
import { PanelWrapper } from '../PowerDoc/plugins/Panel/PanelBlockElement';
import { useBlockEdit } from '../PowerDoc/plugins';

const ScriptableBlockElementType = "scriptable-block";

export const ScriptableBlockElement = (props: RenderElementProps) => {
  const { element, attributes, children } = props;

  const isSelected = useSelected();
  const { formatMessage } = useIntl();

  const { viewMode } = usePowerEditorContext();
  
  const settings = usePowerEditorSettings();
  const EditorComponent = settings.EditorComponent || PowerEditorBase;

  const panel = useBlockEdit(element, {
    getElementState: e => ({ content: e.content, expression: e.expression }),
  });

  return (
    <PanelWrapper {...attributes} className="panel-block-element" isSelected={isSelected} viewMode={viewMode}>
        {children}
        <div style={{ width: "100%" }} contentEditable={false}>
          <FormGrid noMargin columns="1fr">
            <TextField
              value={panel.expression || ""}
              onChange={e => panel.update({ expression: e.target.value })}
              label=""
              placeholder={formatMessage({ id: "powerdoc.plugins.placeholder.expression" })}
              fullWidth
              />
          </FormGrid>
          
          <EditorComponent
              content={panel.content}
              update={v => panel.update({ content: v })}
              viewMode={viewMode}
              />
        </div>
    </PanelWrapper>
  );
}

export const ScriptableBlockPlugin: EditorPlugin = {
  key: "scriptable-block",
  customBlocks: {
    [ScriptableBlockElementType]: ScriptableBlockElement,
  },
  inject: createWithVoidInjector(ScriptableBlockElementType),
  commands: [
    {
      name: "scriptable-block",
      invoke: editor => {
        editor.insertNode({
            type: ScriptableBlockElementType,
            children: [{ text: "", }],
        } as CustomElement);
    },
      menu: { label: "Block", label_id: "emails.popup.scriptable_block", section: "insert-item", },
    },
  ],
}