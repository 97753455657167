import React from 'react';
import { useParams } from 'react-router-dom';
import { RecordsListPageProps } from '../Lists/RecordsListPage';
import { RecordForm } from './RecordForm';
import { useRecordEdit } from './useRecordEdit';

interface Props extends RecordsListPageProps {
}

export const RecordFormPage = (props: Props) => {
    const { id } = useParams<{ id: string }>();
    const data = useRecordEdit(props.apiPath, props.recordType, +id);
    return <RecordForm data={data} />;
}
