import { useState } from "react";
import { downloadFile } from "../../api/core";
import { FieldType, Schema } from "../../hooks/useSchema";
import { FileInfo, FileListConfig, useFileList } from "./useFileList";

export interface Document {
    _id: string;
    filename: string;
    comment?: string;
    meta?: Record<string, any>;
}

export const DocumentSchema: Schema = {
    filename: { type: FieldType.text, label_id: "files.filename" },
    comment: { type: FieldType.textlong, label_id: "files.comments" },
}

export interface SimpleDocumentListData {
    isLoading: boolean;
    documents: Document[];
    create: () => Promise<Document>;
    upload: (f: File) => Promise<Document>;
    schema: Schema;
    download: (d: Document) => void;

    replace: (d: Document) => Promise<Document>;
    remove: (d: Document) => Promise<void>;

    editedRecord: Document | null;
    update: (changes: Partial<Document>) => void;
    startEditing: (d: Document) => void;
    cancelEditing: () => void;
    saveEditing: () => void;
}

const fileInfoToDoc = ({ _id, filename, meta }: FileInfo): Document => ({ _id, filename, comment: meta?.comment, meta  });

export const useSimpleDocumentList = (apiPath: string, metaFilter?: Record<string, any>, cfg?: FileListConfig): SimpleDocumentListData => {
    const files = useFileList(apiPath, metaFilter, cfg);
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const [editedRecord, setEditedRecord] = useState<Document | null>(null);

    const upload = (f: File) => {
        setIsUploading(true);
        return files.upload(f)
            .then(f => {
                setIsUploading(false);
                return fileInfoToDoc(f);
            })
            .catch(e => {
                setIsUploading(false);
                throw e;
            });
    }

    const create = () => {
        setIsUploading(true);
        return files.create()
            .then(f => {
                setIsUploading(false);
                return fileInfoToDoc(f);
            })
            .catch(e => {
                setIsUploading(false);
                throw e;
            })
    }

    return {
        isLoading: files.isLoading || isUploading,
        documents: files.data.map(fileInfoToDoc),
        upload,
        create,
        download: (d: Document) => downloadFile(`${apiPath}/${d._id}`, d.filename),
        schema: DocumentSchema,

        remove: (d: Document) => {
            const fi = files.data.find(f => f._id === d._id);
            if(fi) {
                setEditedRecord(null);
                return files.remove(fi);
            } else {
                return Promise.resolve();
            }
        },
        replace: (d: Document) => {
            const fi = files.data.find(f => f._id === d._id);
            if(fi) {
                setIsUploading(true);
                return files.replace(fi)
                    .then(fi => {
                        setIsUploading(false);
                        setEditedRecord(null);
                        return fileInfoToDoc(fi);
                    })
                    .catch(e => {
                        setIsUploading(false);
                        throw e;
                    });
            } else {
                return Promise.resolve(d);
            }
        },

        editedRecord,
        startEditing: d => setEditedRecord({ ...d}),
        update: c => {
            if(editedRecord) {
                setEditedRecord({ ...editedRecord, ...c});
            }
        },
        cancelEditing: () => setEditedRecord(null),
        saveEditing: () => {
            if(editedRecord) {
                setIsUploading(true);
                const fi = files.data.find(f => f._id === editedRecord._id);
                if(fi) {
                    files.updateInfo(fi, { comment: editedRecord.comment })
                        .then(r => {
                            setIsUploading(false);
                            setEditedRecord(null);
                        })
                        .catch(e => {
                            setIsUploading(false);
                            throw e;
                        })
                }
            }
        }
    }
}

