import axios from "axios";
import { DragEvent } from "react";
import { apiFetch, FetchTypes, getApiTokenForRequest, UnauthenticatedError } from "./core";

export const apiUploadFile = (url: string, method: FetchTypes, formfield: string, file: any): Promise<any> => {
    const data = new FormData();
    data.append(formfield, file);
    return axios.request({
        url,
        method,
        data,
        headers: {
            "Authorization": getApiTokenForRequest(),
        },
        timeout: 120000,
    })
        .catch(error => {
            if (error.response.status === 401) {
                throw new UnauthenticatedError();
            } else {
                throw error;
            }
        });
}

export const apiFetchFile = (url: string) => apiFetch<Blob>(url, FetchTypes.GET, undefined, { responseType: 'blob' });

export const downloadFile = (url: string, filename: string) => {
    let anchor = document.createElement("a");
    document.body.appendChild(anchor);

    return apiFetchFile(url)
        .then(blob => {
            let objectUrl = window.URL.createObjectURL(blob);
            
            anchor.href = objectUrl;
            anchor.download = filename;
            anchor.click();

            window.URL.revokeObjectURL(objectUrl);
        })
}


/**
 * Открывает диалоговое окно для выбора файла и передаёт выбранный файл функции обратного вызова
 * @param callback 
 */
export const openFileUploader = (callback: (file: File) => void) => {
    const input = document.createElement('input') as HTMLInputElement;

    input.setAttribute('type', 'file');
    input.onchange = () => callback((input.files as FileList)[0]);
    input.click();
}

/**
 * Оборачивает файл в FormData
 * 
 * @example
 * (file: File) => apiFetch('/path', FetchTypes.POST, asFormData(file));
 * 
 * @param file 
 */
export const asFormData = (file: File, fieldName: string = 'file') => {
    const data = new FormData();
    data.set(fieldName, file);
    return data;
}

/**
 * Переводит файл в base64 код, 
 * который можно использовать как src для картинок и скачивания файлов
 * @example
 * // Этот пример больше для наглядности чем для применения
 * async (file: File) => <img src={await readBlobAsDataURL(file)}/>
 * 
 * @returns - Промис с получившимся base64 кодом
 */
export const readBlobAsDataURL = (blob: Blob): Promise<string | undefined> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            if (e.target) {
                resolve((e.target.result as string) || undefined);
            }
        }
        reader.readAsDataURL(blob);
    });
};

type FileCallback = (file: File) => void;

export const createFileDropHandler = (handleFile: FileCallback, multiple?: boolean) => {
    return (e: DragEvent<any>) => {
        e.preventDefault();
        
        if (e.dataTransfer.items) {
            for(let i = 0; i < e.dataTransfer.items.length; i++) {
                const item = e.dataTransfer.items[i];
                if(item.kind === "file") {
                    const f = item.getAsFile();
                    if(f) {
                        handleFile(f);
                        
                        if(!multiple) {
                            return;
                        }
                    }
                }
            }
        } else {
            if(e.dataTransfer.files.length) {
                for(let i = 0; i < e.dataTransfer.files.length; i++) {
                    const file = e.dataTransfer.files[i];
                    handleFile(file);
                    
                    if(!multiple) {
                        return;
                    }
                }
            }
        }
    }
}
