import React from 'react';
import styled from '@emotion/styled';
import { ReactEditor, RenderElementProps, useSelected, useSlateStatic } from 'slate-react';
import { CustomElement } from '../../../../../slate';
import { Editor, Transforms } from 'slate';
import { MediaFile, PossiblyProtectedImg, useMediaLibContext } from '../../../medialib';
import { Button, IconButton, Typography, withTheme } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { DescriptionOutlined, PhotoLibraryOutlined, PhotoOutlined } from '@material-ui/icons';
import { downloadFile } from '../../../../api/core';
import { usePowerEditorContext } from '../../slate/PowerEditorContext';
import { BlockSelectionCss } from '../../elements/Common';

const Wrapper = withTheme(styled.div<{ isSelected?: boolean }>`
    position: relative;
    padding: 0.5rem 0;
    ${props => BlockSelectionCss(props)}

    & .open-browser-button, & .display-mode-button {
        display: none;
    }

    &:hover {
        & .open-browser-button {
            display: block;
            position: absolute;
            top: 0.75rem;
            left: 0.25rem;
            box-shadow: 0 0 4px -1px #00000040;
            background: #ffffffa0;
        }

        & .display-mode-button {
            display: block;
            position: absolute;
            top: 0.75rem;
            right: 0.25rem;
            box-shadow: 0 0 4px -1px #00000040;
            background: #ffffffa0;

            & .MuiButton-label {
                text-transform: none;
            }
        }
    }
`);

const Placeholder = withTheme(styled.div`
    background: ${props => props.theme.palette.grey[100]};
    padding: 1rem 0.5rem;
    text-align: center;
    border-radius: 8px;
    cursor: pointer;
`);

const ImagesWrapper = withTheme(styled.div<{ justifyContent?: React.CSSProperties["justifyContent"] }>`
    display: flex;
    flex-flow: row;
    height: auto;
    align-items: center;
    max-width: 100%;
    overflow-x: auto;
    justify-content: ${props => props.justifyContent || "flex-start"};

    &::-webkit-scrollbar {
        width: 2px;
        height: 2px;
        background-color: ${props => props.theme.palette.grey[200]};
    }
    &::-webkit-scrollbar-thumb {
        background-color: ${props => props.theme.palette.primary.light};
    }

    & > img {
        height: 100%;
        max-height: 300px;
    }

    & > button {
        margin-left: 0.5rem;
    }
`);

const FilesWrapper = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 1rem;
    row-gap: 0.5rem;
`;

const FileItem = withTheme(styled.div`
    display: flex;
    align-items: center;
    box-shadow: 0 0 5px -1px #00000040;
    padding: 0.5rem 0.5rem;
    border-radius: 4px;
    min-height: 3rem;
    cursor: pointer;

    & > svg:first-child {
        margin-right: 0.5rem;
    }
`);

const AlignToJustifyContent = {
    left: "flex-start",
    center: "center",
    right: "flex-end",
    justify: "space-between",
}

export type DisplayMode = "gallery" | "documents" | "slider" | null;

export const updateDisplayMode = (editor: Editor, element: CustomElement, displayMode: DisplayMode) => {
    const path = ReactEditor.findPath(editor, element);
    Transforms.setNodes(
        editor,
        {
            display_mode: displayMode,
        } as any,
        { at: path },
    );
}

const nextDisplayMode = (current: DisplayMode | undefined) => {
    switch(current) {
        case "documents":
            return "slider";
        case "slider":
            return "gallery";
        default:
            return "documents";
    }
}

interface Props extends RenderElementProps {
    openBrowser: (editor: Editor, element: CustomElement) => void;
    openPreview: (image: MediaFile) => void;
}

export const MediaFilesElementType = "media-files";

export const MediaFilesElement = (props: Props) => {
    const { element, attributes, children, openBrowser } = props;
    const editor = useSlateStatic();
    const medialib = useMediaLibContext();
    const { viewMode } = usePowerEditorContext();
    const isSelected = useSelected();

    const files = element.files || [];

    const isVariousFiles = files.find(mf => !mf.contenttype.startsWith("image"));

    return (
        <div {...attributes}>
            {children}
            <Wrapper contentEditable={false} isSelected={isSelected}>
                {!files.length && !viewMode &&
                    <Placeholder onClick={() => openBrowser(editor, element)}>
                        <PhotoOutlined /> <Typography><FormattedMessage id="powerdoc.plugins.media_files.empty_state" /></Typography>
                    </Placeholder>}

                {files.length > 0 && !viewMode &&
                    <IconButton size="small" className="open-browser-button" onClick={() => openBrowser(editor, element)}>
                        <PhotoLibraryOutlined />
                    </IconButton>}

                {files.length > 0 && !viewMode &&
                    <Button className="display-mode-button" onClick={() => updateDisplayMode(editor, element, nextDisplayMode(element.display_mode as DisplayMode))}>
                        <FormattedMessage id="powerdoc.plugins.media_files.display_mode.title" />: <FormattedMessage id={`powerdoc.plugins.media_files.display_mode.${element.display_mode || "gallery"}`} />
                    </Button>}
                
                {!isVariousFiles &&
                    <ImagesWrapper justifyContent={(AlignToJustifyContent as any)[element.align || ""]}>
                        {files.map(mf => (
                            <PossiblyProtectedImg
                                protected={medialib.protectedFiles}
                                src={medialib.getFilepath(mf)}
                                alt={mf.filename}
                                onClick={e => {
                                    props.openPreview(mf);
                                    e.stopPropagation();
                                }}
                                />
                        ))}
                    </ImagesWrapper>}
                
                {isVariousFiles &&
                    <FilesWrapper>
                        {files.map(mf => (
                            <FileItem
                                onClick={e => {
                                    downloadFile(medialib.getFilepath(mf));
                                    e.stopPropagation();
                                }}
                                >
                                <DescriptionOutlined fontSize="large" color="action" />
                                <Typography>{mf.filename}</Typography>
                            </FileItem>
                        ))}
                    </FilesWrapper>}
            </Wrapper>
        </div>
    );
}
