import React, { ReactNode, useState } from 'react';
import styled from '@emotion/styled';
import { Button, Checkbox, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, } from '@material-ui/core';
import { Edit, ExitToApp, Replay, LockOpen, Lock, VpnKeyOutlined, GetApp, VpnKey, RadioButtonUnchecked, Check, MoreVert, FileCopyOutlined, Add } from '@material-ui/icons';
import { FormattedMessage } from 'react-intl';
import { useUserManagement } from './useUserManagement';
import { OccupyFreeSpace, SimpleIconButton } from '../primitives';
import { Form } from '../primitives/Forms';
import { LoadingIndicator } from '../primitives/LoadingIndicator';
import { SearchField } from '../primitives/SearchField';
import { Tooltip } from '../primitives/Tooltip';
import { TableRowButtons, useFormats } from '../schemed';
import UserPopup from './UserPopup';
import { IUserRole as IURole, IDefaultUser as IDUser } from './typings';
import { ChangeEmailPopup } from './ChangeEmailPopup';
import { useFieldSorting } from '../../hooks/useFieldSorting';
import { utc } from '../timezone';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { defaultUserFilter } from '.';
import { ChangePasswordPopup } from './ChangePasswordPopup';
import { UserTwoFactorAuthSettingsPopup } from './UserTwoFactorAuthSettingsPopup';
import { useListAutoexpander } from '../primitives/useListAutoexpander';
import { useCopyText } from '../primitives';
import { SendInvitesConfirmationPopup } from './SendInvitesConfirmationPopup';

const NoWrapColumn = styled(TableCell)`
    white-space: nowrap;
`;

const UserRow = styled(TableRow)`
    & td:nth-child(1) {
        opacity: 0;
    }

    &:hover {
        & td:nth-child(1) {
            opacity: 1;
        }
    }
`;

export type IUserRole = IURole;
export type IDefaultUser = IDUser;

export interface UserManagementFormProps<UserRole extends IUserRole> {
    userRoles: UserRole[];
    allowCreateUsers?: boolean;
    allowInvites?: boolean;
    allowPaswordReset?: boolean;
    allowPaswordChange?: boolean;
    twoFactorAuthEnabled?: boolean;
    noMassInviteNew?: boolean;
}

const UserManagementForm = <UserRole extends IUserRole = IUserRole, IUser extends IDefaultUser = IDefaultUser>(props: UserManagementFormProps<UserRole>) => {
    const { formatDatetime } = useFormats();
    const {
        users,
        filter,
        setFilter,
        saveRoles,
        saveUser,
        blockUser,
        unblockUser,
        isLoading,
        addUsers,
        downloadUsers,
        invites,
        sendConfirmationRequest,
        sendPasswordResetEmail,
        changeUserEmail,
        changeUserPassword,
        loginAs,
    } = useUserManagement<IUser>('/api/user', '/api/loginas', defaultUserFilter);

    const snackbar = useSnackbar();

    const doPasswordResetRequest = (userId: string) => {
        sendPasswordResetEmail(userId);
        snackbar.enqueueSnackbar(<FormattedMessage id="userManagement.password_reset_email_sent" />, { variant: 'success', autoHideDuration: 2000 })
    }

    const [isUserPopupOpen, setIsUserPopupOpen] = useState<boolean>(false);
    const [userForEmailChange, setUserForEmailChange] = useState<IUser | null>(null);
    const [userForPasswordChange, setUserForPasswordChange] = useState<IUser | null>(null);
    const [userForTwoFactor, setUserForTwoFactor] = useState<IUser | null>(null);
    const sorting = useFieldSorting();
    const copyText = useCopyText();

    const [userMenu, setUserMenu] = useState<{ anchor: HTMLElement, user: IUser } | null>(null);
    const openUserActions = (anchor: HTMLElement, user: IUser) => {
        setUserMenu({ anchor, user });
    }
    const closeUserMenu = () => setUserMenu(null);

    const { userRoles, allowCreateUsers, allowInvites, noMassInviteNew } = props;

    const sortedRoles = userRoles.sort((ur1, ur2) => ur1.key > ur2.key ? 1 : -1);

    const skip = ["__skip__", ""];

    const columns = [
        ["email", <FormattedMessage id="userManagement.columns.email" />],
        ["last_login", <FormattedMessage id="userManagement.columns.lastLogin" />, 500],
        ["blocked_until", <FormattedMessage id="userManagement.columns.blocked_until" />],
        allowInvites ? ["invited_datetime", <FormattedMessage id="userManagement.columns.invited" />] : skip,
        ["confirmed", <FormattedMessage id="userManagement.columns.confirmed" />],
        props.twoFactorAuthEnabled ? ["two_factor_auth", <FormattedMessage id="userManagement.columns.two_factor_auth" />] : skip,
    ]
        .concat(sortedRoles.map(ur => [`role_${ur.key}`, ur.name]))
        .filter(c => c[0] !== skip[0]) as [string, ReactNode, number?][];

    const onRoleToggle = (userId: string, role: string) => {
        let userCopy = users.slice();
        let user = userCopy.find(u => u._id === userId);

        if (user) {
            const roleIndex = user.roles.findIndex(r => r === role);

            if (roleIndex > -1) {
                user.roles.splice(roleIndex, 1);
            }
            else {
                user.roles.push(role);
            }

            saveRoles(user);
        }
    }

    const onAddUsers = (newUsers: string[]) => {
        addUsers(newUsers);
        setIsUserPopupOpen(false);
    }

    const showDatetime = (v?: string) => v ? formatDatetime(utc.toLocal(v)) : "";
    const isUserBlocked = (user: IUser) => user.blocked_until && moment().isBefore(utc.toLocal(user.blocked_until));

    const sortedUsers = users.sort((u1,u2) => {
            if(!sorting.sort) {
                return u1.email > u2.email ? 1 : -1;
            }

            const { field, direction } = sorting.sort;

            const up = direction === 'asc' ? 1 : -1;

            if(field.startsWith('role_')) {
                const role = field.substr(5);
                return u1.roles.includes(role) && !u2.roles.includes(role) ? up : -up;
            } else {
                return (u1 as any)[field] > (u2 as any)[field] ? up : -up;
            }
        });

    const autoexpander = useListAutoexpander(sortedUsers,  20, 20);

    return (
        <Form
            title={<FormattedMessage id="userManagement.title" />}
            headerItems={<>
                {(allowCreateUsers || allowInvites) &&
                    <Tooltip text_id="userManagement.header.addUser">
                        <IconButton size="small" color="primary" onClick={() => setIsUserPopupOpen(true)}><Add /></IconButton>
                    </Tooltip>}

                {allowInvites && !noMassInviteNew &&
                    <Tooltip text_id="userManagement.header.sendInvintesTip" withWrapper>
                        <Button color="primary" size="small" onClick={() => invites.start()}>
                            <FormattedMessage id="userManagement.header.sendInvites" />
                        </Button>
                    </Tooltip>}
                
                <OccupyFreeSpace />
                
                {isLoading && <LoadingIndicator />}
                <SearchField filter={filter} setFilter={setFilter} doSearch={() => {}} noButton autoFocus />
                <Tooltip text_id="userManagement.header.export_users">
                    <IconButton size="small" onClick={downloadUsers}>
                        <GetApp />
                    </IconButton>
                </Tooltip>
            </>}
            formHeaderProps={{ alignItems: "end" }}
            formPaperProps={{ elevation: 0 }}
            fitFullHeight
        >
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <NoWrapColumn key="header-actions-head" />
                            
                            {columns.map(([field, cname, width]) => (
                                <NoWrapColumn
                                    key={`header-${field}`}
                                    sortDirection={sorting?.sort?.field === field ? sorting.sort.direction : undefined}
                                    width={width}
                                    align={field.startsWith("role") ? "center" : undefined}>
                                    <TableSortLabel
                                        disabled={!sorting}
                                        active={sorting?.sort?.field === field}
                                        direction={sorting?.sort?.direction}
                                        onClick={sorting ? () => sorting.setNextSort(field) : undefined}
                                        >
                                        {cname}
                                    </TableSortLabel>
                                </NoWrapColumn>))}
                            
                            <NoWrapColumn key="header-actions-tail" />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {autoexpander.visibleItems.map(user => (
                            <UserRow key={`row-${user._id}`}>
                                <TableCell key="buttons-head" padding='checkbox'>
                                    <IconButton size="small" onClick={e => openUserActions(e.currentTarget, user)}>
                                        <MoreVert />
                                    </IconButton>
                                </TableCell>

                                <NoWrapColumn key="email">
                                    {user.email}
                                </NoWrapColumn>
                                <NoWrapColumn key="last_login" width={500}>{showDatetime(user.last_login)}</NoWrapColumn>
                                <NoWrapColumn key="block">
                                    {isUserBlocked(user) ?
                                        (<>
                                            <Tooltip key="blocked" text_id="userManagement.columns.blocked_until_explanation" withWrapper>
                                                <>{showDatetime(user.blocked_until)}</>
                                            </Tooltip>
                                            <Tooltip key="unblock" text_id="userManagement.columns.unblock" withWrapper>
                                                <SimpleIconButton action={() => unblockUser(user)} icon={<Lock />} />
                                            </Tooltip>
                                        </>)
                                        :
                                        (<Tooltip key="block" text_id="userManagement.columns.block" withWrapper>
                                            <SimpleIconButton action={() => blockUser(user)} icon={<LockOpen />} />
                                        </Tooltip>)}
                                </NoWrapColumn>
                                {allowInvites &&
                                    <NoWrapColumn key="invite">
                                        {showDatetime(user.invited_datetime)}
                                        <Tooltip text_id="userManagement.columns.invite" withWrapper>
                                            <SimpleIconButton action={() => invites.sendUserInvite(user._id)} icon={<Replay />} />
                                        </Tooltip>
                                    </NoWrapColumn>
                                }
                                <TableCell key="confirmation">
                                    <Tooltip text_id={user.repeated_confirmation_request_at
                                                        ? "userManagement.columns.repeatedConfirmRequestTime"
                                                        : "userManagement.columns.repeatedConfirmRequestNever"}
                                            text_params={{ time: user.repeated_confirmation_request_at ?
                                                                showDatetime(user.repeated_confirmation_request_at) : null }}
                                            withWrapper >
                                        <Checkbox
                                            checked={user.confirmed}
                                            disabled
                                            color="primary"
                                        />
                                    </Tooltip>
                                    {!user.confirmed && (
                                        <Tooltip text_id="userManagement.columns.resendConfirmRequest" withWrapper>
                                            <SimpleIconButton action={() => sendConfirmationRequest(user._id)} icon={<Replay />} />
                                        </Tooltip>
                                    )}
                                </TableCell>

                                {props.twoFactorAuthEnabled &&
                                    <TableCell key="two_factor_auth" align="center">
                                        <SimpleIconButton
                                            action={() => setUserForTwoFactor(user)}
                                            icon={user.two_factor_auth?.is_enabled ? <Check /> : <RadioButtonUnchecked />}
                                            />
                                    </TableCell>
                                }

                                {sortedRoles.map((sr, index) => (
                                    <TableCell key={`role-cell-${user._id}-${index}`} padding="checkbox" align="center">
                                        <Checkbox
                                            checked={user.roles.find(r => r === sr.key) ? true : false}
                                            onChange={() => onRoleToggle(user._id, sr.key)}
                                            color="primary"
                                            />
                                    </TableCell>
                                ))}

                                <TableCell key="buttons" padding="checkbox">
                                    <TableRowButtons>
                                        {props.allowPaswordReset && <Tooltip text_id="userManagement.columns.sendPasswordResetToken" withWrapper>
                                            <SimpleIconButton action={() => doPasswordResetRequest(user._id)} icon={<VpnKeyOutlined />} />
                                        </Tooltip>}
                                        {props.allowPaswordChange && <Tooltip text_id="userManagement.columns.change_password" withWrapper>
                                            <SimpleIconButton action={() => setUserForPasswordChange(user)} icon={<VpnKey />} />
                                        </Tooltip>}
                                        <Tooltip text_id="userManagement.columns.signInAs" withWrapper>
                                            <SimpleIconButton action={() => loginAs(user._id)} icon={<ExitToApp />} />
                                        </Tooltip>
                                    </TableRowButtons>
                                </TableCell>
                            </UserRow>
                        ))}
                    </TableBody>
                </Table>
                {autoexpander.anchor}
            </TableContainer>
            <UserPopup
                isOpen={isUserPopupOpen}
                onClose={() => setIsUserPopupOpen(false)}
                title={<FormattedMessage id="userManagement.userPopup.title" />}
                onSave={onAddUsers}
            />
            <ChangeEmailPopup
                user={userForEmailChange}
                close={() => setUserForEmailChange(null)}
                changeEmail={(u,e) => changeUserEmail(u._id, e)}
            />
            <ChangePasswordPopup
                user={userForPasswordChange}
                close={() => setUserForPasswordChange(null)}
                changePassword={(u,e) => changeUserPassword(u._id, e)}
            />
            <UserTwoFactorAuthSettingsPopup
                user={userForTwoFactor}
                close={() => setUserForTwoFactor(null)}
                saveChanges={u => saveUser(u as IUser)}
                />
            <SendInvitesConfirmationPopup data={invites} />


            <Menu
                open={!!userMenu}
                anchorEl={userMenu?.anchor}
                keepMounted
                onClose={closeUserMenu}
                onClick={() => closeUserMenu()}
                
                >
                {userMenu?.user && <>
                    <MenuItem key="edit-email" dense onClick={() => setUserForEmailChange(userMenu?.user)}>
                        <ListItemIcon><Edit /></ListItemIcon>
                        <ListItemText primary={<FormattedMessage id="userManagement.changeEmailPopup.title" />} />
                    </MenuItem>
                    <MenuItem key="copy-email" dense onClick={() => copyText(userMenu.user.email)}>
                        <ListItemIcon><FileCopyOutlined /></ListItemIcon>
                        <ListItemText primary={<FormattedMessage id="userManagement.copy_email" />} />
                    </MenuItem>
                    <MenuItem key="copy-id" dense onClick={() => copyText(userMenu.user._id)}>
                        <ListItemIcon><FileCopyOutlined /></ListItemIcon>
                        <ListItemText primary={<FormattedMessage id="userManagement.copy_id" />} />
                    </MenuItem>

                    <MenuItem divider dense disabled />

                    {props.allowPaswordReset && <MenuItem key="password-reset" dense onClick={() => doPasswordResetRequest(userMenu.user._id)}>
                        <ListItemIcon><VpnKeyOutlined /></ListItemIcon>
                        <ListItemText primary={<FormattedMessage id="userManagement.columns.sendPasswordResetToken" />} />
                    </MenuItem>}

                    {props.allowPaswordChange && <MenuItem key="password-change" dense onClick={() => setUserForPasswordChange(userMenu.user)}>
                        <ListItemIcon><VpnKey /></ListItemIcon>
                        <ListItemText primary={<FormattedMessage id="userManagement.columns.change_password" />} />
                    </MenuItem>}

                    <MenuItem key="login-as" dense onClick={() => loginAs(userMenu.user._id)}>
                        <ListItemIcon><ExitToApp /></ListItemIcon>
                        <ListItemText primary={<FormattedMessage id="userManagement.columns.signInAs" />} />
                    </MenuItem>
                </>}
            </Menu>
        </Form>
    )
}

export default UserManagementForm;