import React from 'react';
import { LocalizationMessages } from './LocalizationMessages';
import { LocalizationMessagesConfig, useLocalizationMessagesEdit } from './useLocalizationMessagesEdit';

export interface LocalizationMessagesContainerProps extends LocalizationMessagesConfig {
    
}

export const LocalizationMessagesContainer = (props: LocalizationMessagesContainerProps) => {
    const data = useLocalizationMessagesEdit(props);

    return <LocalizationMessages data={data} />;
}
