import { useNewItem } from "../../api/useNewItem";
import { useCrudUnpagedList } from "../../api/useSimpleCrud";
import { ProgramSchema } from "./schema";
import { CreateProgram, Program } from "./types";


export const useProgramsList = (apiPath: string) => {
    const data = useCrudUnpagedList<Program>(apiPath, {
        defaultView: "all",
        filterBy: p => `${p.title} ${p._id}`,
    });

    const newItem = useNewItem<CreateProgram, Program>(apiPath, {
        title: "",
    });

    return {
        ...data,
        create: () => {
            newItem.startEditing();
            return Promise.resolve({} as Program);
        },
        newItem,
        schema: ProgramSchema,
    }
}
