import React from 'react';
import { RecordsList } from './RecordsList';
import { useRecordsList } from './useRecordsList';

export interface RecordsListPageProps {
    apiPath: string;
    recordType: string;
}

export const RecordsListPage = (props: RecordsListPageProps) => {
    const data = useRecordsList(props.apiPath, props.recordType);
    return <RecordsList data={data} />;
}
