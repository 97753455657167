import React, { useState } from 'react';
import {
    useHistory,
} from "react-router-dom";
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import InnerRoutes from './InnerRoutes';
import { clearApiToken, decodeToken, getApiToken } from './toolympus/api/core';
import { AppWrapper, Sidebar, Menu, Routes, getRoutesToRender, AppContentSimple } from './toolympus/components/frame/new';
import { DefaultUser, UserContext } from './toolympus/userContext/UserContext';
import { ExitToApp } from '@material-ui/icons';
import { useLoginGuard } from './toolympus/hooks/auth/useLoginGuard';
import { Logo } from './components/Common';
import { MediaLibProvider } from './toolympus/components/medialib';


const AppRouter: React.FC = () => {
    const token = getApiToken();
    const history = useHistory();
    const [user, setUser] = useState<DefaultUser>(token ? decodeToken(token) as DefaultUser : { _id: '', roles: [], email: '' });

    const logout = () => {
        clearApiToken();
        history.push('/login');
    }

    useLoginGuard("/login", { isAuthenticated: !!user && user.roles.length > 0 });

    const { routes, menuItems } = getRoutesToRender(InnerRoutes, user);

    const bottom = (
        <List>
            <ListItem button key='logout' onClick={logout}>
                <ListItemIcon><ExitToApp /></ListItemIcon>
                <ListItemText primary='Выход' />
            </ListItem>
        </List>
    )

    return (
        <UserContext.Provider value={{ user: user, setUser: setUser }}>
          <MediaLibProvider apiPath="/api/media" spaceId="1" urlPrefix="/media">
            <AppWrapper>
                <Sidebar
                    top={<Logo />}
                    menu={<Menu menuItems={menuItems} />}
                    bottom={bottom}
                    open={true}
                    onClose={() => { }}
                    drawerPaperProps={{ shadow: true }}
                />
                <AppContentSimple fit100Height>
                    <Routes routes={routes} notFoundRedirect="/system" />
                </AppContentSimple>
            </AppWrapper>
          </MediaLibProvider>
        </UserContext.Provider>
    );
}

export default AppRouter;

