import React, { useState, useEffect, createContext, ComponentPropsWithoutRef, useContext, ReactNode } from 'react';
import { apiFetch } from '../api/core';
import { useLoadedData } from './useLoadedData';

export enum FieldType {
    bool = "bool",
    select = "select",
    multiselect = "multiselect",
    dictionarySelect = "dictionary-select",
    dictionarySelectMulti= "dictionary-multi-select",
    date = "date",
    datetime = "datetime",
    number = "number",
    decimal = "decimal",
    text = "text",
    textlong = "text-long",
    markdown = "markdown",
    json = "json",
    password = "password",
    mediafile = "mediafile",
}

export interface ValueOption {
  value: any;
  label: string;
}

export interface FieldSchema {
    type?: FieldType;
    label?: string | ReactNode;
    label_id?: string;
    values?: ValueOption[];
    valueDict?: any;
    dictionary?: string;
    ["max-length"]?: number;
    ["min-length"]?: number;
    hint?: string;
    hint_id?: string;
}

export type Schema = Record<string, FieldSchema>;

export interface Schemas extends Record<string, Schema> {
    case: Schema;
    arbitrator: Schema;
    casearbitrator: Schema;
    practice: Schema;
    dictionary: Schema;
}

const createValueDict = (values?: ValueOption[]) => {
  return (values || []).reduce((result, { value, label }) => ({ ...result, [value]: label }), {});
}

const addValuesDict = (fieldSchema: FieldSchema) => ({
    ...fieldSchema,
    valueDict: createValueDict(fieldSchema.values)
});

const addValuesDictsToFields = (s: Schema) => Object.keys(s).reduce((result, f) => ({ ...result, [f]: addValuesDict(s[f])}), {}) as Schema;

const preprocessSchemas = (schemas: Schemas) => Object.keys(schemas).reduce((result, k) =>
    ({ ...result, [k]: addValuesDictsToFields((schemas as unknown as {[_: string]: Schema})[k])}), {}) as Schemas;

const loadSchemas = () => apiFetch<Schemas>(`/api/uiconfig`).then(preprocessSchemas);

export const createSelectSchema = (values: ValueOption[], fieldSchema?: FieldSchema, ) => {
  return {
    values,
    valueDict: createValueDict(values),
    type: FieldType.select,
    ...(fieldSchema || {}),
  };
}

const NotLoadedSchema: Schema = { NOTLOADED: { type: FieldType.text, valueDict: {}}};
const defaultSchemas = {
    case: NotLoadedSchema,
    arbitrator: NotLoadedSchema,
    casearbitrator: NotLoadedSchema,
    practice: NotLoadedSchema,
    dictionary: NotLoadedSchema
};

export const SchemaContext = createContext<Schemas>(defaultSchemas);

export const SchemaProvider = (props: ComponentPropsWithoutRef<any>) => {
    const [schemas, setSchemas] = useState<Schemas>(defaultSchemas);
    const [isAwaitingSchema, setIsAwaitingSchema] = useState<boolean>(false);
    const clearAwaiting = () => setIsAwaitingSchema(false);
    const setAwaiting = () => {
        setIsAwaitingSchema(false);
        setTimeout(clearAwaiting, 1000);
    }

    useEffect(() => {
        loadSchemas().then(setSchemas).then(clearAwaiting);
    }, []);

    const schemaAccessGuard = {
        get: (target: Schemas, name: string) => {
            const result = (target as unknown as Record<string, Schema>)[name];
            if(result === NotLoadedSchema || !result) {
                console.log(`Schema ${name} not loaded yet, waiting...`);
                setAwaiting();
                return new Proxy(NotLoadedSchema, {
                    get: (t: Schema, f: string) => ({ type: FieldType.text, label: "", valueDict: {} })
                })
            } else {
                return result;
            }
        }
    };

    const guardedSchemas = new Proxy(schemas, schemaAccessGuard);

    return (isAwaitingSchema ?
        <>Loading schema...</> :
        <SchemaContext.Provider value={guardedSchemas}>
            {props.children}
        </SchemaContext.Provider>);
}

export const useSchema = () => useContext(SchemaContext);

interface SingleSchema {
    schema: Schema;
    isLoading: boolean;
    reload: VoidFunction;
}

export const useSingleSchema = (apiPath: string, runAuto?: boolean): SingleSchema => {
    const { data: schema, isLoading, reload } = useLoadedData(apiPath, {}, runAuto === undefined ? true : runAuto, addValuesDictsToFields);
    return { schema, isLoading, reload };
}

export type SchemaChanges = Record<string, Partial<FieldSchema>>;

export const mergeSchema = (source: Schema, changes: SchemaChanges): Schema => {
    if(source["NOTLOADED"]) {
        return source;
    }

    const result = { ...source };
    Object.keys(changes).forEach(k => {
        result[k] = { ...result[k], ...changes[k] };
    });

    return result;
}


export const schemaFieldsSorter = (schema: Schema) => (a: string, b: string) => {
    const la = schema[a]?.label;
    const lb = schema[b]?.label;
    if(la && lb) {
        return la > lb ? 1 : -1;
    } else if (la) {
        return -1;
    } else if (lb) {
        return 1;
    } else {
        return a > b ? 1 : -1;
    }
};
