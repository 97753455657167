import React from 'react';
import { SignupForm } from '../../toolympus/components/auth';
import { SignupMode, useSignup } from '../../toolympus/hooks/auth';
import { PublicPageWrapper } from '../Common/PublicPageWrapper';

export const SignupContainer = () => {
    const mode = SignupMode.Common;
    const signupData = useSignup({ signupApiPath: "/api/signup", mode: mode });

    return (
        <PublicPageWrapper>
            <SignupForm
                signupData={signupData}
                mode={mode}
                />
        </PublicPageWrapper>
    );
}