import React from "react";
import { Settings } from "@material-ui/icons";
import { Roles, RolesKeys } from "./components/Auth";

import { MenuRoute } from "./toolympus/components/frame";
import { SystemSubmenu } from "./toolympus/components/Contests/SystemSubmenu";
import { CmsEditor } from "./components/CmsEditor";

const isAdmin = (roles: string[] | undefined) => (roles || []).includes(RolesKeys.admin);


const InnerRoutes: MenuRoute[] = [
    { 
        path: '/system',
        title: 'System',
        icon: <Settings />,
        resolveHidden: isAdmin,
        component: () => (
            <SystemSubmenu
                emails={{ apiPath: "/emails" }}
                users={{ userRoles: Roles, allowPaswordReset: true, allowPaswordChange: true }}
                messages={{}}
                cms={{
                  apiPath: "/api/cms",
                  EditorComponent: CmsEditor,
                  includeMediaLib: true,
                }}
            />),
    },
];

export default InnerRoutes;