import { useState } from "react";
import { useHistory } from "react-router-dom";
import { apiFetch } from "../../api/core";
import { DefaultUser, login, useUser } from "../../userContext/UserContext";
import { SendData, useSendData } from "../useSendData";

export interface Credentials {
    login: string;
    password: string;
}

export interface Login extends SendData<Credentials, string> {
    isSecondFactorRequested: boolean;
    code: string;
    setCode: (v: string) => void;
    submitCode: () => void;
}

interface Config {
    redirectPathFromUser: (user: DefaultUser) => string;
}

interface SecondFactorState {
    session_code: string;
}

export const useLoginForm = (loginApiPath: string, redirectPath: string, config?: Config): Login => {
    const [secondFactor, setSecondFactor] = useState<SecondFactorState | null>(null);
    const [code, setCode] = useState<string>("");

    const history = useHistory();
    const user = useUser();
    
    const getTargetPath = config?.redirectPathFromUser || (() => redirectPath);

    const doLogin = (token: string) => {
        const userDecoded = login(user, token);
        history.push(getTargetPath(userDecoded));
    }

    const data = useSendData<Credentials, string>(loginApiPath, { login: "", password: "" }, {
        apiExtraParams: { noAuth: true },
        onSuccess: (result: string | SecondFactorState) => {
            if(typeof result === "string") {
                doLogin(result);
            } else {
                setSecondFactor(result);
            }
        },
    });

    const submitCode = () => {
        apiFetch<string>(`${loginApiPath}/2nd`, "post", { login: data.data.login, code, session_code: secondFactor?.session_code }, { noAuth: true })
            .then(token => doLogin(token));
    }

    return {
        ...data,

        code,
        setCode,
        isSecondFactorRequested: !!secondFactor,
        submitCode,

        send: secondFactor ? () => { submitCode(); return Promise.resolve(); } : () => data.send(),
    };
}