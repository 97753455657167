import { useCallback, useEffect, useMemo, useState } from "react";
import { apiFetch } from "../../api/core";
import { useAction } from "../../api/useAction";
import { Program, Trigger } from "./types";

export const useTriggers = (apiPath: string, program: Program) => {
    const apiPathTrigger = `${apiPath}/${program._id}/trigger`;

    const [triggersLocal, setTriggersLocal] = useState<Trigger[]>(program.triggers);
    const [isTriggerExecuting, setIsTriggerExecuting] = useState<boolean>(false);
    const [accumulatedChanges, setAccumulatedChanges] = useState<Record<number, Partial<Trigger>>>({});

    useEffect(() => {
        setTriggersLocal(program.triggers);
    }, [program.triggers]);

    useEffect(() => {
        if(Object.keys(accumulatedChanges).length > 0) {
            const timeout = setTimeout(
                () => {
                    Object.entries(accumulatedChanges).map(([id, changes]) => {
                        return apiFetch<Trigger>(`${apiPathTrigger}/${id}`, "put", changes)
                            .then(t => {
                                setTriggersLocal(ts => ts.map(tOld => tOld.trigger_id === t.trigger_id ? t : tOld));
                                setAccumulatedChanges(cs => {
                                    const updated = { ...cs };
                                    delete updated[id as any];
                                    return updated;
                                });
                            });
                    })
                },
                3000,
            );

            return () => clearTimeout(timeout);
        }
    }, [accumulatedChanges, apiPathTrigger]);

    const createTrigger = useCallback((t: Partial<Trigger>) => {
        return apiFetch<Trigger>(apiPathTrigger, "post", t)
            .then(t => setTriggersLocal(ts => [...ts, t]));
    }, [apiPathTrigger]);

    const addTrigger = () => {
        return createTrigger({ kind: "manual" });
    }

    const addTriggerAction = useAction(addTrigger, true);

    const updateTrigger = (id: number, changes: Partial<Trigger>) => {
        const changesX = { ...changes };
        if(changesX.kind && changesX.kind === "schedule" && !changesX.configuration) {
            changesX.configuration = { period: { unit: "d", qty: 1 }, calendar: {} };
        }
        setAccumulatedChanges(cs => ({ ...cs, [id]: { ...(cs[id] || {}), ...changesX }}));
        setTriggersLocal(ts => ts.map(tOld => tOld.trigger_id === id ? { ...tOld, ...changesX } : tOld));
    }

    const triggers = triggersLocal || [];

    const executeTrigger = (trigger: Trigger) => {
        setIsTriggerExecuting(true);
        return apiFetch(`${apiPathTrigger}/${trigger.trigger_id}/run`, "post", {})
            .then(() => {
                setIsTriggerExecuting(false);
            })
            .catch(e => {
                setIsTriggerExecuting(false);
                throw e;
            });
    }

    const removeTrigger = (trigger: Trigger) => {
        return apiFetch(`${apiPathTrigger}/${trigger.trigger_id}`, "delete", {})
            .then(() => {
                setTriggersLocal(ts => ts.filter(tOld => tOld.trigger_id !== trigger.trigger_id));
            });
    }

    const copyTriggerToClipboard = (trigger: Trigger) => {
        const triggerCopy = { ...trigger } as Partial<Trigger>;
        delete triggerCopy.trigger_id;
        delete triggerCopy.program_id;
        const text = JSON.stringify({ _tl_robud_trigger: triggerCopy});
        navigator.clipboard.writeText(text);
    }

    const pasteTriggerFromClipboard = useMemo(() => (e: ClipboardEvent) => {
            const clipboardText = e.clipboardData?.getData("text/plain") || "";
            if(clipboardText.includes("_tl_robud_trigger")) {
                const data = JSON.parse(clipboardText);
                const trigger = data._tl_robud_trigger as Partial<Trigger>;
                if(trigger) {
                    e.preventDefault();
                    e.stopPropagation();
                    createTrigger(trigger);
                }
            }
        },
        [createTrigger]);

    return {
        isLoading: !program.triggers,
        triggers,
        
        addTrigger: addTriggerAction.run,
        isAdding: addTriggerAction.isRunning,
        updateTrigger,
        hasUnsavedChanges: Object.keys(accumulatedChanges).length > 0,
        removeTrigger,

        activeCount: triggers.filter(t => t.is_active).length,
        executeTrigger,
        isTriggerExecuting,

        copyTriggerToClipboard,
        pasteTriggerFromClipboard,
    }
}


export type ProgramTriggersData = ReturnType<typeof useTriggers>;
