import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { SimpleButton } from '../primitives/Buttons';
import { IDefaultUser } from './typings';


interface Props<IUser extends IDefaultUser = IDefaultUser> {
    user: IUser | null;
    close: () => void;
    changePassword: (u: IUser, password: string) => void;
}

export const ChangePasswordPopup = <IUser extends IDefaultUser = IDefaultUser>(props: Props<IUser>) => {
    const { user, close, changePassword } = props;

    const [newPassword, setNewPassword] = useState<string>("");
    useEffect(() => {
        if(user) {
            setNewPassword("");
        }
    }, [user]);

    return (
        <Dialog open={!!user} onClose={close} maxWidth='md' fullWidth>
            <DialogTitle>
                <FormattedMessage id="userManagement.change_password_popup.title"/>
            </DialogTitle>
            <DialogContent>
                <TextField
                    label={<FormattedMessage id="userManagement.columns.email"/>}
                    margin="normal"
                    fullWidth
                    disabled
                    value={user ? user.email : ""}
                />
                <TextField
                    label={<FormattedMessage id="userManagement.change_password_popup.new_password"/>}
                    margin="normal"
                    fullWidth
                    value={newPassword}
                    onChange={(ev) => setNewPassword(ev.target.value || '')}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={close}><FormattedMessage id="common.cancel"/></Button>
                <SimpleButton
                    label={<FormattedMessage id="common.save"/>}
                    action={() => {
                        if(user) {
                            changePassword(user, newPassword);
                            close();
                        }
                    }}
                    />
            </DialogActions>
        </Dialog>
    );
}
