import React, { useState } from 'react';
import styled from '@emotion/styled';
import { withTheme } from '@material-ui/core';
import { FieldType, Schema, useSchema } from '../../hooks/useSchema';
import { FormWrapper, FormRow, FormColumn } from '../primitives/Forms';
import { FormControl } from '.';

const SchemaView = withTheme(styled.pre`
    width: 100%;
    background: ${props => props.theme.palette.grey[100]};
    padding: 2rem 1rem;
    font-family: 'Courier New', Courier, monospace;
`);


export const SchemasPage = () => {
    const [state, setState] = useState<{ selectedSchema?: string }>({ })
    const schemas = useSchema();
    const availableSchemas = Object.keys(schemas);
    const selectedSchema = state.selectedSchema && (schemas as unknown as { [k: string]: Schema })[state.selectedSchema];

    return (
        <FormWrapper>
            <FormRow>
                <FormColumn>
                    <FormControl
                        key="selectedSchema"
                        field="selectedSchema"
                        schema={{
                            label: "Схема",
                            type: FieldType.select,
                            valueDict: availableSchemas.reduce((result, s) => ({ ...result, [s]: s}), {}),
                            values: availableSchemas.map(s => ({ value: s, label: s}))}}
                        row={state}
                        onChange={(oldRow, changes) => setState({ ...oldRow, ...changes })}
                        />
                </FormColumn>
            </FormRow>
            <FormRow>
                {selectedSchema && (
                    <SchemaView>
                        {JSON.stringify(selectedSchema, undefined, 2)}
                    </SchemaView>
                )}
            </FormRow>
        </FormWrapper>
    );
}
