import { useState } from 'react';
import { apiFetch, apiUploadFile, downloadFile, FetchTypes } from '../../api/core';
import { openFileUploader } from '../../api/files';
import { useLoadedData } from '../../hooks/useLoadedData';
import { FieldType, Schema } from '../../hooks/useSchema';

export interface FileInfo {
    _id: string;
    kind: string;
    alias?: string | null;
    owner_id: string;
    filename: string;
    contenttype: string;
    meta?: Record<string, string>;
    url: string;
}

export type FileInfoUpdate = Record<string, any> & {
    alias?: string | null;
}

const processFile = (pathPrefix: string, fi: FileInfo) => ({
    ...fi,
    meta_text: JSON.stringify(fi.meta || {}, null, 2),
    url: fi.alias ? `${pathPrefix}/alias/${fi.alias}` : `${pathPrefix}/${fi._id}`,
});

export interface FileListData {
    filter: string;
    setFilter: (v: string) => void;

    view: string;
    setView: (_: any) => null,

    schema: Schema;

    data: FileInfo[];
    setData: (v: FileInfo[]) => void;
    isLoading: boolean;
    reload: () => void;

    replace: (fi: FileInfo) => Promise<FileInfo>;
    create: () => Promise<FileInfo>;
    remove: (fi: FileInfo) => Promise<void>;
    updateInfo: (fi: FileInfo, changes: FileInfoUpdate) => Promise<FileInfo>;
    download: (fi: FileInfo) => void;
    upload: (file: File) => Promise<FileInfo>;
}

export interface FileListConfig {
    noLoad?: boolean;
}

export const toUrlParams = (params?: Record<string, any>) => Object.entries(params || {}).map(([k,v]) => `${k}=${v}`).join("&");

export const useFileList = (filesApiPath: string, metaFilter?: Record<string, any>, cfg?: FileListConfig): FileListData => {
    const apiPathWithFilter = metaFilter ?
        `${filesApiPath}?${toUrlParams(metaFilter)}`
        : filesApiPath;

    const [filter, setFilter] = useState<string>("");
    const [prepFiles] = useState(() => (fis: FileInfo[]) => fis.map(fi => processFile(filesApiPath, fi)));
    const data = useLoadedData<FileInfo[]>(apiPathWithFilter, [], !cfg?.noLoad, prepFiles);
    const [isUploading, setIsUploading] = useState<boolean>(false);

    const upload = (file: File) => {
        setIsUploading(true);
        return apiUploadFile(apiPathWithFilter, FetchTypes.POST, "file", file)
            .then(f => {
                setIsUploading(false);
                data.reload();
                return f;
            })
            .catch(e => {
                setIsUploading(false);
                throw e;
            });
    }

    const create = () => {
        return new Promise<FileInfo>(resolve => {
            openFileUploader(file => {
                upload(file).then(f => resolve(f));
            });
        })
    }

    const replace = (fi: FileInfo) => {
        return new Promise<FileInfo>(resolve => {
            openFileUploader(file => {
                setIsUploading(true);
                apiUploadFile(`${filesApiPath}/${fi._id}`, FetchTypes.PUT, "file", file)
                    .then(f => {
                        data.reload();
                        setIsUploading(false);
                        resolve(f);
                    })
                    .catch(e => {
                        setIsUploading(false);
                        throw e;
                    });
            });
        })
    }

    const updateInfo = (fi: FileInfo, changes: FileInfoUpdate) => {
        return apiFetch<FileInfo>(`${filesApiPath}/${fi._id}/info`, FetchTypes.PUT, changes)
            .then(f => {
                data.reload();
                return f;
            });
    }

    const remove = (fi: FileInfo) => {
        return apiFetch(`${filesApiPath}/${fi._id}`, FetchTypes.DELETE)
            .then(() => data.reload());
    }

    const download = (fi: FileInfo) => {
        downloadFile(`${filesApiPath}/${fi._id}`, fi.filename);
    }

    const schema = {
        filename: { label_id: "files.filename", type: FieldType.text },
        contenttype: { label_id: "files.filetype", type: FieldType.text },
        meta_text: { label_id: "files.meta", type: FieldType.text },
        alias: { label_id: "files.alias", type: FieldType.text },
    }

    return {
        filter,
        setFilter,
        view: "",
        setView: (_: any) => null,
        schema,
        ...data,
        data: filter && filter.length > 0 ? data.data.filter(fi => `${fi.filename} ${fi.alias}`.toLowerCase().includes(filter.toLowerCase())) : data.data,
        isLoading: data.isLoading || isUploading,
        create,
        replace,
        remove,
        download,
        upload,
        updateInfo,
    }
}


export interface EditDialogProps {
    fileinfo: FileInfo | null;
    isOpen: boolean;
    open: (fi: FileInfo) => void;
    close: () => void;

    update: (changes: Partial<FileInfoUpdate>) => void;
    save: () => void;
    schema: Schema;
}

export const useFileInfoDialog = (filelist: FileListData): EditDialogProps => {
    const [fileinfo, setFileinfo] = useState<FileInfo | null>(null);

    return {
        fileinfo,
        isOpen: fileinfo !== null,
        open: setFileinfo,
        close: () => setFileinfo(null),

        update: changes => {
            if(fileinfo) {
                setFileinfo({ ...fileinfo, ...changes })
            }
        },
        save: () => {
            if(fileinfo) {
                filelist.updateInfo(fileinfo, { alias: fileinfo?.alias === "" ? null : fileinfo?.alias });
                setFileinfo(null);
            }
        },

        schema: filelist.schema,
    }
}
