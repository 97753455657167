import { useIntl } from "react-intl";
import { apiFetch } from "../../api/core";
import { useCrudUnpagedList } from "../../api/useSimpleCrud";
import { PowerDoc, PowerDocPageConfig, PowerDocSchema } from "./types";


export const usePowerDocList = (apiPath: string, config: PowerDocPageConfig) => {
    const { formatMessage } = useIntl();

    const data = useCrudUnpagedList<PowerDoc>(`${apiPath}`, {
        newItemPath: d => `${config.pathPrefix}/${d._id}`,
        newItem: {
            title: config.newDocumentTitle || formatMessage({ id: "powerdoc.pages.new_document_title" }) || "New document",
            entity: config.entity,
            record_id: config.record_id,
        },
        defaultView: config.view,
        filterBy: d => `${d.title} ${d._id}`,
    });

    const removeDocument = (id: string) => {
        apiFetch(`${apiPath}/${id}`, "delete")
            .then(() => data.reload());
    }

    return {
        ...data,
        schema: PowerDocSchema,
        removeDocument,
    };
}
