import React, { PropsWithChildren, useContext } from "react";
import { RenderLeafProps } from "slate-react";
import { PowerEditorBaseProps } from "./slate/PowerEditorConfig";
import { BlockHint, Suggestion } from "./PowerEditorSettingsSuggestions";

export type BlockSettingsByType = Record<string, string[]>

export const SuggestedBlockSettingsByType: BlockSettingsByType = {
    table: ["no_border"],
}

export interface PowerEditorSettings {
    EditorComponent?: React.ComponentType<PowerEditorBaseProps>;
    CustomLeaf?: (props: RenderLeafProps) => JSX.Element;
    noBlockSettings?: boolean;
    suggestedBlockSettings?: string[];
    suggestedBlockSettingsByType: BlockSettingsByType;
    suggestedBlockSettingValues?: Record<string, BlockSettingsByType>;
    settingsSuggestions?: Suggestion[];
    blockHints?: BlockHint[];
}

const DefaultSettings: PowerEditorSettings = {
    suggestedBlockSettingsByType: SuggestedBlockSettingsByType,
}

export const PowerEditorSettingsContext = React.createContext<PowerEditorSettings>(DefaultSettings);

export const PowerEditorSettingsProvider = (props: PropsWithChildren<Partial<PowerEditorSettings>>) => {
    const { children, ...settings } = props;

    return <PowerEditorSettingsContext.Provider value={{ ...DefaultSettings, ...settings }}>
        {children}
    </PowerEditorSettingsContext.Provider>
};

export const usePowerEditorSettings = () => useContext(PowerEditorSettingsContext);

