import { IconButton, Typography, withTheme } from '@material-ui/core';
import { DeleteOutlined } from '@material-ui/icons';
import React from 'react';
import styled from '@emotion/styled';
import { useItemWithControls } from '../../../api/useWithControls';
import { FieldType, mergeSchema } from '../../../hooks/useSchema';
import { FormControl } from '../../schemed';
import { ScoringSheetEditData } from './useScoringSheetEdit';
import { Buttons } from '../../primitives';

const Wrapper = withTheme(styled.div`
    border-bottom: 1px solid ${props => props.theme.palette.grey[200]};
    padding: 1rem 1rem;
    gap: 0.5rem 1rem;

    display: grid;
    grid-template-columns: 0.75rem 1fr 1fr 1fr max-content;
`);

interface Props {
    data: ScoringSheetEditData;
    idx: number;
}

export const CriteriaEdit = ({ data, idx }: Props) => {
    const item = data.data.criteria[idx];
    
    const isCustomType = !["number", "text", "bool", "dropdown"].includes(item.type);
    const schema = mergeSchema(data.criteriaSchema, isCustomType ? { type: { type: FieldType.text }} : {});

    const { controls } = useItemWithControls({ data: item, update: c => data.updateCriteria(idx, c) }, { schema });


    return (
        <Wrapper>
            <Typography>{idx+1}.</Typography>
            {controls([
                ["name", { wrapperStyle: { gridColumnStart: "span 2"}}],
                ["type"],
                ])}
            <IconButton size="small" onClick={() => data.removeCriteria(idx)}>
                <DeleteOutlined />
            </IconButton>

            <div />
            {controls([
                ["comment", { autoRows: true, wrapperStyle: { gridColumnStart: "span 2"}}]
                ])}

            <Buttons style={{ gridColumn: "span 2" }}>
              
              <FormControl
                  row={{ isCustomType }}
                  onChange={(o,c) => data.updateCriteria(idx, c.isCustomType ? { type: "custom" } : { type: "number" })}
                  schema={{ type: FieldType.bool, label_id: "contests.scoring.fields.criteria.custom" }}
                  field="isCustomType"
                  />
              {item.type === "text" && <FormControl
                  row={item}
                  onChange={(o,c) => data.updateCriteria(idx, { ...c })}
                  schema={{ type: FieldType.bool, label_id: "contests.scoring.fields.criteria.is_optional" }}
                  field="is_optional"
                  />}
            </Buttons>
            
            {item.type === "number" && <>
                <div />
                {controls([
                    ["min_value"],
                    ["max_value"],
                ])}
                
                <Buttons style={{ gridColumn: "span 2" }}>
                  <FormControl
                      row={{ useChips: item.display_settings?.control !== "input" }}
                      onChange={(o,c) => data.updateCriteria(idx, { display_settings: { ...item.display_settings, control: c.useChips ? null : "input" }})}
                      schema={{ type: FieldType.bool, label_id: "contests.scoring.fields.criteria.use_chips" }}
                      field="useChips"
                      />
                  <FormControl
                      row={item.display_settings || {}}
                      onChange={(o,c) => data.updateCriteria(idx, { display_settings: { ...item.display_settings, ...c }})}
                      schema={{ type: FieldType.bool, label_id: "contests.scoring.fields.criteria.reverse_values_order" }}
                      field="reverse_values_order"
                      />
                </Buttons>
                <div />
            </>}
        </Wrapper>
    );
}
