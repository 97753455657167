import { useSnackbar } from 'notistack';
import React, { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import styled from '@emotion/styled';

interface Props {
    value?: ReactNode | null;
    copyValue?: string | null;
    wrapper?: React.ComponentType<React.HTMLAttributes<HTMLSpanElement>>;
    suppressNotification?: boolean;
    notificationText?: ReactNode;
}

export const CopyTextWrapper = styled.span`
    padding-bottom: 2px;
    border-bottom: 1px dotted #d0d0d0;
    cursor: pointer;
`;

export const useCopyText = (cfg?: Pick<Props, "suppressNotification" | "notificationText">) => {
    const { enqueueSnackbar } = useSnackbar();
    const { formatMessage } = useIntl();

    const copy = (v: string) => {
        navigator.clipboard.writeText(v);

        if (!cfg?.suppressNotification) {
            enqueueSnackbar(cfg?.notificationText || formatMessage({ id: "success.clipboard" }), { variant: 'success', autoHideDuration: 2000 })
        }
    }

    return copy;
}

export const CopyText = (props: Props) => {
    const copyText = useCopyText(props);

    const Wrapper = props.wrapper || CopyTextWrapper;

    const copyValue = () => {
        copyText(props.copyValue || props.value?.toString() || "");
    }

    return (
        <Wrapper onClick={copyValue}>
            {props.value}
        </Wrapper>
    );
}
