import React from 'react';
import { CircularProgress, CircularProgressProps } from '@material-ui/core';

type SizeOption = 'font' | 's' | 'm' | 'l';

interface Props {
    sizeVariant?: SizeOption;
}

const sizes = {
    'font': '1em',
    's': 15,
    'm': 30,
    'l': 45,
}

export const LoadingIndicator = (props: CircularProgressProps & Props) => (
    <CircularProgress
        size={sizes[props.sizeVariant || 'm']}
        {...props}
        />
);
