import React, {  } from 'react';
import { MediaFile } from './useMediaLib';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { MediaLibBrowser } from './MediaLibBrowser';


interface DialogProps {
    selectedUrls: string[];
    selectFile: (mf: MediaFile, url: string) => void;
    unselectFile?: (mf: MediaFile, url: string) => void;
    isOpen: boolean;
    close: () => void;
    closeOnSelect?: boolean;
    noUpload?: boolean;
    allowFileActions?: boolean;
}

export const MediaLibPickerDialog = (props: DialogProps) => {
    const { selectedUrls, selectFile, unselectFile, isOpen, close, closeOnSelect, allowFileActions } = props;

    return (
        <Dialog open={isOpen} onClose={close} maxWidth="md" fullWidth>
            <DialogTitle>
                <FormattedMessage id="medialib.title" />
            </DialogTitle>
            <DialogContent>
                <MediaLibBrowser
                    minHeight='60vh'
                    noUpload={props.noUpload}
                    noActions={!allowFileActions}
                    selectedUrls={selectedUrls}
                    onFileClick={(mf, url) => {
                        if(selectedUrls.includes(url) && unselectFile) {
                            unselectFile(mf, url);
                        } else {
                            selectFile(mf, url);
                        }
                        if(closeOnSelect) {
                            close();
                        }
                    }}
                    />
            </DialogContent>
            <DialogActions>
                <Button onClick={close}><FormattedMessage id="common.close" /></Button>
            </DialogActions>
        </Dialog>
    )
}