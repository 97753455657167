import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { EditPageFormContainer } from './EditPageFormContainer';
import { PagesListContainer } from './PagesListContainer';

export interface PageManagementProps {
    apiPath: string;
    customComponentsDescription?: [string,string][];
}

export const PageManagementContainer = ({ apiPath, customComponentsDescription }: PageManagementProps) => {
    let { path } = useRouteMatch();

    return (
        <Switch>
            <Route exact path={path}>
                <PagesListContainer apiPath={apiPath} />
            </Route>
            <Route path={`${path}/:id`}>
                <EditPageFormContainer apiPath={apiPath} customComponentsDescription={customComponentsDescription} />
            </Route>
        </Switch>
    )
}
