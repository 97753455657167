export const RolesKeys = {
  admin: "admin",
  user: "user",
}


export const Roles = [
    { name: 'Admin', key: 'admin' },
    { name: 'User', key: 'user' },
]
