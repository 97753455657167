import React from 'react';
import { ResetPasswordForm } from '../../../toolympus/components/auth';
import { PublicPageWrapper } from '../../Common/PublicPageWrapper';

export const ResetPasswordContainer = () => {
    return (
        <PublicPageWrapper>
            <ResetPasswordForm restoreAPIPath="/api/restore-access/reset" />
        </PublicPageWrapper>
    );
}
