import React from 'react';
import styled from '@emotion/styled';
import { Button, Dialog, DialogActions, DialogContent, IconButton, withTheme } from '@material-ui/core';
import { Add, EditOutlined } from '@material-ui/icons';
import { ActionRow, OccupyFreeSpace } from '../primitives/ActionRow';
import { LoadingIndicator } from '../primitives/LoadingIndicator';
import { FieldDefinition, FormControlsForFields, TableForFields } from '../schemed';
import { DeleteButton } from '../primitives/DeleteButton';
import { FormattedMessage } from 'react-intl';
import { SimpleDocumentListData } from './useSimpleDocumentList';
import { PseudoLink } from '../primitives/Common.styles';
import { createFileDropHandler } from '../../api/files';
import { useDragTarget } from '../../hooks/useDragTarget';

interface Props {
    data: SimpleDocumentListData;
    disabled?: boolean;
}

const EditForm = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr max-content;
    grid-template-areas: 
        "filename replace"
        "comment comment";
`;

const TableWrapper = withTheme(styled.div<{ isDragTarget?: boolean }>`
    width: 100%;
    box-shadow: ${props => props.isDragTarget ? `0 0 5px 0 ${props.theme.palette.primary.main}` : "none"};

    & * {
        /* pointer-events: ${props => props.isDragTarget ? "none" : "auto"}; */
        /* pointer-events: auto; */
    }
`);

export const SimpleDocumentList = ({ data, disabled }: Props) => {
    const {
        documents,
        isLoading,
        download,
        create,
        upload,
        schema,
        editedRecord,
        startEditing,
        cancelEditing,
        replace,
        remove,
        saveEditing,
        update,
    } = data;

    const dragTarget = useDragTarget({ disabled, onDrop: createFileDropHandler(f => upload(f)) });


    const editedRecordControls = (fields: FieldDefinition[]) => (
        editedRecord &&
            <FormControlsForFields
                schema={schema}
                fields={fields}
                data={editedRecord}
                onChange={(o,c) => update(c)}
                withArea
                />
    )

    return (
        <>
            <ActionRow>
                {isLoading && <LoadingIndicator />}
                <OccupyFreeSpace />
                {!disabled && <IconButton size="small" color="primary" onClick={() => create()}><Add /></IconButton>}
            </ActionRow>
            <TableWrapper {...dragTarget}>
                <TableForFields
                    data={documents}
                    schema={schema}
                    fields={[
                        ["filename"],
                        ["comment"],
                    ]}
                    fieldElement={f => {
                        if(f === "filename") {
                            return (row, _, display) => <PseudoLink onClick={() => download(row)}>{display}</PseudoLink>;
                        } else {
                            return null;
                        }
                    }}
                    rowButtons={d => <IconButton size="small" onClick={() => startEditing(d)}><EditOutlined /></IconButton>}
                    emptyStateClick={() => !disabled && create()}
                    />
            </TableWrapper>
            <Dialog open={!!editedRecord} onClose={cancelEditing} fullWidth maxWidth="sm">
                <DialogContent>
                    <EditForm>
                        {editedRecordControls([
                            ["filename", { disabled: true }],
                        ])}
                        {!disabled && <Button
                            color="primary"
                            style={{ gridArea: 'replace'}}
                            onClick={() => editedRecord ? replace(editedRecord) : Promise.resolve({})}>
                            <FormattedMessage id="common.replace" />
                        </Button>}
                        {editedRecordControls([
                            ["comment", { disabled }],
                        ])}
                    </EditForm>

                    <DialogActions>
                        {!disabled && <DeleteButton
                            title={<FormattedMessage id="files.removeConfirmation" />}
                            preventGoBack
                            remove={() => editedRecord ? remove(editedRecord) : Promise.resolve({})}
                            button={<Button color="secondary"><FormattedMessage id="common.delete" /></Button>}
                            />}
                        
                        <Button variant="contained" color="primary" onClick={saveEditing}><FormattedMessage id="common.save" /></Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        </>
    );
}
