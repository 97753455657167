import React, { useState } from 'react';
import styled from '@emotion/styled';
import {
    useHistory,
} from "react-router-dom";
import { Divider, IconButton, ListItemIcon, Theme } from '@material-ui/core';
import { ExitToApp, Notifications as NotificationsIcon } from '@material-ui/icons';
import MenuCollapse from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { clearApiToken } from '../../../api/core';
import { useUser } from '../../../userContext/UserContext';
import { Notifications } from '../../Notifications/Notifications';
import { useNotificationsComponent } from '../../Notifications/useNotifications';

export const Grow = styled.div`
    flex-grow: 1;
`;

export const RibbonItems = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;

    & .item {
        padding: 5px;
        color: ${props => (props.theme as Theme).palette.primary.contrastText};
        border-radius: 5px;
    }

    & .MuiSvgIcon-root, & .MuiBadge-root {
        cursor: pointer;
    }

    & .sep {
        height: 20px;
        width: 0;
        border-left: 1px solid ${props => (props.theme as Theme).palette.primary.contrastText};
    }

    & .item, & .sec, & .MuiSvgIcon-root, & .MuiBadge-root {
        margin: 0 5px;
    }
`;



const isMobile = () => document.body.clientWidth <= 600;

const Ribbon: React.FC = () => {
    const [rolesMenuAnchor, setRolesMenuAnchor] = useState<HTMLElement | null>(null);

    const history = useHistory();
    const { user } = useUser();

    const logout = () => {
        clearApiToken();
        history.push('/login');
    }

    const notiesData = useNotificationsComponent();

    const { Badge, callOpen } = notiesData;
    

    return <>
        <Notifications data={notiesData}/>

        {isMobile() ? <>

            <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={e => setRolesMenuAnchor(e.currentTarget)}
                edge="start" color="inherit"
            >
                <Badge>
                    <MoreVertIcon />
                </Badge>
            </IconButton>
            
        
            <MenuCollapse 
                open={!!rolesMenuAnchor}
                anchorEl={rolesMenuAnchor}
                onClose={() => setRolesMenuAnchor(null)}
            >
                {user && <>
                    {user.roles.includes('team') && <MenuItem>Team {(user as any).teamnbr}</MenuItem>}
                    {user.roles.includes('arbiter') && <MenuItem>Arbiter</MenuItem>}
                    {user.roles.includes('organizer') && <MenuItem>Organizer</MenuItem>}

                    <MenuItem>Organizer</MenuItem>
                    
                    <Divider />
                    <MenuItem>{user.email}</MenuItem>
                </>}

                <MenuItem onClick={callOpen}>
                    <ListItemIcon>
                        <Badge>
                            <NotificationsIcon />
                        </Badge>
                    </ListItemIcon>
                    Notifications
                </MenuItem>
                

                <MenuItem>
                    <ListItemIcon onClick={logout}>
                        <ExitToApp />
                    </ListItemIcon>
                    Exit
                </MenuItem>
            </MenuCollapse>
        </>
        :
        <RibbonItems>
            {user && <>
                {user.roles.includes('team') && <span className="item">Team {(user as any).teamnbr}</span>}
                {user.roles.includes('arbiter') && <span className="item">Arbiter</span>}
                {user.roles.includes('organizer') && <span className="item">Organizer</span>}

                <span className="item">Organizer</span>

                <span className="sep"></span>
                
                <span className="item">{user.email}</span>

            </>}
            <div onClick={callOpen}>
                <Badge>
                    <NotificationsIcon />
                </Badge>
            </div>
            <ExitToApp onClick={logout} />
        </RibbonItems>
        }
        </>
    ;
}

export default Ribbon;
