import {
    Button,
    Dialog as Dlg,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Typography,
    Divider,
} from "@material-ui/core";
import React, { useState } from "react";
import styled from "@emotion/styled";
import { FieldType, Schema } from "../../../hooks/useSchema";
import { FormControlsForFields, PlaceholderField } from "../../schemed";
import { EmailItem } from "../types";
import { ShortText, Code, FileCopyOutlined, EmailOutlined, BatteryChargingFull, Battery20 } from '@material-ui/icons';
import { LoadingIndicator } from "../../primitives/LoadingIndicator";
import { FormattedMessage } from "react-intl";
import { FormGrid } from "../../primitives/Forms";
import { DeleteButton } from "../../primitives/DeleteButton";
import { ActionRow, Buttons, OccupyFreeSpace } from "../../primitives/ActionRow";
import { Tooltip } from "../../primitives/Tooltip";
import { Dialog as DialogX } from "../../primitives/Dialogs";
import { useEditItem2 } from "../../../api/useNewItem";
import { NotificationTemplateEditor } from "../../Notifications/NotificationTemplateEditor";

interface Props {
    data: {
        schema: Schema;
        email: EmailItem;
        isNew: boolean;
        onClose: (email?: EmailItem) => void;
        onChange: (email: EmailItem) => void;
        create: (body: EmailItem) => void;
        update: (body: EmailItem) => void;
        emailDelete: (code: string) => void;
        test: (code: string, data: string, to?: string) => void;
        preview: (code: string) => Promise<{ body_html: string }>;
        testLoading: boolean;
    }
}


const TestEmailSchema = {
  params: { type: FieldType.textlong, label_id: "emails.popup.testEmailTextTip" },
  to: { type: FieldType.text, label_id: "emails.popup.to" },
}

const SubjectRow = styled(ActionRow)`

  & .Mui-IconButton {
    margin-bottom: 0.25rem;
  }
`;
SubjectRow.defaultProps = { firstItemNoMargin: true, itemMarginTop: "0", alignItems: "end", lastItemMarginRight: "0" };

const Dialog = styled(Dlg)`
    .MuiDialogContent-root {
        min-height: 75vh;
    }
`;

export const Popup = (props: Props) => {
    const { email, schema, testLoading, onClose, onChange, create, update, emailDelete, test, preview, isNew } = props.data;
    const [showHtmlBody, setShowHtmlBody] = useState<boolean>(false);
    const [previewData, setPreviewData] = useState<{ body_html: string } | null>(null);

    const cloneEmail = useEditItem2<{ code: string }>({
      getApiPath: () => "",
      save: item => {
        props.data.create({
          ...email,
          ...item,
        });
        onClose();
        return Promise.resolve(email);
      }
    });

    const testEmail2 = useEditItem2<{ params: string, to?: string }>({
      getApiPath: () => "",
      save: item => {
        test(email.code, item.params, item.to);
        return Promise.resolve(item);
      },
    });

    const onChangeCtrl = (oldData: EmailItem, changes: Partial<EmailItem>) => {
        const data = { ...oldData, ...changes };
        onChange({ ...data, code: data.code.replace(/ /g, "-") })
    };

    return <>
        <Dialog onClose={() => onClose()} open fullWidth maxWidth="lg">
            <DialogTitle>
              <Buttons>
                <FormattedMessage id="emails.popup.title" />
                
                <OccupyFreeSpace />
                
                {testLoading && <LoadingIndicator sizeVariant="s" />}
                {!isNew &&
                  <Button size="small" color="primary" startIcon={<EmailOutlined />} onClick={() => testEmail2.startEditing({ params: "" })}>
                    <FormattedMessage id="emails.popup.testEmail" />
                  </Button>}
                {!isNew &&
                  <Button size="small" color="primary" startIcon={<FileCopyOutlined />} onClick={() => cloneEmail.startEditing({ code: "" })}>
                    <FormattedMessage id="common.copy" />
                  </Button>}
              </Buttons>
            </DialogTitle>
            <DialogContent>
                <FormGrid columns="1fr 2fr max-content" noMargin style={{ marginBottom: "1rem", alignItems: "end" }}>
                    <FormControlsForFields
                        fields={[
                            ['code', { disabled: !isNew }],
                            ['title'],
                            ['active'],
                            ['base_template'],
                            ['autowrap_html_body'],
                            [PlaceholderField],
                            ['description', { autoRows: true,  wrapperStyle: { gridColumn: "span 3" } }],
                        ]}
                        schema={schema}
                        data={email}
                        onChange={onChangeCtrl}
                    />

                </FormGrid>

                <Divider />
                
                <FormGrid columns="1fr">
                  <SubjectRow>
                    <FormControlsForFields
                      fields={[
                        ['subject', { wrapperStyle: { flex: "1 0 auto" }}],
                      ]}
                      schema={schema}
                      data={email}
                      onChange={onChangeCtrl}
                      />

                      {!email.is_powertemplate_content && <>
                        <Button size="small" onClick={() => { preview(email.code).then(x => setPreviewData(x)); }} >
                            <FormattedMessage id="emails.popup.preview_html" />
                        </Button>
                        <Tooltip key="text" text_id="emails.popup.textHint">
                            <IconButton size="small" color={showHtmlBody ? "default" : "primary"} onClick={() => setShowHtmlBody(false)}><ShortText /></IconButton>
                        </Tooltip>
                        <Tooltip key="html" text_id="emails.popup.htmlHint">
                            <IconButton size="small" color={showHtmlBody ? "primary" : "default"} onClick={() => setShowHtmlBody(true)}><Code /></IconButton>
                        </Tooltip>
                      </>}

                      <Tooltip key="text" text_id="emails.popup.powertemplate_hint">
                          <IconButton
                            size="small"
                            color={email.is_powertemplate_content ? "primary" : "default"}
                            onClick={() => onChangeCtrl(email, { is_powertemplate_content: !email.is_powertemplate_content })}>
                            {email.is_powertemplate_content ? <BatteryChargingFull /> : <Battery20 />}
                          </IconButton>
                      </Tooltip>
                    
                  </SubjectRow>

                  {!!email.is_powertemplate_content && <>
                      <Typography color="textSecondary" variant="caption" component="p"><FormattedMessage id="emails.fields.body" /></Typography>
                      <NotificationTemplateEditor
                        content={email.body_template}
                        update={v => onChangeCtrl(email, { body_template: v })}
                        />
                      </>}
                    
                  {!email.is_powertemplate_content && <FormControlsForFields
                      fields={[
                          ['subject'],
                          showHtmlBody ?
                              ['body_html', { autoRows: true }]
                              : ['body', { autoRows: true }],
                      ]}
                      schema={schema}
                      data={email}
                      onChange={onChangeCtrl}
                    />}

                </FormGrid>

            </DialogContent>
            <DialogActions>
                {!isNew && <DeleteButton
                    title={<FormattedMessage id="emails.popup.delete"/>}
                    remove={() => { emailDelete(email.code); return Promise.resolve(); }}
                    preventGoBack
                    button={<Button color="secondary"><FormattedMessage id="common.delete"/></Button>}
                    />}
                <Button color="primary" onClick={() => onClose()}><FormattedMessage id="common.cancel"/></Button>
                <Button color="primary" variant="contained" onClick={() => isNew ? create(email) : update(email)}><FormattedMessage id="common.save"/></Button>
            </DialogActions>
        </Dialog>

        <DialogX
            isOpen={previewData !== null}
            close={() => setPreviewData(null)}
            actions={<Button onClick={() => setPreviewData(null)}><FormattedMessage id="common.close" /></Button>}
            dialogTitle={<FormattedMessage id="emails.popup.preview_html" />}
            >
                {previewData?.body_html && <div dangerouslySetInnerHTML={{ __html: previewData.body_html }} />}
        </DialogX>

        <DialogX
          isOpen={cloneEmail.isEditing}
          close={() => cloneEmail.cancel()}
          actions={<Button color="primary" onClick={() => cloneEmail.save()}><FormattedMessage id="common.save" /></Button>}
          dialogTitle={<FormattedMessage id="common.copy" />}
          noFullscreen
          >
            <FormGrid columns="1fr">
              <FormControlsForFields
                fields={[
                    ['code'],
                ]}
                schema={schema}
                data={cloneEmail.item}
                onChange={(o,c) => cloneEmail.update(c)}
              />
            </FormGrid>
        </DialogX>

        <DialogX
          isOpen={testEmail2.isEditing}
          close={() => testEmail2.cancel()}
          actions={<>
            <Button onClick={() => testEmail2.cancel()}><FormattedMessage id="common.cancel" /></Button>
            <Button color="primary" onClick={() => testEmail2.save()}><FormattedMessage id="emails.popup.send"/></Button>
          </>}
          dialogTitle={<FormattedMessage id="emails.popup.testEmail"/>}
          noFullscreen
          >
            <FormGrid columns="1fr" noMargin>
              <FormControlsForFields
                fields={[
                    ["params", { autoRows: true }],
                    ["to"],
                ]}
                schema={TestEmailSchema}
                data={testEmail2.item}
                onChange={(o,c) => testEmail2.update(c)}
              />
            </FormGrid>
        </DialogX>
    </>
}
