import { useEffect } from "react";
import { useHistory } from "react-router-dom";

export const usePromptNavigationBlocker = (condition: boolean, promptMessage: string) => {
    const history = useHistory();

    useEffect(() => {
        if(condition) {
            const unblock = history.block(promptMessage);
            return () => {
              unblock();
            };
        }
      }, [condition, promptMessage, history]);
}
