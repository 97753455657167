import React from 'react';
import { IUserRole } from '../typings';
import { ApiTokensManagementForm } from './ApiTokensManagementForm';
import { useAPITokensManagement } from './useApiTokensManagement';

export interface APITokensManagementProps {
  apiPath: string;
  roles: IUserRole[];
}

export const APITokensManagementContainer = (props: APITokensManagementProps) => {
  const data = useAPITokensManagement(props.apiPath);
  return <ApiTokensManagementForm data={data} roles={props.roles} />;
}
