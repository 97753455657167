import React, { useMemo } from 'react';
import { useParams, useRouteMatch } from 'react-router-dom';
import { Routes } from '../frame';
import { RecordFormPage } from './Forms/RecordFormPage';
import { RecordsListPage, RecordsListPageProps } from './Lists/RecordsListPage';

interface Props extends RecordsListPageProps {
}

export const SingleRecordTypePages = (props: Props) => {
    let { path } = useRouteMatch();
    return (
        <Routes
            routes={[
                { path: `${path}/:id`, component: RecordFormPage, componentProps: props },
                { path: `${path}/`, component: RecordsListPage, componentProps: props },
            ]}
        />
    );
}

export const SingleRecordTypePagesMultiple = (props: Omit<Props, "recordType">) => {
    const { record_type } = useParams<{ record_type: string }>();
    let { path } = useRouteMatch();

    const childProps = useMemo(() => {
        return {
            apiPath: props.apiPath,
            recordType: record_type,
        }
    }, [record_type, props.apiPath])

    return (
        <Routes
            routes={[
                { path: `${path}/:id`, component: RecordFormPage, componentProps: childProps },
                { path: `${path}/`, component: RecordsListPage, componentProps: childProps },
            ]}
        />
    );
}