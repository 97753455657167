import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";

export const useQueryParamsState = <T extends Object>(defaultState: T): [T, (v: T) => void] => {
    const history = useHistory();
    const query = new URLSearchParams(useLocation().search);

    const state: any = {};

    for (const key in defaultState) {
        if (Object.prototype.hasOwnProperty.call(defaultState, key)) {
            state[key] = query.get(key) || defaultState[key];
        }
    }

    return [
        state, 
        (state: T) => {
            const params: string[] = [];
    
            for (const key in state) {
                if (!!state[key] && Object.prototype.hasOwnProperty.call(state, key)) {
                    params.push(`${key}=${state[key]}`);
                }
            }
    
            history.replace('?' + params.join('&'));
        }
    ];
}

export const useQuery = () => new URLSearchParams(useLocation().search);

interface QueryParams<T> {
    params: T;
    update: (p: Partial<T>) => void;
}

export const useQueryParams = <T,>(dflt: T): QueryParams<T> => {
    const history = useHistory();
    const urlp = new URLSearchParams(history.location.search)
    
    const params = Object.keys(dflt).reduce((r,k) => ({ ...r, [k]: urlp.get(k) || (dflt as any)[k] }), {}) as T;

    const update = (newVals: Partial<T>) => {
        const updated = {
            ...params,
            ...newVals,
        }

        Object.keys(updated).forEach(k => { urlp.set(k, (updated as any)[k]) });
        
        history.replace({ pathname: history.location.pathname, search: `?${urlp.toString()}` });
    }

    return {
        params,
        update,
    }

}
