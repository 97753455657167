import React from 'react';
import styled from '@emotion/styled';
import { FormGrid } from '../../../primitives';
import { DefaultColumns, WithTiles } from './useTiles';
import { PossiblyProtectedImg } from '../../../medialib';
import { Link } from 'react-router-dom';
import { Typography, withTheme } from '@material-ui/core';
import { PowerEditorBase } from '../../slate/PowerEditorBase';
import { usePowerEditorSettings } from '../../PowerEditorSettingsContext';

const Tile = withTheme(styled(Link)`
    text-decoration: none;
    color: inherit;
    display: flex;
    flex-flow: row;
    align-items: center;
    width: 100%;
    gap: 2rem;
    padding: 0.5rem;
    height: 100%;

    box-shadow: 0 0 10px -5px #00000020;

    & > img {
        width: 150px;
        height: auto;
    }

    & h6 {
        color: ${props => props.theme.palette.primary.main};
    }
`);

export const TilesBlockView = ({ tiles, columns }: Partial<WithTiles>) => {
    const settings = usePowerEditorSettings();
    const EditorComponent = settings.EditorComponent || PowerEditorBase;

    return (
        <FormGrid columns={`repeat(${columns || DefaultColumns}, 1fr)`} forceEvenColumns>
            {(tiles || []).map(tile => (
                <Tile to={tile.link || ""} className={tile._class || undefined}>
                    {tile.image_url && <PossiblyProtectedImg src={tile.image_url} />}

                    <div>
                        {tile.title && <Typography variant="h6">{tile.title}</Typography>}
                        {tile.content && <EditorComponent content={tile.content} update={() => {}} viewMode />}
                    </div>
                </Tile>
            ))}            
        </FormGrid>
    );
}
