import { useState } from "react";
import { Transforms } from "slate";
import { ReactEditor, useSlateStatic } from "slate-react";
import { CustomElement } from "../../../../slate";

interface Config<DataT> {
  getElementState: (e: CustomElement) => DataT;
}

export const useBlockEdit = <DataT>(element: CustomElement, cfg: Config<DataT>) => {
    const editor = useSlateStatic();
    const path = ReactEditor.findPath(editor, element);

    const [localState, setLocalState] = useState<DataT>(cfg.getElementState(element));


    const update = (changes: Partial<DataT>) => {
      const updated = { ...localState, ...changes };
      setLocalState(updated);
        Transforms.setNodes(
            editor,
            updated as any,
            { at: path },
        );
    }

    return {
        ...localState,
        update,
    }
}
