import React, { PropsWithChildren, useContext } from "react";

export interface ICMSRecordContext {
    lang?: string | null;
}

const CMSRecordContext = React.createContext<ICMSRecordContext>({ });

export const useCMSRecordContext = () => useContext(CMSRecordContext);

export const CMSRecordContextProvider = (props: PropsWithChildren<ICMSRecordContext>) => {
    const { children, ...otherProps } = props;

    return <CMSRecordContext.Provider value={otherProps}>
        {children}
    </CMSRecordContext.Provider>
}
