import React from 'react';
import styled from '@emotion/styled';
import { Button } from '@material-ui/core';
import { Videocam } from '@material-ui/icons';
import { Component } from '../usePages';
import { FormattedMessage } from 'react-intl';

const Wrapper = styled.div`
    display: flex;
    flex-flow: column;
    align-items: center;
    padding: 1rem 0;
`;

export const ConferenceLink = (c: Component) => {
    const [url,title] = c.content_text.split('\n')
        .map(s => s.trim())
        .filter(s => s.length > 0);

    return (
        <Wrapper className="page-component conference">
            <Button href={url} target="_blank" rel="noreferrer noopener" color="primary" variant="contained" startIcon={<Videocam />}>
                {title || <FormattedMessage id="contests.join_conference" />}
            </Button>
        </Wrapper>
    );
}
