import { useHistory, useLocation } from "react-router-dom"
import { useQuery } from "../useQueryParamsState";

interface ReturnUrl {
    fromParam: string | null;
    toParam: string;
    redirectWithReturn: (to: string) => void;
    return: () => void;
}

export const useReturnUrl = (paramKey?: string): ReturnUrl => {
    const k = paramKey || 'return_url';
    const location = useLocation();
    const query = useQuery();
    const history = useHistory();

    const v = query.get(k);
    const target = encodeURIComponent(`${location.pathname}${location.search}`);
    const toParam = location.search.includes(k) ? '' : `${k}=${target}`;
    const fromParam = v ? decodeURIComponent(v) : v;

    return {
        fromParam,
        toParam,
        redirectWithReturn: to => history.push(`${to}${to.includes('?') ? '&' : '?'}${toParam}`),
        return: () => fromParam ? history.push(fromParam) : null,
    }
}
