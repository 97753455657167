import ical, { ICalCalendarMethod } from "ical-generator";
import { today } from "../../../api/datetimeUtil";
import { useEditItem2 } from "../../../api/useNewItem"
import { CalendarEvent } from "./calendar.events.types"

export const getEmptyCalendarEvent = (): CalendarEvent => ({
  start: today(),
  end: today(),
  description: "",
  id: Math.floor(1e8+Math.random()*1e8),
  location: "",
  summary: "",
  organizer_name: "",
  organizer_email: "",
})

export const createIcal = (event: CalendarEvent) => {
  const cal = ical({ name: event.summary, method: ICalCalendarMethod.PUBLISH });
  cal.createEvent({
    start: event.start,
    end: event.end,
    summary: event.summary,
    id: event.id,
    timezone: event.timezone || undefined,
    description: event.description || "",
    location: event.location || "",
    organizer: {
      name: event.organizer_name || "",
      email: event.organizer_email,
    },
  });
  return cal;
}

export const useCalendarEventCreate = (save: (event: CalendarEvent) => Promise<any>) => {
  const data = useEditItem2<CalendarEvent>({
    getApiPath: () => "",
    save: (item, changes) => {
      return save(item).then(() => Promise.resolve(item));
    }
  });

  const getEmptyEvent = () => getEmptyCalendarEvent();

  return {
    ...data,
    getEmptyEvent,
    startEditingNew: () => data.startEditing(getEmptyEvent()),
    createIcal: createIcal,
  }
}

export type CalendarEventCreateData = ReturnType<typeof useCalendarEventCreate>;
