import isHotkey, { KeyboardEventLike } from "is-hotkey"
import { useEffect } from "react";

interface HotkeyConfig {
    stopPropagation?: boolean;
    noPreventDefault?: boolean;
}

interface KeyboardEventX extends KeyboardEventLike {
    preventDefault: () => void;
    stopPropagation: () => void;
}

export const createHotkeyHandler = (hotkey: string, handler: () => void, cfg?: HotkeyConfig) => {
    return (e: KeyboardEventX) => {
        if(isHotkey(hotkey, e)) {
            handler();
            if(!cfg?.noPreventDefault) {
                e.preventDefault();
            }
            if(cfg?.stopPropagation) {
                e.stopPropagation();
            }
        }
    }
}

export const translateHotkey = (hotkey?: string) => {
    return hotkey
        ? hotkey.replace("mod", navigator.platform.toUpperCase().indexOf('MAC') > -1 ? "cmd" : "ctrl")
        : hotkey;
}

export const useWindowHotkey = (hotkey: string, handler: () => void, cfg?: HotkeyConfig) => {
    useEffect(() => {
        const realHandler = createHotkeyHandler(hotkey, handler, cfg);
        window.addEventListener("keydown", realHandler);
        return () => window.removeEventListener("keydown", realHandler);
    }, [hotkey, handler, cfg]);
}
