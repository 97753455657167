import { Descendant } from "slate";
import { FieldType, Schema } from "../../hooks/useSchema";

export interface Content {
    blocks?: Descendant[];
}

export interface PowerDoc {
    _id: string;
    title: string;
    content: Content;
    entity?: string;
    record_id?: string;
}

export interface PowerDocPageConfig {
    apiPath: string;
    pathPrefix: string;
    entity?: string;
    record_id?: string;
    view?: string;
    newDocumentTitle?: string;
}


export const PowerDocSchema: Schema = {
    title: { label: "Title", label_id: "powerdoc.fields.title" },
    created_datetime: { type: FieldType.datetime, label: "Created", label_id: "powerdoc.fields.created_datetime" },
}
