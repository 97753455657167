import { useEffect } from "react";
import { apiFetch } from "../../../api/core";
import { DateTimeInternalFormat } from "../../../api/datetimeUtil";
import { useCrudItem } from "../../../api/useSimpleCrud";
import { useLocalizedRecord } from "../../LocalizedRecords";
import { utc } from "../../timezone";
import { CMSRecord } from "../types";
import { useRecordTypeConfig } from "../useRecordTypeConfig";
import { useRecordTranslationLinks } from "./useRecordTranslationLinks";

export const useRecordEdit = (apiPath: string, recordType: string, recordId: number) => {
    const config = useRecordTypeConfig(apiPath, recordType);

    const data = useCrudItem<CMSRecord>(`${apiPath}/manage/${recordType}/${recordId}`, {
        defaultValue: {
            _id: -1,
            record_type: recordType,
            title: "",
            created_datetime: "",
        },
    });

    const localizer = useLocalizedRecord<CMSRecord>(data.data, {
        defaultLocale: config.data.languages?.length ? config.data.languages[0] : "",
        updateItem: (o,c) => data.update(c),
        translationsField: "translations",
        translatedFields: ["title", "content", "attributes"],
    });

    
    useEffect(() => {
        if(!localizer.locale && config.data.languages?.length && config.data["translations-in-place"]) {
            localizer.setLocale(config.data.languages[0]);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localizer.locale, localizer.setLocale, config])

    const translationLinks = useRecordTranslationLinks({
        apiPath,
        config: config.data,
        record: data.data,
        onUpdatedLinks: (links) => data.reload(),
    });

    const publish = () => {
        if(!data.data.published_datetime) {
            data.save({ published_datetime: utc.toServer(new Date()).format(DateTimeInternalFormat) });
        }
    }

    const unpublish = () => {
        if(data.data.published_datetime) {
            data.save({ published_datetime: null });
        }
    }

    const copyItem = () => {
        return apiFetch<CMSRecord>(
            `${apiPath}/manage/${recordType}`,
            "post",
            {
                title: `${data.data.title} (copy)`,
                slug: data.data.slug ? `${data.data.slug}-copy` : null,
                content: data.data.content,
                lang: data.data.lang,
                attributes: data.data.attributes,
                record_datetime: data.data.record_datetime,
                translations: data.data.translations,
            });
    }

    const updateAttributes = (changes: CMSRecord["attributes"]) => {
        if(config.data["translations-in-place"]) {
            localizer.updateItem(localizer.item, { attributes: { ...(localizer.item.attributes || {}), ...(changes || {}) }})
        } else {
            data.update({ attributes: { ...(data.data.attributes || {}), ...(changes || {}) }});
        }
    }

    return {
        ...data,
        data: config.data["translations-in-place"] ? localizer.item : data.data,
        update: config.data["translations-in-place"] ? (c: Partial<CMSRecord>) => localizer.updateItem(data.data, c) : data.update,

        config,
        lang: localizer.locale,
        setLang: localizer.setLocale,

        publish,
        unpublish,

        copyItem,

        updateAttributes,
        translationLinks,

        isLoading: config.isLoading || data.isLoading,
    };
}

export type RecordEditData = ReturnType<typeof useRecordEdit>;
