import { useIntl } from "react-intl";
import { useWindowHotkey } from "./hotkeys";
import { usePromptNavigationBlocker } from "./usePromptNavigationBlocker";

export interface Saveable {
    hasChanges: boolean;
    save: () => void;
    saveHotkey?: string;
    confirmationMessage?: string;
    confirmationMessageId?: string;
}

export const useSaveable = (config: Saveable) => {
    const { formatMessage } = useIntl();

    const confirmationMessage = config.confirmationMessageId
        ? formatMessage({ id: config.confirmationMessageId })
        : config.confirmationMessage || formatMessage({ id: "common.unsaved_changes_prompt" });

    useWindowHotkey(config.saveHotkey || "alt+s", config.save);
    usePromptNavigationBlocker(config.hasChanges, confirmationMessage);
}