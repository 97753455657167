import { apiFetch, FetchTypes } from "../../api/core";
import { CrudItemData, CrudListData, useCrudItem, useCrudUnpagedList } from "../../api/useSimpleCrud";
import { FieldType, mergeSchema, Schema, useSingleSchema } from "../../hooks/useSchema";

export interface Page {
    _id: string;
    slug: string;
    language: string;
    styles: string;
    properties?: Object;
}

export interface Component {
    _id: string;
    page_id: string | null;
    content_text: string;
    comp_order: number;
    subtype_text: string;
    properties: string;
}

export interface PageWithComponents extends Page {
    components: Component[];
}

export interface PagesData {
    pages: CrudListData<Page>;
    schema: Schema;
    savePage: (body: Page) => Promise<void>;
    deletePage: (id: string) => Promise<void>;
    apiPath: string;
}

const usePageSchema = (apiPath: string) => {
    const s = useSingleSchema(`${apiPath}/uiconfig`);
    return mergeSchema(s.schema, { properties: { type: FieldType.json} });
}

export const usePages = (pagesAPIPath: string): PagesData => {
    const apiPath = `${pagesAPIPath}/edit`;

    const pagesData = useCrudUnpagedList<Page>(apiPath, {
        newItem: { slug: "defaultslug" },
        filterBy: p => p.slug,
    });

    const schema = usePageSchema(apiPath);

    const save = (page: Page) => {
        return apiFetch(`${apiPath}/${page._id}`, FetchTypes.PUT, { ...page, _id: undefined })
            .then(pagesData.reload);
    }

    const remove = (id: string) => {
        return apiFetch(`${apiPath}/${id}`, FetchTypes.DELETE)
            .then(pagesData.reload);
    }

    return {
        pages: pagesData,
        schema,
        savePage: save,
        deletePage: remove,
        apiPath: pagesAPIPath,
    }
}

export interface ComponentData {
    components: CrudListData<Component>;
    schema: Schema;
    saveComponent: (body: Component) => Promise<void>;
    deleteComponent: (id: string) => Promise<void>;
}

const useComponents = (pagesAPIPath: string, pageId: string | null): ComponentData => {
    const apiPath = `${pagesAPIPath}/edit/${pageId}/component`;

    const componentData = useCrudUnpagedList<Component>(apiPath, { newItem: { comp_order: 0, page_id: pageId }, defaultView: 'by-id' });
    const { schema } = useSingleSchema(`${apiPath}/uiconfig`);

    const save = (component: Component) => {
        return apiFetch(`${apiPath}/${component._id}`, FetchTypes.PUT, { ...component, _id: undefined })
            .then(componentData.reload);
    }

    const remove = (id: string) => {
        return apiFetch(`${apiPath}/${id}`, FetchTypes.DELETE).then(componentData.reload);
    }

    return {
        components: componentData,
        schema,
        saveComponent: save,
        deleteComponent: remove
    }
}

export type EditPageData = CrudItemData<Page> & {
    schema: Schema;
    components: ComponentData;
}

export const useEditPage = (apiPath: string, id: string): EditPageData => {
    const apiEditPath = `${apiPath}/edit`;
    const schema = usePageSchema(apiEditPath);

    const data = useCrudItem<Page>(`${apiEditPath}/${id}`, {
        defaultValue: { _id: "", slug: "", language: "", styles: "", },
    });

    const components = useComponents(apiPath, id);

    return {
        ...data,
        schema,
        components,
    };
}