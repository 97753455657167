import React, { ReactNode } from 'react';
import VisibilitySensor from 'react-visibility-sensor';

interface Config {
    onBecameVisible: () => void;
    isDisabled?: boolean;
}

interface VisilibilityTrigger {
    anchor: ReactNode;
}

export const useVisibilityTrigger = (config: Config): VisilibilityTrigger => {
    const { onBecameVisible, isDisabled } = config;

    const anchor = !isDisabled ? (
        <VisibilitySensor onChange={(isVisible) => {
            if(isVisible) {
                onBecameVisible();
            }}}>
            <div className="visibility-trigger" style={{ height: "1px", width: "100%" }} />
        </VisibilitySensor>)
        : null;

    return {
        anchor,
    }
}