import React from 'react';
import { ViewDay } from "@material-ui/icons";
import { CustomElement } from "../../../../../slate";
import { EditorPlugin } from "../../slate/PowerEditorConfig";
import { createWithVoidInjector } from "../common";
import { PanelBlockElement, PanelBlockElementType } from './PanelBlockElement';

const withPanel = createWithVoidInjector(PanelBlockElementType);

export const PanelPlugin: EditorPlugin = {
    key: "panel-block",
    inject: withPanel,
    commands: [{
        name: "insert-panel-block",
        menu: { section: "insert-item", label: "Panel", label_id: "powerdoc.plugins.panel.title", icon: <ViewDay /> },
        invoke: editor => {
            editor.insertNode({
                type: PanelBlockElementType,
                children: [{ text: "", }],
            } as CustomElement);
        }
    }],
    customBlocks: { [PanelBlockElementType]: PanelBlockElement },
};
