import React, { useState } from 'react';
import styled from '@emotion/styled';
import { ReactEditor, RenderElementProps, useSelected, useSlateStatic } from 'slate-react';
import { FormGrid } from '../../../primitives';
import { IconButton, TextField, withTheme } from '@material-ui/core';
import { Add, DeleteOutlined } from '@material-ui/icons';
import { Editor, Transforms } from 'slate';
import { CustomElement } from '../../../../../slate';
import { EditorPlugin } from '../../slate/PowerEditorConfig';
import { useIntl } from 'react-intl';
import { usePowerEditorContext } from '../../slate/PowerEditorContext';
import { BlockSelectionCss, Buttons } from '../../elements/Common';
import isHotkey from 'is-hotkey';
import { generateCode } from '../common';

export const CustomFieldsBlockElementType = "custom_fields_block";

interface Field {
    label: string;
    value: string;
}

interface WithFields {
    fieldsCodes: string[];
    fields: Record<string, Field>;
}

const Wrapper = withTheme(styled.div<{ isSelected?: boolean }>`
    padding: 0.5rem 1rem;
    border-top: 1px solid #eeeeee;
    border-bottom: 1px solid #eeeeee;
    ${props => BlockSelectionCss(props)}
`);

export const extractCustomBlockFields = (block: RenderElementProps["element"]) => {
  const fields = Object.values((block as any).fields || {}) as { label: string; value: string }[];
  return fields.reduce<Record<string, string>>((r, { label, value }) => { r[label] = value; return r; }, {});
}


const useCustomFields = (element: Partial<WithFields> & CustomElement) => {
    const editor = useSlateStatic();
    const [insertedCode, setInsertedCode] = useState<string>("");
    
    // local state is needed to help react keep track of control state with the async updates by Slate/Transforms
    const [localState, setLocalState] = useState<WithFields>({
        fieldsCodes: element.fieldsCodes || [],
        fields: element.fields || {},
    });
    
    const codes = localState.fieldsCodes;
    const fields = localState.fields;

    const path = ReactEditor.findPath(editor, element);

    const updateState = (updated: WithFields) => {
        setLocalState(updated);
        Transforms.setNodes(
            editor,
            updated as any,
            { at: path },
        );
    }

    const insertField = () => {
        const newCode = generateCode();
        setInsertedCode(newCode);
        updateState({
            fieldsCodes: [ ...codes, newCode],
            fields: { ...fields, [newCode]: { label: "", value: "" }},
        })
    };

    const updateField = (code: string, changes: Partial<Field>) => {
        updateState({
            fields: { ...fields, [code]: { ...(fields[code]), ...changes }},
            fieldsCodes: localState.fieldsCodes,
        });
    }

    const removeField = (code: string) => {
        const filteredCodes = codes.filter(c => c !== code);
        updateState({
            fieldsCodes: filteredCodes,
            fields: filteredCodes.reduce<Record<string, Field>>((r, c) => {
                if(c !== code) {
                    r[c] = fields[c];
                } 
                return r;
            }, {}),
        });
    }

    return {
        codes: localState.fieldsCodes,
        fields: localState.fields,
        insertField,
        updateField,
        removeField,
        insertedCode,
    }
}

export const CustomFieldsBlockElement = (props: RenderElementProps) => {
    const { element, attributes, children } = props;
    
    const {
        codes,
        fields,
        insertField,
        updateField,
        removeField,
        insertedCode,
    } = useCustomFields(element as Partial<WithFields> & CustomElement);
    const isSelected = useSelected();

    const { formatMessage } = useIntl();
    const { viewMode } = usePowerEditorContext();

    return (
        <Wrapper {...attributes} isSelected={isSelected}>
            {children}
            <FormGrid columns="1fr 1fr max-content" forceEvenColumns contentEditable={false}>
                {codes.map(f => (
                    <React.Fragment key={f}>
                        <TextField
                            key="label"
                            value={fields[f]?.label || ""}
                            onChange={e => updateField(f, { label: e.target.value })}
                            label=""
                            placeholder={formatMessage({ id: "powerdoc.plugins.custom_fields_block.field.label"})}
                            autoFocus={f === insertedCode}
                            InputProps={{ readOnly: viewMode }}
                            />
                        <TextField
                            key="value"
                            value={fields[f]?.value || ""}
                            onChange={e => updateField(f, { value: e.target.value })}
                            label=""
                            placeholder={formatMessage({ id: "powerdoc.plugins.custom_fields_block.field.value"})}
                            multiline
                            onKeyDown={e => {
                                if(isHotkey("mod+Enter", e)) {
                                    insertField();
                                    e.preventDefault();
                                }
                            }}
                            InputProps={{ readOnly: viewMode }}
                            />
                        <IconButton size="small" onClick={() => removeField(f)}><DeleteOutlined /></IconButton>
                    </React.Fragment>
                ))}
            </FormGrid>
            <Buttons>
                <IconButton size="small" onClick={() => insertField()}><Add /></IconButton>
            </Buttons>
        </Wrapper>
    );
}

const withCustomFieldsBlock = (editor: Editor) => {
    const { isVoid } = editor;
    editor.isVoid = (element: CustomElement) => {
        return element.type === CustomFieldsBlockElementType ? true : isVoid(element);
    }
    
    return editor;
}

export const CustomFieldsBlockPlugin: EditorPlugin = {
    key: "custom-fields-block",
    commands: [{
        name: "insert-custom-fields-block",
        menu: { section: "insert-item", label: "Custom fields", label_id: "powerdoc.plugins.custom_fields_block.title" },
        invoke: editor => {
            const firstFieldCode = generateCode();
            editor.insertNode({
                type: CustomFieldsBlockElementType,
                fieldsCodes: [firstFieldCode],
                fields: { [firstFieldCode]: { label: "", value: "" }},
                children: [{ text: "", }],
            } as CustomElement);
        }
    }],
    customBlocks: { [CustomFieldsBlockElementType]: CustomFieldsBlockElement },
    inject: e => withCustomFieldsBlock(e),
};
