import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ConfirmationDialogProvider } from '../primitives/ConfirmationDialog';
import { Form } from '../primitives/Forms';
import { MediaLibBrowser } from './MediaLibBrowser';
import { MediaLibProvider, MediaLibProviderProps } from './MediaLibContext';

interface Props {
}

export const MediaLibForm = () => {
    return (
        <ConfirmationDialogProvider>
            <Form
                title={<FormattedMessage id="medialib.title" />}
                >
                <MediaLibBrowser minHeight="70vh" />
            </Form>
        </ConfirmationDialogProvider>
    );
}

export const MediaLibContainer = (props: Omit<MediaLibProviderProps, "children">) => {
    return <MediaLibProvider {...props}><MediaLibForm /></MediaLibProvider>;
}
