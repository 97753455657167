import { LabelOutlined } from "@material-ui/icons";
import React, { useMemo, useState } from "react";
import { Editor, Transforms } from "slate";
import { CustomElement } from "../../../../../slate";
import { useDialogState } from "../../../primitives"
import { useTextFilter } from "../../../schemed/Filtering/useTextFilter";
import { FieldSchema, Schema } from "../../../../hooks/useSchema";
import { EditorPlugin } from "../../slate/PowerEditorConfig";
import { PlaceholderDialog } from "./PlaceholderDialog";
import { PlaceholderElement } from "./PlaceholderElement";
import { ReactEditor, RenderElementProps } from "slate-react";

export interface PlaceholderField extends FieldSchema {
    expression: string;
}

interface PlaceholderFieldInternal extends PlaceholderField {
    entityExpression: string;
    entityLabel: string;
}

export interface PlaceholderEntityConfig {
    expression: string;
    label: string;
    fields: PlaceholderField[];
}

export const placeholdersEntityConfigFromSchema = (expression: string, label: string, schema: Schema): PlaceholderEntityConfig => {
    return {
        expression,
        label,
        fields: Object.entries(schema).map(([k,v]) => ({ expression: k, ...v })),
    }
}



export const PlaceholderElementType = "placeholder";

export const withPlaceholders = (editor: Editor) => {
    const { isInline, isVoid } = editor
    
    editor.isInline = (element: CustomElement) => {
        return element.type === PlaceholderElementType ? true : isInline(element)
    }
    
    editor.isVoid = (element: CustomElement) => {
        return element.type === PlaceholderElementType ? true : isVoid(element)
    }
    
    return editor
}

const fieldComparisonValue = (f: PlaceholderField) => `${f.label || "״"} ${f.expression}`;

export const usePlaceholders = (fieldsConfig: PlaceholderEntityConfig[]) => {
    const dialog = useDialogState();
    const filter = useTextFilter<PlaceholderFieldInternal>(f => `${f.entityLabel} ${f.label} ${f.expression}`);
    const [mode, setMode] = useState<"field" | "expression">("field");
    const [editElement, setEditElement] = useState<CustomElement | null>(null);

    const insertField = (editor: Editor, field: string) => {
        editor.insertNode({
            type: PlaceholderElementType,
            expression: field,
            children: [{ text: "", }],
        });
        dialog.close();
    }

    const openEdit = (element: CustomElement) => {
        filter.setFilter(element.expression || "");
        setEditElement(element);
    }

    const closeEdit = () => {
        setEditElement(null);
    }

    const completeEdit = (editor: Editor, expression: string) => {
        if(editElement) {
            const path = ReactEditor.findPath(editor, editElement);
            Transforms.setNodes(
                editor,
                { expression },
                { at: path },
            );
        }
        closeEdit();
    }

    const allFields = useMemo(() => {
        return fieldsConfig.reduce<PlaceholderFieldInternal[]>((r, entity) => {
            entity.fields.forEach(f => {
                r.push({
                    ...f,
                    expression: `${entity.expression}${entity.expression ? "." : ""}${f.expression}`,
                    entityExpression: entity.expression,
                    entityLabel: entity.label,
                });
            });
            return r;
        }, [])
    }, [fieldsConfig]);

    const fields = filter.filterData(allFields)
        .sort((a,b) => fieldComparisonValue(a) > fieldComparisonValue(b) ? 1 : -1);

    return {
        ...dialog,
        close: () => {
            dialog.close();
            filter.setFilter("");
        },
        filter,
        fields,
        insertField,
        mode,
        setMode,
        expression: mode === "expression" ? filter.filter : "",

        editDialog: {
            isOpen: !!editElement,
            close: closeEdit,
        },
        openEdit,
        completeEdit,
    }
}

export type PlaceholdersData = ReturnType<typeof usePlaceholders>;

export const usePlaceholdersPlugin = (fieldsConfig: PlaceholderEntityConfig[]): PlaceholdersData & { plugin: EditorPlugin } => {
    const data = usePlaceholders(fieldsConfig);
    const [editor, setEditor] = useState<Editor | null>(null);

    const PlaceholderElementMemo = useMemo(() => {
        return (props: RenderElementProps) => (
            <PlaceholderElement
                openEdit={(editor, element) => { data.openEdit(element); setEditor(editor); }}
                {...props}
                />);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        ...data,
        plugin: {
            key: "placeholders",
            inject: e => withPlaceholders(e),
            customBlocks: { [PlaceholderElementType]: PlaceholderElementMemo },
            dialogs: editor ? <>
                <PlaceholderDialog
                    key="insert-dialog"
                    data={data}
                    state={data}
                    submit={expression => {
                        data.insertField(editor, expression);
                    }}
                    />
                <PlaceholderDialog
                    key="edit-dialog"
                    data={data}
                    state={data.editDialog}
                    submit={expression => {
                        data.completeEdit(editor, expression);
                    }}
                    />
            </> : undefined,
            commands: [{
                name: "insert-placeholder",
                hotkey: "alt+p",
                invoke: editor => { setEditor(editor); data.open(); },
                menu: { label: "Placeholder", label_id: "powerdoc.plugins.placeholder.title", section: "insert-item", icon: <LabelOutlined /> },
            }],
        },
    }
}
