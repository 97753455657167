import React, { useMemo } from 'react';
import { FormControlProps } from '../schemed/FormControlProps';
import { useApiConfiguration } from '../ApiConfguration';
import { FieldSchema, FieldType } from '../../hooks/useSchema';
import { useLoadedData } from '../../hooks/useLoadedData';
import { EmailItem } from './types';
import { IconButton } from '@material-ui/core';
import { CallMadeOutlined } from '@material-ui/icons';
import { FormControl } from '../schemed';

export const useEmailTemplates = () => {
  const { emailTemplatesApiPath } = useApiConfiguration();
  const data = useLoadedData<EmailItem[]>(emailTemplatesApiPath || "", [], !!emailTemplatesApiPath);

  const selectorSchema: FieldSchema = useMemo(() => {
    const options = data.data.map(template => ({ value: template.code, label: template.title }));
    return {
      type: FieldType.select,
      values: options,
      valueDict: options.reduce<Record<string, string>>((r,v) => { r[v.value] = v.label; return r; }, {}),
    }
  }, [data.data]);

  return {
    ...data,
    selectorSchema,
    isAvailable: !!emailTemplatesApiPath,
  }
}


interface Props extends FormControlProps {
  renderPlaceholderWhenUnavailable?: boolean;
}

export const EmailTemplateSelector = (props: Props) => {
  const templates = useEmailTemplates();
  
  if(!templates.isAvailable) {
    return props.renderPlaceholderWhenUnavailable ? <div /> : null;
  }

  const schema = { ...props.schema, ...templates.selectorSchema };
  const selectedTemplateSlug = (props.row || {})[props.field];

  return (
    <FormControl
      {...props}
      schema={schema}
      extraProps={{
        ...(props.extraProps || {}),
        controlProps: {
          ...(props.extraProps?.controlProps || {}),
          startAdornment: (
            <IconButton
              size="small"
              href={selectedTemplateSlug ? `/system/emails?code=${selectedTemplateSlug}` : `/system/emails`}
              target="_blank"
              rel="noreferrer noopener">
                <CallMadeOutlined />
            </IconButton>)
        }
      }}
      />
  );
}
