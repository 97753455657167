import React, { useState } from 'react';
import styled from '@emotion/styled';
import { useMediaLibContext } from './MediaLibContext';
import { IconButton, Typography, withTheme } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import { createFileDropHandler, openFileUploader } from '../../api/files';
import { Add, DeleteOutlined, GetAppOutlined, InsertDriveFileOutlined, LinkOutlined, VisibilityOutlined } from '@material-ui/icons';
import { useCopyText } from '../primitives/CopyText';
import { useConfirmationDialog } from '../primitives/ConfirmationDialog';
import { ActionRow, OccupyFreeSpace } from '../primitives/ActionRow';
import { SearchField } from '../primitives/SearchField';
import { MediaFile } from './useMediaLib';
import { MediaLibDetailsDialog } from './MediaLibDetailsDialog';
import { useListAutoexpander } from '../primitives/useListAutoexpander';
import { LoadingIndicator } from '../primitives/LoadingIndicator';
import { PossiblyProtectedImg } from './ProtectedImg';

const Wrapper = withTheme(styled.div<{ isDragTarget?: boolean, minHeight?: string }>`
    position: relative;
    width: 100%;
    height: auto;
    ${props => props.minHeight ? `min-height: ${props.minHeight};` : ""}
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-content: flex-start;
    
    gap: 20px;
    column-gap: 20px;
    row-gap: 20px;
    padding-top: 1rem;

    filter: ${props => props.isDragTarget ? "brightness(0.75)" : "none"};
    border: 3px solid ${props => props.isDragTarget ? props.theme.palette.primary.main : "transparent"};
    border-radius: ${props => props.theme.shape.borderRadius}px;

    & > * {
        pointer-events: ${props => props.isDragTarget ? "none" : "auto"};
    }
`);

const Item = withTheme(styled.div<{ isSelected?: boolean}>`
    position: relative;
    width: 150px;
    height: 150px;
    min-width: 150px;
    min-height: 150px;
    max-width: 150px;
    max-height: 150px;

    display: flex;
    flex-flow: column;
    justify-content: space-between;
    border-radius: ${props => props.theme.shape.borderRadius}px;
    overflow: hidden;
    box-shadow: 0 0 5px 0 #00000020;
    border: 2px solid ${props => props.isSelected ? props.theme.palette.primary.main : "transparent"};

    & > img, & > svg {
        width: 100%;
        max-height: 125px;
        height: auto;
        object-fit: contain;
    }

    & > svg {
        color: #00000040;
    }

    & > .filename {
        overflow: hidden;
        max-width: 100%;
        text-overflow: ellipsis;
        padding: 0.25rem 0.25rem;
        color: ${props => props.theme.palette.grey[600]};
    }

    .overlay {
        display: none;
    }

    &:hover {
        background-color: #00000080;

        .overlay {
            display: flex;
            flex-flow: row wrap;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            padding: 20%;
            gap: 6px;
            background: #00000040;
            color: #ffffff;
            border-radius: ${props => props.theme.shape.borderRadius}px;
        }
    }
`);

const Placeholder = withTheme(styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    background: ${props => props.theme.palette.grey[200]};
    border-radius: ${props => props.theme.shape.borderRadius}px;
`);


interface Props {
    noUpload?: boolean;
    noActions?: boolean;
    onFileClick?: (mf: MediaFile, url: string) => void;
    minHeight?: string;
    selectedUrls?: string[];
}

export const MediaLibBrowser = (props: Props) => {
    const medialib = useMediaLibContext();
    const {
        isLoading,
        isUploading,
        data,
        filter,
        setFilter,
        getFilepath,
        downloadFile,
        uploadFile,
        removeFile,
        protectedFiles,
    } = medialib;
    const [isDragTarget, setIsDragTarget] = useState<boolean>(false);
    const [detailsFile, setDetailsFile] = useState<MediaFile | null>(null);

    const { noUpload, noActions, minHeight, selectedUrls, onFileClick } = props;

    const { formatMessage } = useIntl();
    const copyText = useCopyText({ notificationText: formatMessage({ id: "medialib.browser.link_copied" })});

    const confirmations = useConfirmationDialog();

    const autoExpander = useListAutoexpander(data, 20, 20);

    return (
        <>
            <ActionRow alignItems="flex-end" itemMarginTop="0">
                <OccupyFreeSpace />
                {(isLoading || isUploading) && <LoadingIndicator sizeVariant="m" />}
                <SearchField
                    filter={filter}
                    setFilter={setFilter}
                    autoFocus
                    noButton
                    small
                    />
                {!noUpload && <IconButton size="small" onClick={() => openFileUploader(f => uploadFile(f))}>
                    <Add />
                </IconButton>}
            </ActionRow>
            <Wrapper
                isDragTarget={!noUpload && isDragTarget}
                onDragEnter={() => setIsDragTarget(true)}
                onDragLeave={() => setIsDragTarget(false)}
                onDrop={noUpload ? undefined : createFileDropHandler(f => { setIsDragTarget(false); uploadFile(f); }, true)}
                onDragOver={(e: any) => { e.preventDefault(); }}
                minHeight={minHeight}
                >
                {!isLoading && !data.length && (
                    <Placeholder
                        onClick={() => !noUpload && openFileUploader(f => uploadFile(f))}>
                        {!filter && <Typography key="no-files"><FormattedMessage id="medialib.browser.no_files" /></Typography>}
                        {!!filter && <Typography key="no-files-found"><FormattedMessage id="medialib.browser.no_files_found" /></Typography>}
                        <Typography key="upload"><FormattedMessage id="medialib.browser.to_upload" /></Typography>
                    </Placeholder>
                )}

                {autoExpander.visibleItems.map(mf => {
                    const url = getFilepath(mf);
                    const thumbnailUrl = mf.thumbnail
                        ? protectedFiles ? `${url}/thumbnail` : getFilepath({ ...mf, filename: mf.thumbnail })
                        : null;
                        
                    return (
                    <Item
                        onClick={onFileClick ? () => onFileClick(mf, url) : undefined}
                        isSelected={selectedUrls && selectedUrls.includes(url)}>
                        {mf.contenttype.startsWith("image")
                            ? <PossiblyProtectedImg protected={protectedFiles} src={thumbnailUrl || url} alt={mf.filename} />
                            : <InsertDriveFileOutlined /> }
                        <Typography className="filename" variant="caption">{mf.filename}</Typography>
                        {!noActions && <div className="overlay" onClick={() => setDetailsFile(mf)}>
                            <IconButton
                                key="view"
                                size="small"
                                color="inherit">
                                <VisibilityOutlined />
                            </IconButton>
                            <IconButton
                                key="download"
                                size="small"
                                color="inherit"
                                onClick={e => {
                                    e.stopPropagation();
                                    downloadFile(mf);
                                }}>
                                <GetAppOutlined />
                            </IconButton>
                            <IconButton
                                key="copy-url"
                                size="small"
                                color="inherit"
                                onClick={e => {
                                    e.stopPropagation();
                                    copyText(url);
                                    }}>
                                    <LinkOutlined />
                            </IconButton>
                            <IconButton
                                key="remove"
                                size="small"
                                color="inherit"
                                onClick={e => {
                                    e.stopPropagation();
                                    confirmations.open({
                                        execute: () => removeFile(mf),
                                        title: <FormattedMessage id="medialib.browser.remove_file_confirmation" />,
                                        confirmationLabel: <FormattedMessage id="common.delete" />,
                                })}}>
                                    <DeleteOutlined />
                            </IconButton>
                        </div>}
                    </Item>
                )})}
            </Wrapper>
            {autoExpander.anchor}

            <MediaLibDetailsDialog
                file={detailsFile}
                close={() => setDetailsFile(null)}
                data={medialib}
                />
        </>
    );
}
