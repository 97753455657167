import React from 'react';
import styled from '@emotion/styled';
import { TextField, withTheme } from '@material-ui/core';
import { ScoringControlProps } from './typings';

export const Chip = withTheme(styled.button<{ isSelected?: boolean }>`
    font-size: 16px;
    width: 32px;
    height: 32px;
    border-radius: 16px;
    padding: 0;
    text-align: center;
    background: ${props => props.isSelected ? props.theme.palette.primary.main : props.theme.palette.grey[300]};
    color: ${props => props.isSelected ? props.theme.palette.primary.contrastText : props.theme.palette.text.primary};

    outline: none;
    border: none;
    cursor: pointer;
`);

const Wrapper = styled.div`
    display: flex;
    flex-flow: row wrap;
    gap: 4px;
    padding: 6px 0;
`;

export const NumberScore = ({ data, criteria }: ScoringControlProps) => {
    const {
        scores,
        update,
    } = data;

    const id = criteria._id as string;

    const showChips = !!criteria && criteria.min_value !== undefined && criteria.max_value !== undefined && criteria.display_settings?.control !== "input";
    
    if(showChips) {
        const chipStep = criteria?.display_settings?.step || 1;
        const chipItems = [];
        let v = criteria.min_value as number;
        while(v < (criteria.max_value as number)) {
            chipItems.push(v);
            v += chipStep;
        }
        chipItems.push(criteria.max_value as number);
        if(criteria.display_settings?.reverse_values_order) {
          chipItems.reverse();
        }

        return (
            <Wrapper className={`numeric ${criteria.display_settings?.reverse_values_order ? "reverse" : ""}`}>
                {chipItems.map(v =>
                    <Chip
                        key={v}
                        className={`chip ${scores[id] === v ? 'selected' : ''} ${scores[id] > v ? 'less-selected' : ''}`}
                        onClick={() => update({ [id]: v })}
                        isSelected={criteria.display_settings?.reverse_values_order ? scores[id] <= v : scores[id] >= v}>
                        {v}
                    </Chip>)}
            </Wrapper>
        );
    } else {
        return <TextField className="numeric" type="number" value={scores[id]} onChange={e => update({ [id]: +e.target.value })} />
    }
}
