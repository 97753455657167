import { apiFetch, FetchTypes } from "../../../api/core";
import { LoadedData, useLoadedData } from "../../../hooks/useLoadedData"
import { OpenableEdit, useOpenableEdit } from "../../../hooks/useOpenableEdit";
import { Schema, useSingleSchema } from "../../../hooks/useSchema";
import { useTextFilter, WithTextFilter } from "../../schemed/Filtering/useTextFilter";
import { ScoringSheet } from "./typings";

export interface ScoringSheetsListData extends LoadedData<ScoringSheet[]>, WithTextFilter {
    newSheet: OpenableEdit<Pick<ScoringSheet, 'code'>>;
    schema: Schema;
}

export const useScoringSheetsList = (apiPath: string): ScoringSheetsListData => {
    const data = useLoadedData<ScoringSheet[]>(apiPath, []);
    const filter = useTextFilter<ScoringSheet>(s => `${s.code} ${s.title} ${s.description}`);

    const newSheet = useOpenableEdit<Pick<ScoringSheet, 'code'>>(
        item => apiFetch(apiPath, FetchTypes.POST, item).then(() => data.reload()),
        { canComplete: item => item.code.length > 0 });

    const { schema } = useSingleSchema(`${apiPath}/uiconfig`);

    return {
        ...data,
        ...filter,
        data: filter.filterData(data.data),
        newSheet,
        schema,
    }
}