import React from 'react';
import { TextField, Button, FormControl, FormHelperText } from '@material-ui/core';
import { Login } from '../../hooks/auth';
import { OuterCentralizedForm } from '../primitives/Forms';
import { FormattedMessage } from 'react-intl';

interface Props {
    loginData: Login;
}

export const LoginForm = (props: Props) => {
    const {
        data: credentials,
        update: onChangeCredentials,
        send: submit,

        isSecondFactorRequested,
        code,
        setCode,
    } = props.loginData;

    return (
        <form onSubmit={e => { e.preventDefault(); submit(); }}>
            <OuterCentralizedForm>
                <TextField
                    key="email"
                    autoFocus
                    label={<FormattedMessage id="login.email"/>}
                    name='email'
                    variant='outlined'
                    value={credentials.login}
                    disabled={isSecondFactorRequested}
                    onChange={e => onChangeCredentials({ ...credentials, login: e.target.value || '' })} />
                {!isSecondFactorRequested && <TextField
                    key="password"
                    label={<FormattedMessage id="login.password"/>}
                    name='password'
                    type='password'
                    variant='outlined'
                    value={credentials.password}
                    onChange={e => onChangeCredentials({ ...credentials, password: e.target.value || '' })} />}
                {isSecondFactorRequested && <FormControl>
                    <TextField
                        key="code"
                        autoFocus
                        label={<FormattedMessage id="login.code"/>}
                        name='code'
                        type='code'
                        variant='outlined'
                        value={code}
                        onChange={e => setCode(e.target.value || '')} />
                    <FormHelperText><FormattedMessage id="login.code_hint"/></FormHelperText>
                </FormControl>}
                <Button type='submit' variant='contained' color='primary'><FormattedMessage id="login.submit"/></Button>
            </OuterCentralizedForm>
        </form>
    )
};
