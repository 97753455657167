import { useState } from "react";
import { apiFetch, downloadBuffer, FetchTypes } from "../../api/core";
import { useLoadedData } from "../../hooks/useLoadedData"
import { FieldSchema, FieldType, mergeSchema, Schema, useSingleSchema } from "../../hooks/useSchema";
import { EmailItem } from "../emails/types";
import { AttachmentsData, useAttachments } from "./useAttachments";
import { NotificationTarget, NotificationTargetsManager, useNotificationTargets } from "./useNotificationTargets";
import { getDefaultTemplateContent } from "./NotificationTemplateEditor";
import { TextFilter, useTextFilter } from "../schemed/Filtering/useTextFilter";
import ExcelJS from 'exceljs';

export interface Attachment {
    filename: string;
    filepath: string;
}

export interface NotificationTask {
    _id: string;
    title: string;
    message: string;
    message_html?: string;
    message_template?: any;
    is_powertemplate_content?: boolean;
    attachments?: Attachment[];
    link: string;
    comment: string;
    medium: 'email' | 'notification' | 'both';
    targets: NotificationTarget[];
    is_executed: boolean;
    executed_time: string;
}

export interface NotificationTargetsManagerX extends NotificationTargetsManager {
    addTarget: (t: NotificationTarget) => void;
    removeTarget: (t: NotificationTarget) => void;
    addBatch: (t: NotificationTarget[]) => void;
    removeBatch: (t: NotificationTarget[]) => void;
    removeAll: () => void;
    isTargetSelected: (t: NotificationTarget) => boolean;
}

export interface NotificationTasksData {
    tasks: NotificationTask[];
    isLoading: boolean;
    isShowExecuted: boolean;
    setIsShowExecuted: (v: boolean) => void;
    schema: Schema;
    
    newTask: Partial<NotificationTask> | null;
    isCreating: boolean;
    editTask: NotificationTask | null;
    isEditing: boolean;
    hasChanges: boolean;
    
    startCreate: () => void;
    startEdit: (task: NotificationTask) => void;
    uploadTargets: (file: File) => Promise<any>;
    update: (changes: Partial<NotificationTask>) => void;
    cloneEdited: () => void;
    save: () => void;
    remove: (task: NotificationTask) => void;
    cancelEdit: () => void;
    isSaving: boolean;

    execute: (task: NotificationTask) => void;
    testExecute: (task: NotificationTask, target: { email: string }) => void;
    isExecuting: boolean;

    targets: NotificationTargetsManagerX;

    attachments: AttachmentsData;
    filter: TextFilter<NotificationTask>;
}

export interface NotificationTasksConfig {
    emailsApiPath?: string;
}

export const readTargetsFromXlsx = (file: File): Promise<NotificationTarget[]> => {
  return new Promise<NotificationTarget[]>((resolve,reject) => {
    const reader = new FileReader();
    reader.addEventListener("load", e => {
      const workbook = new ExcelJS.Workbook();
      workbook.xlsx.load(e.target?.result as ArrayBuffer).then(wb => {
        try {
          if(e.target) {
            const sheet = wb.worksheets[0];
            const result: NotificationTarget[] = [];
            const extraInfoCols: Record<string,number> = {};
            sheet.eachRow((row, idx) => {
              if(idx === 1) {
                for (let i = 3; i < 20; i++) {
                  const key = row.getCell(i)?.text;
                  if(key) {
                    extraInfoCols[key] = i;
                  }
                }
              }
              if(idx > 1) {
                const item: NotificationTarget = {
                  email: row.getCell(1).text,
                  display_name: row.getCell(2).text || row.getCell(1).text,
                  kind: "upload",
                };

                if(Object.keys(extraInfoCols).length) {
                  item.info = Object.entries(extraInfoCols).reduce<Record<string,string>>((r,[k,i]) => { r[k] = row.getCell(i)?.text || ""; return r; }, {});
                }

                result.push(item)
              }
            });
            resolve(result);
          }
        } catch(e) {
          reject(e);
        }});
    });
    reader.readAsArrayBuffer(file);
  });
}

export const getTargetsUploadXlsxExample = () => {
  const workbook = new ExcelJS.Workbook();
    const titleClean = "Example";
    const sheet = workbook.addWorksheet(titleClean);

    sheet.columns = [
        { header: "Email", key: "email" },
        { header: "Name", key: "display_name" },
        { header: "extra_data1", key: "extra_data1" },
        { header: "extra_data2", key: "extra_data2" },
    ];
  
    [
      ["example1@example.com", "", "anything", "anything else"],
      ["example2@example.com", "Name", "anything 2", "anything else 2"],
    ].forEach(example => {
        sheet.addRow(example)
    });
  
    workbook.xlsx
      .writeBuffer({ base64: true } as any)
      .then((xls64) => downloadBuffer(xls64, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", `example.xlsx`));
}

const useEmailTemplates = (apiPath?: string) => {
    const data = useLoadedData<EmailItem[]>(apiPath || "", [], !!apiPath);
    const templateSelectorSchema: FieldSchema = {
        label_id: "notifications.task.email_template",
        label: "Email template",
        type: FieldType.select,
        values: data.data.map(e => ({ value: e.code, label: e.code })),
        valueDict: data.data.reduce((r,e) => ({ ...r, [e.code]: e.code }), []),
    }
    return {
        ...data,
        templateSelectorSchema,
    }
}

export const useNotificationTasks = (apiPath: string, cfg?: NotificationTasksConfig): NotificationTasksData => {
    const data = useLoadedData<NotificationTask[]>(apiPath, []);
    const [isShowExecuted, setIsShowExecuted] = useState<boolean>(false);

    const [newTask, setNewTask] = useState<Partial<NotificationTask> | null>(null);
    const [changes, setChanges] = useState<Partial<NotificationTask> | null>(null);
    const [editTask, setEditTask] = useState<NotificationTask | null>(null);

    const targets = useNotificationTargets(apiPath);

    const { schema } = useSingleSchema(`${apiPath}/uiconfig`);
    const emailTemplates = useEmailTemplates(cfg?.emailsApiPath);

    const startCreate = () => {
      setNewTask({
        title: "",
        message: "",
        medium: "email",
        is_powertemplate_content: true,
        message_template: getDefaultTemplateContent(window.location.href),
        targets: [],
      });
      setChanges({});
      setEditTask(null);
    }

    const startEdit = (task: NotificationTask) => {
        setEditTask(task);
        setChanges({});
        setNewTask(null);  
    }

    const cloneEdited = () => {
        if(editTask) {
            const clone = { 
                title: editTask.title,
                message: editTask.message,
                message_html: editTask.message_html,
                message_template: editTask.message_template,
                is_powertemplate_content: editTask.is_powertemplate_content,
                attachments: editTask.attachments,
                
                comment: editTask.comment,
                targets: [...editTask.targets],
                medium: editTask.medium,
            };
            setChanges({});
            setEditTask(null);
            setNewTask(clone);
        }
    }

    const cancelEdit = () => {
        setEditTask(null);
        setChanges({});
        setNewTask(null);  
    }

    const update = (changes: Partial<NotificationTask>) => {
        if(newTask) {
            setNewTask(t => ({ ...t, ...changes }));
        }
        if(editTask) {
            setEditTask(t => ({ ...t, ...changes } as NotificationTask));
            setChanges(t => ({ ...t, ...changes }));
        }
    }

    const attachments = useAttachments({ task: editTask || newTask, update });

    const [isSaving, setIsSaving] = useState<boolean>(false);

    const save = () => {
        const isCreate = !!newTask;
        if(!isCreate && !editTask) {
            return;
        }
        
        setIsSaving(true);
        apiFetch<NotificationTask>(
            isCreate ? apiPath : `${apiPath}/${editTask?._id}`,
            isCreate ? FetchTypes.POST : FetchTypes.PUT,
            isCreate ? newTask : changes
        ).then(created => {
            setIsSaving(false);
            setChanges({});
            if(isCreate) {
                startEdit(created);
            }
            data.reload();
        })
        .catch(e => {
            setIsSaving(false);
            throw e;
        });
    }

    const remove = (task: NotificationTask) => {
        apiFetch<NotificationTask>(`${apiPath}/${task._id}`, FetchTypes.DELETE,)
            .then(() => {
                data.reload();
                cancelEdit();
            });
    }


    const [isExecuting, setIsExecuting] = useState<boolean>(false);
    const execute = (task: NotificationTask) => {
        setIsExecuting(true);
        apiFetch<NotificationTask>(`${apiPath}/${task._id}/send`, FetchTypes.POST)
            .then(result => {
                data.reload();
                if(editTask) {
                    setEditTask(result);
                }
                setIsExecuting(false);
            })
            .catch(e => {
                setIsExecuting(false);
                throw e;
            });
    }

    const testExecute = (task: NotificationTask, target: { email: string }) => {
        setIsExecuting(true);
        apiFetch<NotificationTask>(`${apiPath}/${task._id}/send-test`, FetchTypes.POST, { email: target.email })
            .then(result => {
                if(editTask) {
                    setEditTask(result);
                }
                setIsExecuting(false);
            })
            .catch(e => {
                setIsExecuting(false);
                throw e;
            });
    }

    const updateTargets = (mutation: (old: NotificationTarget[]) => NotificationTarget[]) => {
        update({ targets: mutation((!!newTask ? newTask?.targets : editTask?.targets) || [])})
    }

    const targetKey = (t: NotificationTarget) => `${t.email}${t.user_id}${t.kind}`;
    const filter = useTextFilter<NotificationTask>(t => `${t.title} ${t._id} ${t.comment || ""}`);

    const uploadTargets = (file: File) => {
      if(editTask) {
        return readTargetsFromXlsx(file)
          .then(targets => updateTargets(old => [...old, ...targets]));
      }
      return Promise.resolve({});
    }

    return {
        tasks: filter.filterData(data.data.filter(t => isShowExecuted || !t.is_executed)),
        ...data,
        isShowExecuted,
        setIsShowExecuted,
        schema: mergeSchema(schema, {
            message: { type: FieldType.textlong },
            message_html: { type: FieldType.textlong },
            email_template: emailTemplates.templateSelectorSchema,
        }),

        newTask,
        isCreating: !!newTask,
        editTask,
        isEditing: !!editTask,
        hasChanges: !!changes && Object.keys(changes).length > 0,
        uploadTargets,

        startCreate,
        startEdit,
        cancelEdit,
        cloneEdited,

        update,
        save,
        remove,
        isSaving,

        isExecuting,
        execute,
        testExecute,
        filter,

        targets: {
            ...targets,
            addTarget: t => updateTargets(x => [...x, t ]),
            removeTarget: t => updateTargets(x => x.filter(te => targetKey(t) !== targetKey(te))),
            addBatch: toAdd => updateTargets(targets => [...targets, ...toAdd.filter(tAdded => !targets.find(tExisting => targetKey(tExisting) === targetKey(tAdded)))]),
            removeBatch: toRemove => updateTargets(targets => targets.filter(tExisting => !toRemove.find(tRemoved => targetKey(tRemoved) === targetKey(tExisting)))),
            removeAll: () => updateTargets(x => []),
            isTargetSelected: t => !!(editTask?.targets || newTask?.targets || []).find(te => targetKey(t) === targetKey(te)),
        },

        attachments,
    }
}