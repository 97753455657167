import { ReactNode, useCallback } from "react";
import { CustomElement } from "../../../slate";
import { useIntl } from "react-intl";

export interface Setting {
  setting: string;
  values?: string[];
}


export type SettingSelector = (element: CustomElement | null) => boolean;

export interface Suggestion {
  selector: SettingSelector;
  suggestion: Setting;
}

export const suggest = (selector: SettingSelector, setting: Setting): Suggestion => ({
  selector,
  suggestion: setting,
})

export const forAny = (): SettingSelector => e => !!e;
export const forBlock = (blockType: string): SettingSelector => e => (e?.type === blockType);

export const setting = (setting: string, values?: string[]): Setting => ({ setting, values });

export interface BlockHint {
  selector: SettingSelector;
  messageId?: string;
  message?: ReactNode;
}

export const hint = (selector: SettingSelector, hint: ReactNode | { messageId: string }): BlockHint => ({
  selector,
  message: (hint as any).messageId ? undefined : hint,
  messageId: (hint as any).messageId || undefined,
})


export const getSuggestedSettings = (suggestions: Suggestion[], element: CustomElement | null): string[] => {
  return suggestions
    .filter(s => s.selector(element))
    .map(s => s.suggestion.setting);
}

export const getSuggestedValues = (suggestions: Suggestion[], element: CustomElement | null, setting: string): string[] => {
  const applicable = suggestions
    .filter(s => s.selector(element))
    .filter(s => s.suggestion.setting === setting)
    .map(s => s.suggestion.values)
    .reduce<string[]>((r,v) => {
      r.push(...(v || []));
      return r;
    }, []);
  
  return Array.from(new Set(applicable)).sort();
}

export const useSuggestions = (suggestions: Suggestion[]) => {
  const settings = useCallback(
    (e: CustomElement | null) => getSuggestedSettings(suggestions, e),
    [suggestions]);
  
  const values = useCallback(
    (e: CustomElement | null, setting: string) => getSuggestedValues(suggestions, e, setting),
    [suggestions]);

  return { 
    settings,
    values,
  }
}


export const useBlockHints = (hintsIn: BlockHint[]) => {
  const { formatMessage } = useIntl();

  const hints = useCallback(
    (e: CustomElement | null) => (
      hintsIn
        .filter(h => h.selector(e))
        .map(h => h.messageId ? formatMessage({ id: h.messageId }) : h.message)
    ),
    [hintsIn, formatMessage]);
  
  
  return { 
    hints,
  }
}
