import React from 'react';
import { FileList, useFileList } from '.';

export interface FileListProps {
    apiPath: string;
    title?: string
}

export const FileListPage = (props: FileListProps) => {
    const filelist = useFileList(props.apiPath);
    return (
        <FileList {...props}  filelist={filelist} />
    );
}
