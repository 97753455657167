import { FormHelperText } from '@material-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FieldSchema } from '../../hooks/useSchema';
import { ExtraProps } from './FormControlProps';

interface Props {
    schema: FieldSchema;
    extraProps: ExtraProps | undefined;
    wrapper?: React.ComponentType;
}

export const Hint = ({ schema, extraProps, wrapper }: Props) => {
    const hint = extraProps?.hint || (schema?.hint_id ? <FormattedMessage id={schema.hint_id} /> : schema?.hint);
    const Wrapper = wrapper || FormHelperText;
    return hint ? <Wrapper>{hint}</Wrapper> : null;
}
