import { LocalizerConfig, useLocalizer } from "./useLocalizer";


export const useLocalizedRecord = <T,>(source: T, config: LocalizerConfig<T>) => {
    const localizer = useLocalizer(config);

    const item = localizer.isDefaultLocale ? source : localizer.translate(source);

    return {
        item,
        ...localizer,
    }
}
