import { useLoadedData } from "../../hooks/useLoadedData"
import { useTextFilter } from "../schemed/Filtering/useTextFilter";
import { RecordTypeConfig } from "./useRecordTypeConfig"

export const useCMSMenu = (apiPath: string) => {
    const configs = useLoadedData<RecordTypeConfig[]>(`${apiPath}/manage/config`, []);
    const filter = useTextFilter<RecordTypeConfig>(r => `${r.record_type} ${r.label}`);
    
    return {
        ...configs,
        filter,
        data: filter.filterData(configs.data),
    };
}

export type CMSMenuData = ReturnType<typeof useCMSMenu>;
