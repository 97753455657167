import React, { useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { FormattedMessage } from 'react-intl';
import { Editor } from 'slate';
import { ListItemIcon, ListItemText, MenuItem, MenuList, Popover, Typography, withTheme } from '@material-ui/core';
import { ContextMenuState } from './useContextMenu';
import { EditorCommand } from '../PowerEditorConfig';
import { translateHotkey } from '../../../primitives/hotkeys';
import { assembleMenuSectionsConfig, DefaultMenuConfig, Divider, MenuSection } from './MenuConfiguration';

const MenuTabHeaders = styled.div`
    width: 100%;
    display: flex;
    flex-flow: row;
`;

const MenuTabHeader = withTheme(styled(Typography)<{ isActive?: boolean }>`
    cursor: pointer;
    padding: 0.5rem 1rem;
    font-weight: ${props => props.isActive ? "500" : "normal"};
    border-bottom: ${props => props.isActive ? "none" : "1px solid #cccccc"};
    background: ${props => props.isActive ? "transparent" : "#f5f5f5"};
    &:not(:last-child) {
        border-right: 1px solid #cccccc;
    }
`);
MenuTabHeader.defaultProps = { role: "button" };

const MenuTab = styled.div`
`;

const MenuItemX = withTheme(styled(MenuItem)<{ isActive?: boolean }>`
    & .MuiListItemIcon-root {
        color: ${props => props.isActive ? props.theme.palette.primary.main : props.theme.palette.text.secondary};
    }
`);

interface Props {
    state: ContextMenuState;
    config?: MenuSection[];
    commands?: EditorCommand[];
    editor: Editor;
}

const useTabState = (tabs: string[]) => {
    const [activeIdx, setActiveTabIdx] = useState<number>(0);

    return {
        prev: () => setActiveTabIdx(x => x-1 >= 0 ? x-1 : tabs.length - 1),
        next: () => setActiveTabIdx(x => x+1 < tabs.length ? x+1 : 0),
        activeIdx,
        active: tabs[activeIdx],
        activate: (tab: string) => {
            const foundIdx = tabs.indexOf(tab);
            if(foundIdx >= 0) {
                setActiveTabIdx(foundIdx);
            }
        },
    }
}



export const ContextMenu = ({ state, commands, config, editor }: Props) => {
    const menuConfig = useMemo(
        () => assembleMenuSectionsConfig(config || DefaultMenuConfig, commands || []),
        [config, commands],
    );

    const tabs = useTabState(menuConfig.map(s => s.section));

    const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if(e.key === "ArrowLeft") {
            e.preventDefault();
            tabs.prev();
        } else if (e.key === "ArrowRight") {
            e.preventDefault();
            tabs.next();
        } else if (e.key === "Escape" && state.isOpen) {
            state.close();
            e.stopPropagation();
            e.preventDefault();
        }
    }

    const renderCommandMenuItem = (command: EditorCommand) => (
        <MenuItemX
            key={command.name}
            onClick={() => {
                command.invoke(editor);
                state.close();
            }}
            isActive={command.isActive && command.isActive(editor)}
            >
            {command.menu?.icon && <ListItemIcon>{command.menu.icon}</ListItemIcon>}
            <ListItemText
                primary={command.menu?.label_id
                    ? <FormattedMessage id={command.menu.label_id} values={command.menu.label_values} />
                    : command.menu?.label}
                secondary={translateHotkey(command.hotkey)}
                />
        </MenuItemX>
    );
    

    return (
        <Popover
            open={state.isOpen}
            onClose={state.close}
            anchorReference="anchorPosition"
            anchorPosition={state.anchor ? { top: state.anchor.y, left: state.anchor.x } : undefined}
            onKeyDown={onKeyDown}
        >
            <MenuTabHeaders>
                {menuConfig.map(section => (
                    <MenuTabHeader
                        isActive={tabs.active === section.section}
                        onClick={e => {
                            tabs.activate(section.section);
                            e.preventDefault();
                        }}
                        >
                        {section.label_id ? <FormattedMessage id={section.label_id} /> : section.label}
                    </MenuTabHeader>
                ))}
            </MenuTabHeaders>

            {menuConfig.map(section => (
                <MenuTab key={section.section}>
                    {tabs.active === section.section && (
                        <MenuList dense autoFocusItem={state.isOpen && tabs.active === section.section}>
                            {section.commands
                                .filter(c => c === Divider || !c.isAvailable || c.isAvailable(editor))
                                .map((c, idx) => c === Divider
                                    ? <MenuItem key={idx} divider disabled />
                                    : renderCommandMenuItem(c))}
                        </MenuList>)}
                </MenuTab>
            ))}

        </Popover>
    );
}
