import React from 'react';
import { Redirect, useRouteMatch } from 'react-router-dom';
import { Routes } from '../frame';
import { ConfirmationDialogProvider } from '../primitives';
import { RobudConfig } from './Config';
import { RobudExecutionsPage } from './RobudExecutionsPage';
import { RobudProgramPage } from './RobudProgramPage';
import { RobudProgramsListPage } from './RobudProgramsListPage';

interface Props extends Partial<Omit<RobudConfig, "pagePath">> {
}

const DefaultApiPath = "/api/robud";

export const RobudRoutes = (props: Props) => {
    let { path } = useRouteMatch();
    const apiPath = props.apiPath || DefaultApiPath;
    
    return (
        <ConfirmationDialogProvider>
            <Routes
                routes={[
                    { path: `${path}/program/:id`, component: RobudProgramPage, componentProps: { pagePath: path, apiPath } },
                    { path: `${path}/program`, component: RobudProgramsListPage, componentProps: { pagePath: path, apiPath: `${apiPath}/program` } },
                    { path: `${path}/executions`, component: RobudExecutionsPage, componentProps: { pagePath: path, apiPath } },
                    { path, component: () => <Redirect to={`${path}/program`} />}
                ]}
            />
        </ConfirmationDialogProvider>
    );
}
