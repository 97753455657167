import { Button, TextField } from '@material-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Element } from 'slate';
import { Dialog, FormGrid, ItemDialogState } from '../../../primitives';
import { InlineImageProperties } from './types';

interface Props extends ItemDialogState<Element & InlineImageProperties> {
    updateImageSettings: (updated: InlineImageProperties) => void;
    openImagePicker: () => void;
}

export const EditImageDialog = (props: Props) => {
    const { isOpen, item, update, close, updateImageSettings, openImagePicker } = props;
    return (
        <Dialog
            isOpen={isOpen}
            close={() => {
                if(item) {
                    updateImageSettings(item);
                }
                close();
            }}
            dialogTitle={<FormattedMessage id="powerdoc.plugins.images.dialog_title" />}
            noFullscreen>
            
            <FormGrid
                columns="1fr"
                contentEditable={false}>
                
                <TextField
                    value={item?.width || ""}
                    onChange={e => item && update({ width: e.target.value })}
                    label={<FormattedMessage id="powerdoc.plugins.images.fields.width" />}
                    />

                <TextField
                    value={item?.height || ""}
                    onChange={e => item && update({ height: e.target.value })}
                    label={<FormattedMessage id="powerdoc.plugins.images.fields.height" />}
                    />

                <TextField
                    value={item?._class || ""}
                    onChange={e => item && update({ _class: e.target.value })}
                    label={<FormattedMessage id="powerdoc.plugins.images.fields._class" />}
                    />

                <Button
                    color="primary"
                    onClick={() => openImagePicker()}>
                    <FormattedMessage id="powerdoc.plugins.images.fields.replace_image" />
                </Button>
            </FormGrid>
        </Dialog>
    );
}
