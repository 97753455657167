import { useState } from 'react';
import { apiFetch, FetchTypes } from '../../api/core';
import { useFetch } from '../../api/useFetch';

export interface Query {
    _id?: string;
    title: string;
    query: string;
    useremail?: string;
    datetime?: string;
}

export interface QueryError {
    _error: string;
    _details: any;
}

interface NumericDetails {
    total: number;
    rowvalues: string[];
}

export interface QueryConsoleData {
    query: Query;
    updateQuery: (query: Partial<Query>) => void;
    run: () => void;
    result: any[];
    error: QueryError | null;
    isQueryLoading: boolean;
    
    history: Query[];
    deleteHistory: (id: string) => void;
    restoreHistory: (id: string) => void;

    keys: string[];
    resultJSON: string;
    resultTSV: string;
    toggleNumericDetailsForColumn: (key: string) => void;
}


export const useQueryConsole = (apiPath: string): QueryConsoleData => {
    const { data: history, reload } = useFetch<Query[]>([], { url: apiPath.replace('/api', '') });
    const [query, setQuery] = useState<Query>({ title: "", query: ""});
    const [resultRaw, setResult] = useState<any[]>([]);
    const [error, setError] = useState<QueryError | null>(null);
    const [isQueryLoading, setIsQueryLoading] = useState<boolean>(false);
    const [numericDetails, setNumericDetails] = useState<{ [k: string]: NumericDetails | undefined }>({});

    const toggleNumericDetailsForColumn = (key: string) => {
        if(numericDetails[key]) {
            setNumericDetails(old => ({ ...old, [key]: undefined}));
        } else {
            if(result && result.length && !isNaN(result[0][key])) {
                const total = result.reduce((a,item) => a+item[key], 0);
                setNumericDetails(old => ({
                    ...old,
                    [key]: {
                        total,
                        rowvalues: result.map(row => `${row[key]} - ${(row[key]/total*100).toFixed(2)}%`)
                    }
                }))
            }
        }
    }

    const run = () => {
        setIsQueryLoading(true);
        setNumericDetails({});
        apiFetch<any[]>(apiPath, FetchTypes.POST, query)
            .then(result => {
                setIsQueryLoading(false);
                setResult(result);
                setError(null);
                reload();
            })
            .catch(e => {
                setIsQueryLoading(false);
                setResult([]);
                setError(e.response.data);
            });
    }

    const deleteHistory = (id: string) => {
        apiFetch(`${apiPath}/${id}`, FetchTypes.DELETE)
            .then(() => reload());
    }

    const restoreHistory = (id: string) => {
        const found = history.find(h => h._id === id);
        if(found) {
            setQuery({ title: found.title, query: found.query });
        }
    }

    const numericDetailsSetKeys = Object.keys(numericDetails).filter(k => !!numericDetails[k]);
    const result = numericDetailsSetKeys.length ?
        [...resultRaw.map((item, idx) => numericDetailsSetKeys.reduce((r,k) => ({ ...r, [k]: numericDetails[k]?.rowvalues[idx]}), item)),
        {
            ...Object.keys(resultRaw[0]).reduce((r,k) => ({...r, [k]: ""}), {}),
            ...numericDetailsSetKeys.reduce((r,k) => ({ ...r, [k]: numericDetails[k]?.total }), {}),
        }]
        : resultRaw;

    const keys = result && result.length ? Object.keys(result[0]).sort((a,b) => a < b ? -1 : (a === b ? 0 : 1)) : [];

    const toTSV = (items: any[]) => {
        if(items && items.length) {
            const header = keys.join('\t');
            const rows = items.map(i => keys.map(k => i[k]).join('\t'));
            return [header, ...rows].join('\n');
        } else {
            return "";
        }
    }
    

    return {
        query,
        updateQuery: (q: Partial<Query>) => setQuery(current => ({ ...current, ...q })),
        run,
        result,
        error,
        isQueryLoading,
        
        history,
        restoreHistory,
        deleteHistory,

        keys,
        resultJSON: error ? JSON.stringify(error) : JSON.stringify(result),
        resultTSV: toTSV(result),
        toggleNumericDetailsForColumn,
    }
}