import { useSnackbar } from "notistack";
import { useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { apiFetch } from "../../../api/core";
import { openFileUploader } from "../../../api/files";
import { useAction } from "../../../api/useAction";
import { useLoadedData } from "../../../hooks/useLoadedData";
import { RecordTypeConfig } from "../useRecordTypeConfig";

const areSetsEqual = <T>(a: Set<T>, b: Set<T>) => a.size === b.size && [...a].every(value => b.has(value));

export const useCMSImportExport = (apiPath: string) => {
    const configs = useLoadedData<RecordTypeConfig[]>(`${apiPath}/manage/config`, []);
    const { enqueueSnackbar } = useSnackbar();
    const { formatMessage } = useIntl();

    const [selectedTypes, setSelectedTypes] = useState<string[]>([]);

    const selectType = (recordType: string, isSelected: boolean) => {
        setSelectedTypes(x => isSelected ? [...x, recordType] : x.filter(t => t !== recordType));
    }

    const selectAll = (isSelected: boolean) => {
        setSelectedTypes(isSelected ? configs.data.map(c => c.record_type) : []);
    }

    const performExport = () => {
        return apiFetch(`${apiPath}/manage/export`, "post", { record_types: selectedTypes })
            .then(result => {
                const anchor = document.createElement("a");
                const data = new Blob([JSON.stringify(result)], { type: "text/plain;charset=utf-8" });
            
                const url = URL.createObjectURL(data);
                anchor.href = url;
                anchor.download = "export.json";
                document.body.appendChild(anchor);
                anchor.click();
                
                setTimeout(() => {
                    document.body.removeChild(anchor);
                    window.URL.revokeObjectURL(url);
                    }, 0);
            })
    }

    const exportAction = useAction(performExport, selectedTypes.length > 0);

    const [isImporting, setIsImporting] = useState<boolean>(false);

    const performImport = () => {
        openFileUploader(f => {
            setIsImporting(true);
            return new Promise<string>(resolve => {
                let reader = new FileReader();

                reader.onload = (e) => {
                    resolve(e.target?.result?.toString() || "");
                }
                reader.readAsText(f);
            })
            .then(jsonString => JSON.parse(jsonString))
            .then(data => apiFetch(`${apiPath}/manage/import`, "post", data))
            .then(result => {
                enqueueSnackbar(formatMessage({ id: "cms.importexport.import_success"}), { variant: "success", autoHideDuration: 5000 });
                setIsImporting(false);
            })
            .catch(e => {
                setIsImporting(false);
                throw e;
            });
        })
    }

    const isAllSelected = useMemo(
        () => areSetsEqual(new Set(selectedTypes), new Set(configs.data.map(c => c.record_type))),
        [selectedTypes, configs.data])

    return {
        isLoading: configs.isLoading,
        recordTypes: configs.data,
        selectedTypes,
        isAllSelected,
        selectType,
        selectAll,

        exportAction,
        importAction: {
            run: performImport,
            isRunning: isImporting,
            canRun: !isImporting,
        }
    }
}

export type CMSImportExportData = ReturnType<typeof useCMSImportExport>;
