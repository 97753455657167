import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { ScoringSheetEditContainer } from './ScoringSheetEdit';
import { ScoringComponentMapping } from './ScoringSheetForm';
import { ScoringSheetsListContainer } from './ScoringSheetsList';

export interface ScoringSheetsManagementProps {
    apiPath: string;
    customScoringComponents?: ScoringComponentMapping;
}

export const ScoringSheetsManagementContainer = (props: ScoringSheetsManagementProps) => {
    const { apiPath } = props;
    let { path } = useRouteMatch();

    return (
        <Switch>
            <Route exact path={path}>
                <ScoringSheetsListContainer apiPath={apiPath} />
            </Route>
            <Route path={`${path}/:code`}>
                <ScoringSheetEditContainer {...props} />
            </Route>
        </Switch>
    )
}
