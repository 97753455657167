import React from 'react';
import { Button, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { FormControl } from '../../schemed';
import { FormControlProps } from '../../schemed/FormControlProps';
import { FormattedMessage } from 'react-intl';

interface Props extends Omit<FormControlProps, "onChange"> {
    publish: () => void;
    unpublish: () => void;
}

export const PublishOrPublishedDate = (props: Props) => {
    const { row, field, publish, unpublish } = props;
    const value = (row || {})[field];
    return value
        ? <FormControl
            {...props}
            onChange={() => {}}
            extraProps={{
                readOnly: true,
                utcToLocal: true,
                ...(props.extraProps || {}),
                controlProps: {
                    InputProps: {
                        startAdornment: (
                            <IconButton size="small" color="secondary" onClick={() => unpublish()}>
                                <Close />
                            </IconButton>) }},
            }}
            />
        : (
            <Button size="small" color="primary" onClick={() => publish()}>
                <FormattedMessage id="cms.form.publish" />
            </Button>
        );
}
