import React, { PropsWithChildren, useContext } from 'react';

export interface ApiConfiguration {
  robudApiPath?: string;
  emailTemplatesApiPath?: string;
  cmsApiPath?: string;
  actionsApiPath?: string;
}

const ApiConfigurationContext = React.createContext<ApiConfiguration>({});

export const useApiConfiguration = () => useContext(ApiConfigurationContext);

export const ApiConfgurationProvider = (props: PropsWithChildren<ApiConfiguration>) => {
  const { children, ...configuration } = props;
  return (
    <ApiConfigurationContext.Provider value={configuration}>
      {children}
    </ApiConfigurationContext.Provider>
  );
}
