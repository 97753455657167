import { Chip } from '@material-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { LoadedData } from '../../hooks/useLoadedData';
import { PseudoLink2, useItemDialogState } from '../primitives';
import { TableForFields, TableRowButtons } from '../schemed';
import { getProgramLink } from './Config';
import { ExecutionDetailsPopup } from './ExecutionDetailsPopup';
import { ExecutionSchema } from './schema';
import { ProgramExecution } from './types';

interface Props {
    pagePath: string;
    data: Pick<LoadedData<ProgramExecution[]>, "data" | "isLoading">;
}

export const RobudExecutionsList = (props: Props) => {
    const detailsDialog = useItemDialogState<ProgramExecution>(null);
    return (<>
        <TableForFields
            data={props.data.data}
            fields={[
                ["execution_id"],
                ["program_id"],
                ["program_title"],
                ["trigger_id"],
                ["created_at", { utcToLocal: true }],
                ["data_rows_nbr"],
                ["log", { label: " "}],
            ]}
            schema={ExecutionSchema}
            fieldLink={f => {
                
                switch(f) {
                    case "program_id":
                        return row => getProgramLink(props.pagePath, { program_id: row.program_id });
                    case "trigger_id":
                        return row => getProgramLink(props.pagePath, row);
                }
            }}
            fieldElement={f => {
                switch(f) {
                    case "data_rows_nbr":
                        return row => (row.log?.data || []).length.toString();
                    case "log":
                        return row => <TableRowButtons style={{ gap: "0.5rem", alignItems: "center" }}>
                            <PseudoLink2 onClick={() => detailsDialog.open(row)}><FormattedMessage id="robud.executions.see_log" /></PseudoLink2>
                            {(row.log?.errors || []).length > 0 && <Chip color="secondary" size="small" label={<FormattedMessage id="robud.executions.has_errors" />} />}
                        </TableRowButtons>;
                }
            }}
            />
        <ExecutionDetailsPopup {...props} state={detailsDialog} />
    </>);
}
