import React from 'react';
import styled from '@emotion/styled';
import { Component } from '../usePages';
import { TabPanel, TabsHeader, useTabsState } from '../../primitives/Tabs';
import { MarkdownDisplay } from '../../schemed/Markdown';

const Wrapper = styled.div`
    padding: 1rem 0;
`;

export const Tabs = (c: Component) => {    
    const tabs = c.content_text.split("::tab")
        .map(tabPart => {
            const lines = tabPart.trim().split("\n");
            const tabNameLineNbr = lines.findIndex(s => s.trim().length > 0);
            if(tabNameLineNbr >= 0) {
                return { title: lines[tabNameLineNbr], content: lines.slice(tabNameLineNbr + 1).join("\n") };
            } else {
                return { title: null, content: "" };
            }
        })
        .filter(t => !!t.title);

    const tabState = useTabsState(tabs.map((t,i) => [i.toString(), <MarkdownDisplay key={i} text={t.title || ""} />]));

    return (
        <Wrapper className="page-component tabs">
            <TabsHeader state={tabState} scrollable noMargin />
            {tabs.map((tab,i) => (
               <TabPanel key={i} state={tabState.forTab(i.toString())}>
                   <MarkdownDisplay text={tab.content} />
               </TabPanel>
           ))}
        </Wrapper>
    );
}
