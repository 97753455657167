import { Button } from '@material-ui/core';
import { RefreshOutlined, UpdateOutlined } from '@material-ui/icons';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FieldType, Schema } from '../../hooks/useSchema';
import { Form, FormGrid, LoadingIndicator, OccupyFreeSpace } from '../primitives';
import { FormControlsForFields } from '../schemed';
import { RobudConfig } from './Config';
import { RobudExecutionsList } from './RobudExecutionsList';
import { RobudPagesLinks } from './RobudPagesLinks';
import { useRobudExecutions } from './useRobudExecutions';

interface Props extends RobudConfig {
    
}

const FilterSchema: Schema = {
    after: { type: FieldType.datetime, label_id: "robud.executions.filter.after" },
    before: { type: FieldType.datetime, label_id: "robud.executions.filter.before" },
}

export const RobudExecutionsPage = (props: Props) => {
    const data = useRobudExecutions(props.apiPath);

    return (
        <Form
            title={<FormattedMessage id="robud.executions.page_title" />}
            headerItems={<>
                <RobudPagesLinks {...props} active="execution" />
                <OccupyFreeSpace />

                <Button size="small" color="primary" startIcon={<RefreshOutlined />} onClick={() => data.reload()}>
                    <FormattedMessage id="common.reload" />
                </Button>
                
                <Button size="small" color="primary" startIcon={<UpdateOutlined />} onClick={() => data.resetFilter()}>
                    <FormattedMessage id="robud.executions.filter.reset" />
                </Button>
                {data.isLoading && <LoadingIndicator sizeVariant="s" />}
            </>}
            >
            <FormGrid columns="1fr 1fr 2fr">
                <FormControlsForFields
                    fields={[
                        ["after", { utcToLocal: true }],
                        ["before", { utcToLocal: true }],
                    ]}
                    schema={FilterSchema}
                    data={data.filter}
                    onChange={(o,c) => data.updateFilter(c)}
                    />
            </FormGrid>

            <RobudExecutionsList
                data={data}
                pagePath={props.pagePath}
                />
        </Form>
    );
}
