import { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useNewItem } from "../../../api/useNewItem";
import { useCrudUnpagedList } from "../../../api/useSimpleCrud";
import { useFieldSorting } from "../../../hooks/useFieldSorting";
import { CMSRecord, NewCMSRecord } from "../types";
import { useRecordTypeConfig } from "../useRecordTypeConfig";

export const useRecordsList = (apiPath: string, recordType: string) => {
    const config = useRecordTypeConfig(apiPath, recordType);
    const [lang, setLangX] = useState<string | null>("all");

    const sorting = useFieldSorting({ defaultSort: { field: "created_datetime", direction: "desc" } });

    const viewParams = useMemo(() => {
        const params = {} as Record<string,string>;
        if(lang && lang !== "all") {
            params["lang"] = lang;
        }
        if(sorting.sort?.field) {
            params["order-by"] = `${sorting.sort?.field || ""}:${sorting.sort?.direction}`;
        }
        return params;
    }, [lang, sorting.sort]);

    const data = useCrudUnpagedList<CMSRecord>(`${apiPath}/manage/${recordType}`, {
        defaultView: "all",
        filterBy: r => `${r.title} ${r.slug} /${(r.slug || "").replaceAll(".", "/")}`,
        extraParams: viewParams,
    });

    const history = useHistory();
    useEffect(() => {
        const query = new URLSearchParams(history.location.search);
        setLangX(query.get("lang") || "all");
        data.setFilter(query.get("filter") || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setLang = (lang: string) => {
        setLangX(lang);
        const urlp = new URLSearchParams(history.location.search);
        urlp.set("lang", lang);
        history.replace({ pathname: history.location.pathname, search: `?${urlp.toString()}` });
    }

    const setFilter = (filter: string) => {
        data.setFilter(filter);
        const urlp = new URLSearchParams(history.location.search);
        urlp.set("filter", filter);
        history.replace({ pathname: history.location.pathname, search: `?${urlp.toString()}` });
    }

    const hasLanguages = config.data.languages && config.data.languages.length > 1;

    const newItem = useNewItem<NewCMSRecord, CMSRecord>(
        `${apiPath}/manage/${recordType}`,
        { title: "", slug: "", lang: hasLanguages ? lang : undefined });

    return {
        recordType,
        config: config.data,
        schema: config.schema,
        hasLanguages,
        lang,
        setLang,
        sorting,

        ...data,
        setFilter,
        newItem,
        isLoading: config.isLoading || data.isLoading,
        isConfigLoading: config.isLoading,
    }
}


export type RecordsListData = ReturnType<typeof useRecordsList>;
