import React from 'react';
import styled from '@emotion/styled';
import { Button, IconButton, Typography } from '@material-ui/core';
import { Dialog, Link, LoadingIndicator, SearchField } from '../../primitives';
import { useRouteMatch } from 'react-router-dom';
import { Add, Close } from '@material-ui/icons';
import { FormattedMessage } from 'react-intl';
import { TranslationLinksData } from './useRecordTranslationLinks';
import { Schema } from '../../../hooks/useSchema';
import { TableForFields } from '../../schemed';
import { CMSLink } from '../types';
import isHotkey from 'is-hotkey';
import { getRecordLink } from '../util';

const LanguageWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 0.25rem;
`;

const Wrapper = styled.div`
    & > span {
        display: block;
    }
    & > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
`;

interface Props {
    data: TranslationLinksData;
    schema: Schema;
}

export const TranslationsLinks = (props: Props) => {
    const {
        translationLinkType,
        links,
        candidates,
        candidatesLang,
        loadCandidatesForLang,
        setCandidatesLang,
        addTranslation,
        removeTranslation,
        isRecordLinkedToUs,
    } = props.data;

    const { path } = useRouteMatch();

    if(!translationLinkType) {
        return null;
    }

    return (<>
        <Wrapper>
            <Typography variant="caption" color="textSecondary"><FormattedMessage id="cms.form.translation_links" /></Typography>
            <div>
                {links.map(link => {
                    return (
                        <LanguageWrapper key={link.lang}>
                            <Typography>
                                {link.lang}: {link.link && link.link._id && <Link to={getRecordLink(path, link.link)}>{link.link._id.toString()}</Link>}
                            </Typography>
                            
                            {link.link &&
                                <IconButton size="small" color="secondary" onClick={() => removeTranslation(link.link as CMSLink)}><Close /></IconButton>}
                            {!link.link &&
                                <IconButton size="small" color="primary" onClick={() => loadCandidatesForLang(link.lang)}><Add /></IconButton>}
                        </LanguageWrapper>
                )})}
            </div>
        </Wrapper>

        <Dialog
            isOpen={!!candidatesLang}
            close={() => setCandidatesLang(null)}
            dialogTitle={<>
                <FormattedMessage id="cms.form.translation_links_popup" values={{ lang: candidatesLang }} />
                {candidates.isLoading && <LoadingIndicator sizeVariant="font" />}
            </>}
            maxWidth="md"
            fullWidth
            noFullscreen
            onKeyDown={e => {
                if(isHotkey("Enter", e)) {
                    if(candidates.data.length === 1) {
                        const translation = candidates.data[0];
                        if(!isRecordLinkedToUs(translation)) {
                            addTranslation(translation).then(() => setCandidatesLang(null));
                        }
                    }
                }
            }}
            >

            <SearchField filter={candidates.filter.filter} setFilter={candidates.filter.setFilter} autoFocus noButton fullWidth />
            
            <TableForFields
                data={candidates.data}
                schema={props.schema}
                fields={[
                    ["_id"],
                    ["title"],
                    ["slug"],
                    ["published_datetime", { utcToLocal: true }],
                    ["created_datetime", { utcToLocal: true }],
                ]}
                fieldLink={f => {
                    switch(f) {
                        case "_id":
                        case "title":
                        case "slug":
                            return r => getRecordLink(path, r);
                    }
                }}
                rowButtons={row => (
                    <Button
                        size="small"
                        color="primary"
                        disabled={isRecordLinkedToUs(row)}
                        onClick={() => { addTranslation(row).then(() => setCandidatesLang(null)); }}>
                            <FormattedMessage id="common.select" />
                    </Button>)}
                autoexpander={{ initialRows: 20, increment: 20 }}
                />
        </Dialog>
    </>);
}
