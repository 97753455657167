import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Editor, Transforms } from 'slate';
import { ReactEditor, RenderElementProps, useSlate } from 'slate-react';
import { CustomElement } from '../../../../slate';
import { IconButton, TextField, Typography, withTheme } from '@material-ui/core';
import { SimpleDialog, useDialogState } from '../../primitives';
import { Add, PhotoAlbumOutlined } from '@material-ui/icons';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { EditorPlugin } from '../slate/PowerEditorConfig';
import { FormattedMessage } from 'react-intl';
import { usePowerEditorContext } from '../slate/PowerEditorContext';

export const GalleryElementType = "gallery";

export const withGallery = (editor: Editor) => {
    const { isVoid } = editor;
    
    editor.isVoid = (element: CustomElement) => {
        return element.type === GalleryElementType ? true : isVoid(element)
    }
    
    return editor
}


export const insertGallery = (editor: Editor) => {
    editor.insertNode({
        type: GalleryElementType,
        images: [],
        children: [{ text: "", }],
    });
}

const Placeholder = withTheme(styled.div`
    background: ${props => props.theme.palette.grey[200]};
    padding: 0.5rem 0.5rem;
    border-radius: 8px;
    cursor: pointer;
`);

const ImagesWrapper = withTheme(styled.div<{ justifyContent?: CSSProperties["justifyContent"] }>`
    display: flex;
    flex-flow: row;
    height: auto;
    align-items: center;
    max-width: 100%;
    overflow-x: auto;
    justify-content: ${props => props.justifyContent || "flex-start"};

    &::-webkit-scrollbar {
        width: 2px;
        height: 2px;
        background-color: ${props => props.theme.palette.grey[200]};
    }
    &::-webkit-scrollbar-thumb {
        background-color: ${props => props.theme.palette.primary.light};
    }
    
    & > img {
        height: 100%;
        max-height: 300px;
    }

    & > button {
        margin-left: 0.5rem;
    }
`);

const AlignToJustifyContent = {
    left: "flex-start",
    center: "center",
    right: "flex-end",
    justify: "space-between",
}

export const GalleryElement = (props: RenderElementProps) => {
    const { element, children } = props;

    const editor = useSlate();
    const dialog = useDialogState();
    const { viewMode } = usePowerEditorContext();
    
    const [newImageUrl, setNewImageUrl] = useState<string>("");
    const closeDialog = () => {
        setNewImageUrl("");
        dialog.close();
    }
    
    const path = ReactEditor.findPath(editor, element);

    const addImage = (url: string) => {
        Transforms.setNodes(
            editor,
            { images: [ ...(element.images || []), url] },
            { at: path },
        );
    }

    const removeImage = (idx: number) => {
        Transforms.setNodes(
            editor,
            { images: (element.images || []).filter((_,i) => i !== idx) },
            { at: path },
        );
    }

    return (
        <div>
            {children}
            {(!element.images || element.images.length === 0) && !viewMode && (
                <Placeholder onClick={dialog.open}>
                    <Typography>{<FormattedMessage id="powerdoc.plugins.gallery.click_here_to_add" />}</Typography>
                </Placeholder>
            )}
            {(element.images && element.images.length > 0) && (
                <ImagesWrapper justifyContent={(AlignToJustifyContent as any)[element.align || ""]}>
                    {element.images.map((url,idx) => (
                        <img
                            src={url}
                            key={url}
                            alt=""
                            onClick={e => {
                                if(e.shiftKey && !viewMode) {
                                    removeImage(idx);
                                }
                            }}
                            />))}

                    {!viewMode && <IconButton size="small" style={{ height: "auto" }} onClick={dialog.open}><Add /></IconButton>}
                </ImagesWrapper>
            )}

            <SimpleDialog
                dialogTitle={<FormattedMessage id="powerdoc.plugins.gallery.dialog_title" />}
                isOpen={dialog.isOpen}
                close={closeDialog}
                save={() => {
                    addImage(newImageUrl);
                    closeDialog();
                }}
                saveLabel={<FormattedMessage id="powerdoc.plugins.gallery.insert" />}
                noFullscreen
                >
                <TextField
                    label={<FormattedMessage id="powerdoc.plugins.gallery.image_url" />}
                    style={{ width: "100%" }}
                    autoFocus
                    value={newImageUrl || ""}
                    onChange={e => setNewImageUrl(e.target.value)}
                    />
            </SimpleDialog>
        </div>
    );
}

export const GalleryPlugin: EditorPlugin = {
    key: "conditional-blocks",
    customBlocks: { [GalleryElementType]: GalleryElement },
    inject: withGallery,
    commands: [{
        name: "insert-gallery",
        invoke: e => insertGallery(e),
        menu: {
            section: "insert-item",
            label: "Gallery",
            label_id: "powerdoc.plugins.gallery.title",
            icon: <PhotoAlbumOutlined />,
        }
    }]
}
