import React from 'react';
import styled from '@emotion/styled';
import { Chip, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { NotificationTarget } from './useNotificationTargets';

interface Props {
    targets: NotificationTarget[];
    removeTarget: (t: NotificationTarget) => void;
    openConfiguration: () => void;
    disabled?: boolean;
}

const Wrapper = styled.div`
    width: 100%;
    margin: 8px 0 4px;
    & > span:first-child {
        color: rgba(0, 0, 0, 0.54);
        display: block;
    }
`;

const ChipsContainer = styled.div`
    display: flex;
    flex-flow: row wrap;
    gap: 6px;
    padding: 6px 0;
`;

export const TargetDisplay = ({ targets, removeTarget, openConfiguration, disabled }: Props) => {

    return (
        <Wrapper onClick={() => !disabled && openConfiguration()}>
            <Typography variant="caption"><FormattedMessage id="notifications.task.targets" /></Typography>
            <ChipsContainer>
                {!targets.length &&
                    <Chip
                        key="noone"
                        variant="outlined"
                        clickable
                        label={<FormattedMessage id="notifications.task.target.noone" />}
                        />}
                {targets.map(t =>
                    <Chip
                        key={t.display_name}
                        label={t.display_name}
                        onDelete={() => !disabled && removeTarget(t)}
                        />)}
                {!!targets.length &&
                    <Chip
                        key="count"
                        variant="outlined"
                        clickable
                        label={<FormattedMessage id="notifications.task.target.count" values={{ count: targets.length }} />}
                        />}
            </ChipsContainer>
        </Wrapper>
    );
}
