import React from 'react';
import { useFormats } from '.';
import { FieldType } from '../../hooks/useSchema';
import { utc } from '../timezone';
import { DisplayProps } from './FormControlProps';

export const TimeDisplay = (props: DisplayProps) => {const { row, field, schema } = props;
    const { formatDate, formatDatetime } = useFormats();
    
    const value = row[field];
    const valueTransformed = value ? (props.config as any)?.utcToLocal ? utc.toLocal(value) : value : "";
    
    switch(schema.type) {
        case FieldType.date:
            return <>{formatDate(valueTransformed)}</>;
        default:
            return <>{formatDatetime(valueTransformed)}</>;
    }
}
