import React, { useEffect, useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    DialogActions,
    Button,
    Select,
    MenuItem,
    FormControl,
    FormHelperText,
    InputLabel,
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { SimpleButton } from '../primitives/Buttons';
import { IDefaultUser, TwoFactorAuthSettings } from './typings';
import { FormGrid } from '../primitives/Forms';


interface Props<IUser extends IDefaultUser = IDefaultUser> {
    user: IUser | null;
    close: () => void;
    saveChanges: (u: Partial<IUser>) => void;
}

const Mediums = [
    "-",
    "email",
    "telegram",
]

export const UserTwoFactorAuthSettingsPopup = <IUser extends IDefaultUser = IDefaultUser>(props: Props<IUser>) => {
    const { user, close, saveChanges } = props;

    const [settings, setSettings] = useState<TwoFactorAuthSettings>({ is_enabled: false });
    useEffect(() => {
        if(user) {
            setSettings(user.two_factor_auth || { is_enabled: false });
        }
    }, [user]);

    const updateMedium = (medium: string | null) => {
        const isOff = medium === "-" || medium === null || medium === undefined;
        setSettings(s => ({
            ...s,
            is_enabled: !isOff,
            medium: isOff ? null : medium,
            user_id: isOff ? null : s.user_id,
        }));
    }

    return (
        <Dialog open={!!user} onClose={close} maxWidth='sm' fullWidth>
            <DialogTitle>
                <FormattedMessage id="userManagement.two_factor_auth_popup.title"/>
            </DialogTitle>
            <DialogContent>
                <FormGrid columns="1fr">
                    <TextField
                        label={<FormattedMessage id="userManagement.columns.email"/>}
                        margin="normal"
                        fullWidth
                        disabled
                        value={user ? user.email : ""}
                    />
                    <FormControl>
                        <InputLabel><FormattedMessage id="userManagement.two_factor_auth_popup.medium"/></InputLabel>
                        <Select
                            value={settings.medium || "-"}
                            onChange={e => updateMedium(e.target.value as string)}>
                                {Mediums.map(m => (
                                    <MenuItem key={m} value={m}>
                                        {m}
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                    {settings.is_enabled && <FormControl>
                        <TextField
                            label={<FormattedMessage id="userManagement.two_factor_auth_popup.two_factor_user_id"/>}
                            margin="normal"
                            fullWidth
                            value={settings.user_id || ""}
                            onChange={(ev) => {
                                const user_id = ev.target.value || '';
                                setSettings(s => ({ ...s, user_id }));
                            }}
                        />
                        <FormHelperText><FormattedMessage id="userManagement.two_factor_auth_popup.two_factor_user_id_hint"/></FormHelperText>
                    </FormControl>}
                </FormGrid>
            </DialogContent>
            <DialogActions>
                <Button onClick={close}><FormattedMessage id="common.cancel"/></Button>
                <SimpleButton
                    label={<FormattedMessage id="common.save"/>}
                    action={() => {
                        if(user) {
                            saveChanges({ _id: user._id, two_factor_auth: settings } as Partial<IUser>);
                            close();
                        }
                    }}
                    />
            </DialogActions>
        </Dialog>
    );
}
