import React from 'react';
import { Editor } from "slate";
import { EditorPlugin } from "../../slate/PowerEditorConfig";

const withLineBreaks = (editor: Editor) => {
    editor.insertSoftBreak = () => {
        editor.insertText("\n");
    }
    return editor;
}

export const LineBreaksPlugin: EditorPlugin = {
    key: "linebreaks",
    // this is legacy - not used by normal documents, but may be stuck in some older ones
    // and removing this will make them unrenderable by slate
    customBlocks: { br: props => <span {...props.attributes} contentEditable={false}>{props.children}<br/></span> },
    inject: withLineBreaks,
    commands: [
        {
            name: "linebreak",
            hotkey: "shift+enter",
            invoke: e => e.insertSoftBreak(),
        },
    ],
}