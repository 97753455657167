import React, { useMemo } from 'react';
import { FormGrid } from '../../primitives';
import { FieldDefinition, FormControlsForFields } from '../../schemed';
import { RecordEditData } from './useRecordEdit';

interface Props {
    data: RecordEditData;
}

export const RecordAttributesForm = (props: Props) => {
    const { data, updateAttributes } = props.data;
    const { data: config, attributesSchema, } = props.data.config;

    const { fat, normal } = useMemo(
        () => (config.attributes || []).reduce<{ normal: FieldDefinition[], fat: FieldDefinition[] }>(
            (r,a) => {
                if(["mediafile", "json", "textlong", "markdown"].includes(a.fieldtype || "")) {
                    r.fat.push([a.code]);
                } else {
                    r.normal.push([a.code]);
                }
                return r;
            }, { normal: [], fat: [] }),
            [config.attributes]
        )
    
    return fat.length > 0
        ? (
            <FormGrid columns="2fr 1fr" noMargin>
                <FormGrid columns="1fr 1fr" noMargin>
                    <FormControlsForFields
                        data={data.attributes || {}}
                        onChange={(o,c) => updateAttributes(c)}
                        schema={attributesSchema}
                        fields={normal}
                        />
                </FormGrid>

                <FormGrid columns="1fr" noMargin>
                    <FormControlsForFields
                        data={data.attributes || {}}
                        onChange={(o,c) => updateAttributes(c)}
                        schema={attributesSchema}
                        fields={fat}
                        />
                </FormGrid>
            </FormGrid>
        )
        : (
            <FormGrid noMargin>
                <FormControlsForFields
                    data={data.attributes || {}}
                    onChange={(o,c) => updateAttributes(c)}
                    schema={attributesSchema}
                    fields={normal}
                    />
            </FormGrid>
        );
}
