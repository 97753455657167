import React from 'react';
import styled from '@emotion/styled';
import { RobudConfig } from './Config';
import { Link as RouterLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { withTheme } from '@material-ui/core';

interface Props extends Pick<RobudConfig, "pagePath"> {
    active?: "programs" | "execution";
}

const Link = withTheme(styled(RouterLink)<{ isActive?: boolean }>`
    color: ${props => props.theme.palette.text.hint};
    text-decoration: none;

    border-bottom: 1px dotted ${props => props.isActive ? props.theme.palette.text.hint : "transparent"};
    &:hover {
        border-bottom: 1px dotted ${props =>props.theme.palette.primary.main};
    }
`);

const Wrapper = styled.div`
    display: flex;
    align-items: baseline;
    gap: 1rem;
    align-self: flex-end;
`;

export const RobudPagesLinks = (props: Props) => {
    return (
        <Wrapper>
            {props.active !== "programs"
                && <Link to={`${props.pagePath}/program`}><FormattedMessage id="robud.programs.page_title" /></Link>}
            {props.active !== "execution"
                && <Link to={`${props.pagePath}/executions`}><FormattedMessage id="robud.executions.page_title" /></Link>}
        </Wrapper>
    );
}
