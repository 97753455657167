import React, { ReactNode, useEffect } from 'react';
import { useRestorePassword } from '../../hooks/auth';
import { OuterCentralizedForm } from '../primitives/Forms';
import { SimpleButton } from '../primitives/Buttons';
import { TextField, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

interface RestorePasswordFormProps {
    restoreAPIPath: string;
    logoAlt?: string;
    successMessage?: ReactNode;
    successRedirect?: string;
    successRedirectTimeout?: number;
}

export const RestorePasswordForm: React.FC<RestorePasswordFormProps> = (props: RestorePasswordFormProps) => {
    const { restoreAPIPath, successRedirect, successRedirectTimeout, successMessage, } = props;
    const { data, update, send, isSaving, isSaved: isSuccess } = useRestorePassword(restoreAPIPath);

    const history = useHistory();

    useEffect(() => {
        if(isSuccess && successRedirect) {
            setTimeout(() => {
                history.replace(successRedirect);
            }, successRedirectTimeout || 1000);
        }
    }, [history, successRedirect, successRedirectTimeout, isSuccess]);

    return (
        <OuterCentralizedForm>
            {!isSuccess && <>
                <TextField
                    label={<FormattedMessage id="restorePassword.password"/>}
                    name='password'
                    type='password'
                    variant='outlined'
                    value={data.password}
                    onChange={e => update({ ...data, password: e.target.value || '' })} />
                <TextField
                    label={<FormattedMessage id="restorePassword.passwordRepeat"/>}
                    name='password repeat'
                    type='password'
                    variant='outlined'
                    value={data.passwordRepeat}
                    onChange={e => update({ ...data, passwordRepeat: e.target.value || '' })} />
                <SimpleButton
                    label={<FormattedMessage id="restorePassword.submit"/>}
                    action={send}
                    isLoading={isSaving}
                />
            </>}
            {isSuccess && (successMessage || <Typography><FormattedMessage id="restorePassword.success"/></Typography>)}
        </OuterCentralizedForm>
    )
};
