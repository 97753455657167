import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useLoadedData } from "../../hooks/useLoadedData"
import { FieldType, mergeSchema, Schema } from "../../hooks/useSchema";
import { useTextFilter } from "../schemed/Filtering/useTextFilter";

export interface NotificationTarget {
    user_id?: string | null;
    email: string | null;
    kind: string;
    display_name: string;
    info?: any | null;   
    tags?: string[]; 
}

interface Placeholders {
    availableOnAll: string[];
    availableOnSome: string[];
}


export interface NotificationTargetGroup {
    title: string;
    code: string;
    targets: NotificationTarget[];
}

export interface NotificationTargetsManager {
    targets: NotificationTarget[];
    isLoading: boolean;
    group: string;
    setGroup: (v: string) => void;
    filter: string;
    setFilter: (v: string) => void;
    availableTags: string[];
    isTagSelected: (t: string) => boolean;
    setIsTagSelected: (t: string, v: boolean) => void;
    tagFilterMode: "and" | "or";
    setTagFilterMode: (m: "and" | "or") => void;
    notFilterMode: boolean;
    setNotFilterMode: (m: boolean) => void;
    getPlaceholders: (targets: NotificationTarget[]) => Placeholders;

    groups: NotificationTargetGroup[];
    schema: Schema;
}

const TargetSchema: Schema = {
    display_name: { type: FieldType.text, label_id: "notifications.task.display_name" },
    email: { type: FieldType.text, label_id: "notifications.task.email" },
    kind: { type: FieldType.select, label_id: "notifications.task.kind" },
    tags: { type: FieldType.text, label_id: "notifications.task.tags" },
};

export const useNotificationTargets = (apiPath: string): NotificationTargetsManager => {
    const data = useLoadedData<Record<string, NotificationTargetGroup>>(`${apiPath}/targets`, {});
    const [group, setGroup] = useState<string>("all");
    const [selectedTags, setSelectedTags] = useState<string[]>([]);
    const [tagFilterMode, setTagFilterMode] = useState<"and" | "or">("or");
    const [notFilterMode, setNotFilterMode] = useState<boolean>(false);
    const filter = useTextFilter<NotificationTarget>(t => `${t.display_name} ${t.email} ${t.user_id}`);

    const intl = useIntl();

    const kinds = Object.keys(data.data).map(k => ({ 
        value: k, 
        label: k === "all" ? intl.formatMessage({ id: "notifications.task.target.all"}) : data.data[k].title }));

    const schema = mergeSchema(TargetSchema, {
        kind: { values: kinds, valueDict: kinds.reduce((r, { value, label }) => ({ ...r, [value]: label }), {})}
    });

    const targets = (data.data[group]?.targets || []);

    const availableTags = Array.from(targets.reduce((r,target) => {
        (target.tags || []).forEach(t => r.add(t));
        return r;
    }, new Set<string>())).sort();

    const isTagSelected = (t: string) => selectedTags.includes(t);
    const setIsTagSelected = (t: string, isSelected: boolean) => setSelectedTags(ts => isSelected ? [...ts, t] : ts.filter(tx => tx !== t));

    const byTagFilter = (target: NotificationTarget) => {
        if(selectedTags.length === 0) {
            return true;
        }

        const tags = (target.tags || []);

        const hasTags = tagFilterMode === "or"
            ? !!selectedTags.find(tx => tags.includes(tx))
            : !!selectedTags.every(tx => tags.includes(tx));

        return notFilterMode ? !hasTags : hasTags;
    }

    const getPlaceholders = (targets: NotificationTarget[]): Placeholders => {
        const allOnAll = targets.map(t => (Object.keys({ ...t, ...(t.info || {}), info: undefined })));
        const uniqueAll = allOnAll.reduce((result, items) => {
            items.forEach(param => {
                if(!result.has(param)) {
                    result.add(param);
                }
            })
            return result;
        }, new Set<string>());
        uniqueAll.delete("info");
        
        const onEveryone = new Set<string>(uniqueAll);
        uniqueAll.forEach(param => {
            const doesntHave = allOnAll.find(t => !t.includes(param));
            if(doesntHave) {
                onEveryone.delete(param);
            }
        });

        return {
            availableOnAll: Array.from(onEveryone).sort(),
            availableOnSome: Array.from(uniqueAll).sort(),
        }
    }

    useEffect(() => {
        setSelectedTags(ts => ts.filter(t => availableTags.includes(t)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [group]);

    return {
        groups: Object.values(data.data),
        isLoading: data.isLoading,

        group,
        setGroup,
        availableTags,
        isTagSelected,
        setIsTagSelected,
        tagFilterMode,
        setTagFilterMode,
        notFilterMode,
        setNotFilterMode,
        targets: filter.filterData(targets.filter(byTagFilter)),
        getPlaceholders,

        ...filter,

        schema,
    }

}