import { useMemo } from "react";
import { useLoadedData } from "../../hooks/useLoadedData";
import { FieldSchema, FieldType, mergeSchema, Schema } from "../../hooks/useSchema";

export interface LinkConfig {
    link_type: string;
    target: string;
    kind: string;
}

export interface AttributeConfig {
    code: string;
    label: string;
    fieldtype?: FieldType | "mediafile";
}

export interface RecordTypeConfig {
    record_type: string;
    label?: string;
    slugs?: boolean;
    "disable-drafts"?: boolean;
    "translations-in-place"?: boolean;

    languages?: string[];
    links?: LinkConfig[];
    attributes?: AttributeConfig[];
}

export const DefaultRecordSchema: Schema = {
    _id: { label: "ID", label_id: "cms.record.fields._id", type: FieldType.text },
    title: { label: "Title", label_id: "cms.record.fields.title" },
    slug: { label: "Slug", label_id: "cms.record.fields.slug" },
    content: { label: "Content", label_id: "cms.record.fields.content" },
    lang: { label: "Lang", label_id: "cms.record.fields.lang", type: FieldType.select },
    record_datetime: { label: "Date", label_id: "cms.record.fields.record_datetime", type: FieldType.datetime },
    created_datetime: { label: "Created", label_id: "cms.record.fields.created_datetime", type: FieldType.datetime },
    published_datetime: { label: "Published on", label_id: "cms.record.fields.published_datetime", type: FieldType.datetime },
    is_published: { label: "Published", label_id: "cms.record.fields.is_published", type: FieldType.bool },
}


export const useRecordTypeConfig = (apiPath: string, recordType: string) => {
    const config = useLoadedData<RecordTypeConfig>(`${apiPath}/manage/config/${recordType}`, { record_type: recordType });

    const schema = useMemo(() => {
        const langs = config.data.languages || [];
        return mergeSchema(
            DefaultRecordSchema,
            {
                lang: { values: langs.map(l => ({ value: l, label: l })), valueDict: langs.reduce<Record<string, string>>((r,l) => { r[l] = l; return r; }, {}) },
            }
        )
    }, [config.data]);

    const attributesSchema = useMemo(() => {
        return (config.data.attributes || []).reduce<Record<string, FieldSchema>>(
            (r,f) => { r[f.code] = { type: f.fieldtype as any, ...f }; return r; },
            {},
        )
    }, [config.data]);

    const hasLanguages = config.data.languages && config.data.languages.length > 1;
    const hasAttributes = config.data.attributes && config.data.attributes.length > 0;

    return {
        ...config,
        schema,
        attributesSchema,
        hasLanguages,
        hasAttributes,
    }
}