import { useEffect, useState } from "react";

export interface HistoryItem {
    location: string;
    title: string | { id: string, values?: Record<string, any> };
}

export interface HistoryData {
    history: HistoryItem[];
    favorites: HistoryItem[];
}

export const DefaultHistoryData: HistoryData = { history: [], favorites: [] };

export interface HistoryFavoritesConfig {
    lsKey: string;
    historyLength?: number;
}

export const useHistoryFavoritesMain = ({ lsKey, historyLength }: HistoryFavoritesConfig) => {
    const [historyData, setHistoryData] = useState<HistoryData>(DefaultHistoryData);
    
    const trackHistory = (location: string, title: HistoryItem["title"]) => {
        setHistoryData(x => ({
            ...x,
            history: [ { location, title }, ...x.history.filter(h => h.location !== location) ].slice(0, historyLength || 10),
            favorites: x.favorites.map(h => h.location === location ? { ...h, title } : h),
        }));
    }

    const saveFavorite = (item: HistoryItem) => {
        setHistoryData(x => ({
            ...historyData,
            favorites: [...historyData.favorites, item],
        }));
    }

    const removeFavorite = (item: HistoryItem) => {
        setHistoryData(x => ({
            ...x,
            favorites: x.favorites.filter(h => h.location !== item.location),
        }));
    }

    useEffect(() => {
        const saved = localStorage.getItem(lsKey) || "";
        if(saved) {
            const parsed = JSON.parse(saved);
            setHistoryData(parsed);
        } else {
            setHistoryData(DefaultHistoryData);
        }
    }, [lsKey]);

    useEffect(() => {
        if(historyData.history.length || historyData.favorites.length) {
            localStorage.setItem(lsKey, JSON.stringify(historyData));
        }
    }, [lsKey, historyData]);


    return {
        history: historyData,
        trackHistory,
        saveFavorite,
        removeFavorite,
    }
}

export type HistoryFavoritesData = ReturnType<typeof useHistoryFavoritesMain>;
