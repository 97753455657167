import React from 'react';
import { MediaLibPicker, useMediaLibContext } from '../medialib';
import { expandProps, FormControlProps } from './FormControlProps';

export const MediafileEdit = (props: FormControlProps) => {
    const medialib = useMediaLibContext();
    const { row, value, field, onChange, schema, extraProps } = expandProps(props);
    
    return (
        <MediaLibPicker
            selectedUrl={value}
            selectFile={mf => onChange(row, { [field]: mf ? medialib.getFilepath(mf) : null })}
            closeOnSelect
            size="small"
            label={schema?.label || field}
            {...extraProps}
            />
    );
}
