import React, { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { Tooltip as TooltipMUI } from '@material-ui/core';

interface Props {
    text?: string;
    text_id?: string;
    text_params?: Record<string, any>;
    withWrapper?: boolean;
    children: ReactElement<any, any>;
}

export const Tooltip = ({ text, text_id, text_params, withWrapper, children }: Props) => {
    const title = text ? text : text_id ? <FormattedMessage id={text_id} values={text_params} /> : "";

    const content = withWrapper ? <div style={{ display: "inline-block" }}>{children}</div> : children;
    return (<TooltipMUI title={title}>{content}</TooltipMUI>);
}
