import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Editor, Transforms } from 'slate';
import { ReactEditor, RenderElementProps, useSelected, useSlateStatic } from 'slate-react';
import { CustomElement } from '../../../../slate';
import { TextField, withTheme } from '@material-ui/core';
import { EditorPlugin } from '../slate/PowerEditorConfig';
import { ViewAgendaOutlined } from '@material-ui/icons';
import { FormattedMessage } from 'react-intl';
import { usePowerEditorContext } from '../slate/PowerEditorContext';
import { BlockSelectionCss } from '../elements/Common';
import { usePowerEditorSettings } from '../PowerEditorSettingsContext';
import { PowerEditorBase } from '../slate/PowerEditorBase';

export const ConditionalBlockElementType = "conditional_block";

export const withConditionalBlocks = (editor: Editor) => {
    const { isVoid } = editor;
    
    editor.isVoid = (element: CustomElement) => {
        return element.type === ConditionalBlockElementType ? true : isVoid(element)
    }
    
    return editor
}


export const insertConditionalBlock = (editor: Editor) => {
    editor.insertNode({
        type: ConditionalBlockElementType,
        condition: "",
        content: undefined,
        children: [{ text: "", }],
    });
}

const Wrapper = withTheme(styled.div<{ isSelected?: boolean }>`
    padding: 0.5rem 1rem;
    border-top: 1px solid #eeeeee;
    border-bottom: 1px solid #eeeeee;
    ${props => BlockSelectionCss(props)}
    width: 100%;
    display: flex;
    flex-flow: column;
`);

export const ConditionalBlockElement = (props: RenderElementProps) => {
    const { element, children } = props;

    const editor = useSlateStatic();
    const { viewMode } = usePowerEditorContext();
    const isSelected = useSelected();

    // local state for condition is needed because
    // Transforms.setNodes happens asynchronously and the lag between element value update and its completion
    // is enough to set React off with the element state and cause a rerender when the node if finally updated
    const [conditionLocal, setConditionLocal] = useState<string>(element.condition || "");

    const update = (changes: { condition?: string, content?: any}) => {
        const path = ReactEditor.findPath(editor, element);
        if(changes.condition !== undefined) {
            setConditionLocal(changes.condition);
        }
        Transforms.setNodes(
            editor,
            changes,
            { at: path },
        );
    }

    const settings = usePowerEditorSettings();
    const Editor = settings.EditorComponent || PowerEditorBase;

    return (
        <div {...props.attributes}>
            {children}
            <Wrapper contentEditable={false} isSelected={isSelected}>
                <TextField
                    value={conditionLocal}
                    onChange={e => update({ condition: e.target.value })}
                    label={<FormattedMessage id="powerdoc.plugins.conditional_block.condition" />}
                    InputProps={{ readOnly: viewMode }}
                    />

                <Editor
                    content={element.content}
                    update={v => update({ content: v })}
                    viewMode={viewMode}
                    />
            </Wrapper>
        </div>
    );
}

export const ConditionalBlockPlugin: EditorPlugin = {
    key: "conditional-blocks",
    customBlocks: { [ConditionalBlockElementType]: ConditionalBlockElement },
    inject: withConditionalBlocks,
    commands: [{
        name: "insert-conditional-block",
        invoke: e => insertConditionalBlock(e),
        hotkey: "alt+c",
        menu: {
            section: "insert-item",
            label: "Conditional block",
            label_id: "powerdoc.plugins.conditional_block.title",
            icon: <ViewAgendaOutlined />,
        }
    }]
}
