import { useEffect, useMemo, useState } from "react";
import { ScoringSheet } from ".";
import { apiFetch, FetchTypes } from "../../../api/core";
import { useLoadedData } from "../../../hooks/useLoadedData";
import { Scoring, Scores } from "./typings";

export interface ScoringData {
    sheet: ScoringSheet;
    scores: Scores;
    totalNumeric: number;
    update: (s: Scores) => void;
    submit: () => Promise<void | Scoring>;
    canSubmit: boolean;
    isSubmitted: boolean;
    isLoading: boolean;
    isSaving: boolean;
}

interface Config {
    pathAsIs?: boolean;
}

export const useScoring = (apiPath: string, code: string | null | undefined, id: string | null | undefined, cfg?: Config): ScoringData => {
    const apiPathX = cfg?.pathAsIs ? apiPath : `${apiPath}/${code}/${id}`;
    const data = useLoadedData<Scoring>(apiPathX, { sheet: {}, scores: {}} as Scoring, !!code && !!id);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

    const [scores, setScores] = useState<Scores>({});

    useEffect(() => {
        if(data.data.scores) {
            setScores(data.data.scores);
        }
    }, [data.data]);

    const criteria = data.data.sheet?.criteria || [];
    const canSubmit = !data.isLoading && !isSaving && !criteria.find(c => !c.is_optional && (scores[c._id as string] === null || scores[c._id as string] === undefined));

    const submit = () => {
        if(canSubmit) {
            setIsSaving(true);
            return apiFetch<Scoring>(apiPathX, FetchTypes.PUT, { scores })
                .then(result => {
                    setIsSaving(false);
                    setScores(result.scores);
                    setIsSubmitted(true);
                    return result;
                })
                .catch(e => {
                    setIsSaving(false);
                    throw e;
                });
        }
        return Promise.resolve();
    }

    const totalNumeric = useMemo(() => {
      const criteria = (data.data.sheet?.criteria || []).filter(c => c.type === "number");
      return criteria.reduce((r,c) => r + (scores[c._id || ""] || 0), 0)
    }, [scores, data.data.sheet]);


    return {
        sheet: data.data.sheet,
        scores,
        totalNumeric,

        update: (s: Scores) => setScores(so => ({ ...so, ...s })),
        submit,
        canSubmit,

        isLoading: data.isLoading,
        isSaving,
        isSubmitted,
    }
}

export const useMockScoring = (sheet: ScoringSheet): ScoringData => {
    const [scores, setScores] = useState<Scores>({});
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

    const criteria = sheet.criteria || [];
    const canSubmit = !criteria.find(c => scores[c._id as string] === null);

    const submit = () => {
        setIsSubmitted(true);
        return Promise.resolve();
    }


    return {
        sheet,
        scores,
        totalNumeric: 0,

        update: (s: Scores) => setScores(so => ({ ...so, ...s })),
        submit,
        canSubmit,

        isLoading: false,
        isSaving: false,
        isSubmitted,
    }
}
