import { Node } from 'slate';
import { jsx } from 'slate-hyperscript';
import { InlineMarks } from '../../../../../slate';

type ElementType = HTMLElement | ChildNode;

const ElementTags: Record<string, (el: ElementType) => any> = {
    A: el => ({ type: 'link', url: (el as any).getAttribute('href') }),
    BLOCKQUOTE: () => ({ type: 'blockquote' }),
    H1: () => ({ type: 'h1' }),
    H2: () => ({ type: 'h2' }),
    H3: () => ({ type: 'h3' }),
    H4: () => ({ type: 'h4' }),
    H5: () => ({ type: 'h5' }),
    H6: () => ({ type: 'h6' }),
    IMG: el => ({ type: 'image', url: (el as any).getAttribute('src') }),
    LI: () => ({ type: 'li' }),
    OL: () => ({ type: 'ol' }),
    P: () => ({ type: 'paragraph' }),
    PRE: () => ({ type: 'code' }),
    UL: () => ({ type: 'ul' }),
    TABLE: () => ({ type: 'table' }),
    TR: () => ({ type: 'tr' }),
    TD: () => ({ type: 'td' }),
    TH: () => ({ type: 'th' }),
}

// COMPAT: `B` is omitted here because Google Docs uses `<b>` in weird ways.
const TextTags: Record<string, (el: ElementType) => InlineMarks> = {
    CODE: () => ({ code: true }),
    DEL: () => ({ strikethrough: true }),
    EM: () => ({ italic: true }),
    I: () => ({ italic: true }),
    S: () => ({ strikethrough: true }),
    STRONG: () => ({ bold: true }),
    B: () => ({ bold: true }),
    U: () => ({ underline: true }),
}

const ignoreEmptyTextContentForTags = ["body"];

export const deserialize = (el: ElementType): Node[] | Node => {
    if (el.nodeType === 3) {
        if(!(ignoreEmptyTextContentForTags.includes((el.parentElement?.tagName || "").toLowerCase()) && (el.textContent || "").match(/^(\n|\r|\s)*$/))) {
            return el.textContent as any;
        }
    } else if (el.nodeType !== 1) {
        return null as any;
    } else if (el.nodeName === 'BR') {
        return '\n' as any;
    }
    
    const { nodeName } = el
    let parent: ElementType = el
    
    if (
        nodeName === 'PRE' &&
        el.childNodes[0] &&
        el.childNodes[0].nodeName === 'CODE'
        ) {
            parent = el.childNodes[0]
        }
    let children = Array.from(parent.childNodes)
    .map(deserialize)
    .flat()
    
    if (children.length === 0) {
        children = [{ text: '' }]
    }
    
    if (el.nodeName === 'BODY') {
        return jsx('fragment', {}, children)
    }
    
    if (ElementTags[nodeName]) {
        const attrs = ElementTags[nodeName](el)

        if(nodeName === "TABLE") {
            children = children.filter((c: any) => c && c.type === "tr");
        }
        if(nodeName === "TR") {
            children = children.filter((c: any) => c && (c.type === "td" || c.type === "th"));
        }
        if(nodeName === "TD" || nodeName === "TH") {
            children = children.reduce<any[]>((r,c: any) => {
                if(c && c.children && c.children.length) {
                    c.children.forEach((cx: any) => r.push(cx));
                } else if(c) {
                    r.push(c);
                }
                return r;
            }, []);
        }

        return jsx('element', attrs, children)
    }
    
    if (TextTags[nodeName]) {
        const attrs = TextTags[nodeName](el)
        return children.map(child => jsx('text', attrs, child))
    }
    
    return children
}